import styled from "@emotion/styled";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { MouseEvent, useState } from "react";

const specialCharacter =
  /[^a-z0-9\\{\\}\\[\]\\/?,.;:|\\)*~`!^_\-+<>@\\#$%&\\\\=\\(\\'\\"]/gi;

const useShowPassword = () => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const type = isShow ? "text" : "password";

  const handleClickShowPassword = () => {
    setIsShow(!isShow);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
        sx={{
          "&:hover": {
            color: "var(--violet050)",
          },
        }}
      >
        {isShow ? (
          <Icon className="icon icon-hidepw" />
        ) : (
          <Icon className="icon icon-showpw" />
        )}
      </IconButton>
    </InputAdornment>
  );

  return [{ type, InputProps: { endAdornment } }, setIsShow];
};
const Icon = styled.div`
  font-size: 24px;
`;

const PasswordField = ({ ...props }: TextFieldProps) => {
  const [showPassword] = useShowPassword();
  return <TextField {...props} {...showPassword} />;
};

export default PasswordField;
