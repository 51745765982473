import styled from "@emotion/styled";
import { type DialogProps } from "@mui/material/Dialog";
import { useTranslation } from "next-i18next";
import { DubButton } from "../common/button";
import { DubDialog } from "../common/dialog";
import { B1 } from "../common/fonts";

type Props = DialogProps & {
  locale: string;
  blockMessage: string;
  handleClose: () => void;
};

const BlockedUser = ({ open, locale, blockMessage, handleClose }: Props) => {
  const { t } = useTranslation();

  const url = (() => {
    switch (locale) {
      case "ko":
        return "https://forms.gle/TnJXSr5MFcqbpcsM8";
      case "ja":
        return "https://forms.gle/7LkbBaGmce6EoEhV8";
      case "en":
      default:
        return "https://forms.gle/DbTQ8KYPrGSyvXgTA";
    }
  })();

  // 이의제기
  const handleAppeal = () => {
    window.open(url, "_blank");
    handleClose();
  };

  return (
    <DubDialog
      open={open}
      size="regular"
      header={t("dialog_account_suspended")}
      body={
        <Text
          dangerouslySetInnerHTML={{
            __html: blockMessage,
          }}
        />
      }
      footer={
        <>
          <DubButton
            variant="line"
            color="gray"
            size="regular"
            onClick={handleAppeal}
          >
            {t("button_appeal")}
          </DubButton>
          <DubButton
            variant="fill"
            color="magenta"
            size="regular"
            onClick={handleClose}
          >
            {t("button_okay")}
          </DubButton>
        </>
      }
    />
  );
};

const Text = styled(B1)`
  color: var(--gray090);
  padding: 8px 32px 12px;
`;

export default BlockedUser;
