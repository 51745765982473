import { EVENT, send } from "@/lib/amplitude";
import moment from "moment";
import { ChangeEvent, useMemo, useState } from "react";
import { ACCOUNT_TYPE, AuthInfo } from "../types/useAuth.types";

const MASK_FORMAT = {
  ko: "YYYY/MM/DD",
  ja: "YYYY/MM/DD",
  en: "DD/MM/YYYY",
};

const matchTypeKo = (changeValue: string, currentValue: string) => {
  let result = changeValue;
  if (changeValue.match(/^\d{4}$/) !== null) {
    if (currentValue.match(/^\d{3}$/)) {
      result += "/";
    } else {
      result = changeValue.slice(0, 3);
    }
  } else if (changeValue.match(/^\d{4}\/\d{2}$/) !== null) {
    if (currentValue.match(/^\d{4}\/\d{1}$/)) {
      result += "/";
    } else {
      result = changeValue.slice(0, 6);
    }
  } else if (changeValue.slice(-1).match(/[^\d]/)) {
    result = changeValue.slice(0, -1);
  }

  return result;
};
const matchTypeEn = (changeValue: string, currentValue: string) => {
  let result = changeValue;
  if (changeValue.match(/^\d{2}$/) !== null) {
    if (currentValue.match(/^\d{1}$/)) {
      result += "/";
    } else {
      result = changeValue.slice(0, 1);
    }
  } else if (changeValue.match(/^\d{2}\/\d{2}$/) !== null) {
    if (currentValue.match(/^\d{2}\/\d{1}$/)) {
      result += "/";
    } else {
      result = changeValue.slice(0, 4);
    }
  } else if (changeValue.slice(-1).match(/[^\d]/)) {
    result = changeValue.slice(0, -1);
  }

  return result;
};

const useEnterBirthday = (
  handleStep: (type: number) => void,
  handleBirthdayProps: (newBirthday: string) => void,
  locale: string,
  auth_info: AuthInfo,
) => {
  const [value, setValue] = useState("");
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = value;
    let { value: changeValue } = e.target;

    if (changeValue.length > 10) return;

    if (["ko", "ja"].includes(locale)) {
      changeValue = matchTypeKo(changeValue, currentValue);
    } else {
      changeValue = matchTypeEn(changeValue, currentValue);
    }

    setValue(changeValue);
  };

  const mask = useMemo(() => {
    const str = MASK_FORMAT[locale] ?? MASK_FORMAT.en;
    return `${value}<span>${str.substring(value.length)}</span>`;
  }, [locale, value]);

  const handleClickSkip = () => {
    send(EVENT.CLICK__SKIP_BIRTHDAY, {
      account_type: ACCOUNT_TYPE[auth_info.type],
    });
    handleStep(1);
  };

  const handleClickNext = () => {
    send(EVENT.CLICK__CONFIRM_BIRTHDAY, {
      account_type: ACCOUNT_TYPE[auth_info.type],
    });
    handleBirthdayProps(value);
    handleStep(1);
  };

  const disabled =
    value.length < 10 ||
    !moment(value, MASK_FORMAT[locale] ?? MASK_FORMAT.en).isValid();

  return {
    value,
    onChange,
    mask,
    disabled,
    handleClickSkip,
    handleClickNext,
  };
};

export default useEnterBirthday;
