import React, { useRef } from "react";
import { PlayerProps, VideoModel } from "./player.types";
import usePlayer from "./usePlayer.hook";

const defaultPromise = async () => {
  return undefined;
};

const defaultContext = {
  ref: null,
  video: undefined,
  speed: 1,
  scriptsState: [],
  play: defaultPromise,
  playScript: defaultPromise,
  pause: defaultPromise,
  mute: defaultPromise,
  unmute: defaultPromise,
  seekTo: defaultPromise,
  setSpeed: defaultPromise,
};

export const VideoContext = React.createContext<PlayerProps>(defaultContext);

const VideoProvider = ({
  video,
  children,
}: {
  video: Partial<VideoModel>;
  children: React.ReactNode;
}) => {
  const ref = useRef(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const playerProps = usePlayer(video, ref, audioRef);

  return (
    <VideoContext.Provider value={playerProps}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} hidden />
      {children}
    </VideoContext.Provider>
  );
};

export default VideoProvider;
