import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { type TextFieldProps } from "@mui/material/TextField";
import CustomTextField from "./CustomTextField";

const DubPassword = (props: TextFieldProps) => {
  const [type, setType] = useState("password");

  const onClick = () => {
    const nextType = type === "password" ? "text" : "password";
    setType(nextType);
  };

  const onMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const iconClassName =
    type === "password" ? "icon icon-showpw" : "icon icon-hidepw";

  const endAdornment = (
    <InputAdornment position="end" sx={{ margin: 0 }}>
      <IconButton
        aria-label="toggle password visibility"
        onClick={onClick}
        onMouseDown={onMouseDown}
        edge="end"
        sx={{
          color: "#616161",
          "&:hover": {
            color: "var(--gray090)",
          },
        }}
      >
        <Box sx={{ fontSize: "24px" }} className={iconClassName} />
      </IconButton>
    </InputAdornment>
  );

  return (
    <CustomTextField type={type} InputProps={{ endAdornment }} {...props} />
  );
};

export default DubPassword;
