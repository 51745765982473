import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

export const UUID_KEY = "2DUB_UUID_KEY";

export const useUUID = () => {
  useEffect(() => {
    if (getUUID()) return;
    setUUID();
  }, []);
};

const setUUID = () => {
  const uuid = uuidv4();
  localStorage.setItem(UUID_KEY, uuid);

  return uuid;
};

export const getUUID = () => {
  const uuid = localStorage.getItem(UUID_KEY);
  if (uuid) return uuid;

  return setUUID();
};
