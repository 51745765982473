import styled from "@emotion/styled";
import { type DialogProps } from "@mui/material/Dialog";
import { useTranslation } from "next-i18next";
import React from "react";
import DubButton from "../common/button/DubButton";
import DubDialog from "../common/dialog/DubDialog";
import { B1 } from "../common/fonts";

type Props = DialogProps & {
  loading: boolean;
  handleClose: () => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
};

const ForceSignIn = ({ open, loading, handleClose, handleSubmit }: Props) => {
  const { t } = useTranslation();

  return (
    <DubDialog
      open={open}
      onClose={handleClose}
      size="regular"
      header={t("dialog_logged_device_title")}
      body={<Text>{t("dialog_logged_device_body")}</Text>}
      footer={
        <>
          <DubButton
            variant="line"
            size="regular"
            color="gray"
            onClick={handleClose}
          >
            {t("button_cancel")}
          </DubButton>
          <DubButton
            variant="fill"
            size="regular"
            color="magenta"
            loading={loading}
            onClick={handleSubmit}
          >
            {t("button_login")}
          </DubButton>
        </>
      }
    />
  );
};

const Text = styled(B1)`
  padding: 8px 32px 12px;
  color: var(--gray090);
`;

export default ForceSignIn;
