import { useTranslation } from "next-i18next";
import styled from "@emotion/styled";
import { EVENT, send } from "@/lib/amplitude";
import {
  ConvertText,
  Section,
  ChipText,
  TitleText,
  Wrapper,
  SubTitleText,
} from "./LandingStyle";

const Section10 = () => {
  const { t } = useTranslation();
  const classURL = `https://class.2dub.me/`;

  const onClick = () => {
    send(EVENT.CLICK__MOVE_TO_CLASS_BTN, {
      is_mobile: false,
    });
  };

  return (
    <Section bgColor="var(--violet050)">
      <Wrapper>
        <ContentWrapper>
          <TextWrapper>
            <TitleText isWhite>
              <ConvertText text={t("land_10_class_title")} />
            </TitleText>
            <SubTitleWrapper>
              <SubTitleText isWhite>
                <ConvertText text={t("land_10_class_body")} />
              </SubTitleText>
            </SubTitleWrapper>
            <ButtonWrapper>
              <ClassButton href={classURL} target="_blank" onClick={onClick}>
                <ChipText color="var(--violet050)">
                  <ConvertText text={t("button_app_teacher")} />
                </ChipText>
              </ClassButton>
            </ButtonWrapper>
          </TextWrapper>
          <Image src="/img/new_landing/common/2dub_class_logo.png" alt="" />
        </ContentWrapper>
      </Wrapper>
    </Section>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 499px) {
    width: 100%;
    align-items: center;
  }
`;

const SubTitleWrapper = styled.div`
  max-width: 460px;
  margin-top: 0px;
  margin-bottom: 32px;
  @media screen and (max-width: 599px) {
    max-width: 300px;
  }
  @media screen and (max-width: 499px) {
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const ClassButton = styled.a`
  display: flex;
  background-color: var(--white);
  border-radius: 16px;
  gap: 10px;
  padding: 12px 20px;
  cursor: pointer;
`;

const Image = styled.img`
  width: 204px;
  height: 225px;

  @media screen and (max-width: 499px) {
    display: none;
  }
`;

export default Section10;
