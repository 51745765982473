import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { type DialogProps } from "@mui/material/Dialog";
import { useTranslation } from "next-i18next";
import useResetPassword from "./hooks/resetPassword.hook";
import { AuthProps, AuthDialogType } from "./types/useAuth.types";
import DubDialog from "../common/dialog/DubDialog";
import DubButton from "../common/button/DubButton";
import { EmailAutoComplete } from "../common/textfield";

type Props = DialogProps & AuthProps;

const ResetPassword = ({ open, handleClose, handleSwitch }: Props) => {
  const { t } = useTranslation();
  const { emailProps, submit, loading } = useResetPassword();

  const buttonDisabled = emailProps.error || emailProps.value.length === 0;

  const handleSubmit = async () => {
    const success = await submit();
    if (success) {
      onSwitch(AuthDialogType.SignInDialog);
    }
  };

  const onClose = () => {
    if (loading) return;
    handleClose();
  };

  const onSwitch = (type: AuthDialogType) => {
    if (loading) return;
    handleSwitch(type);
  };

  return (
    <DubDialog
      open={open}
      onClose={onClose}
      size="medium"
      center
      header={t("account_changePW")}
      body={
        <DialogBody>
          <Box style={{ paddingBottom: 16 }}>
            <FieldBox>
              <EmailAutoComplete
                label={t("textfield_email")}
                value={emailProps.value}
                error={emailProps.error}
                helperText={emailProps.helperText}
                onChange={emailProps.onChange}
                onClickItem={(email: string) =>
                  emailProps.onChange({ target: { value: email } })
                }
              />
            </FieldBox>
          </Box>
          <ButtonContainer>
            <DubButton
              variant="text"
              color="gray"
              size="regular"
              onClick={() => onSwitch(AuthDialogType.SignInDialog)}
              style={{
                marginRight: "4px",
              }}
            >
              {t("button_login")}
            </DubButton>
            <DubButton
              variant="fill"
              color="magenta"
              size="regular"
              loading={loading}
              disabled={buttonDisabled}
              onClick={handleSubmit}
            >
              {t("button_send_pw_change")}
            </DubButton>
          </ButtonContainer>
        </DialogBody>
      }
    />
  );
};

const DialogBody = styled.div`
  padding: 24px 32px 32px;
`;

const FieldBox = styled.div`
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  text-align: end;
`;

export default ResetPassword;
