import { type TextFieldProps } from "@mui/material/TextField";
import CustomTextField from "./CustomTextField";

/**
 * rows={number} number 줄만큼 고정된 크기
 *
 * minRows={number} number 줄만큼 최소 크기를 가짐
 *
 * maxRows={number} number 줄만큼 최대 크기를 가짐
 *
 * 선없는 textarea 만들려면 variant="standard"
 *
 */

const DubTextarea = (props: TextFieldProps) => {
  const { variant } = props;
  const inputProps = {
    ...(variant === "standard" && { disableUnderline: true }),
  };

  return <CustomTextField multiline InputProps={inputProps} {...props} />;
};

export default DubTextarea;
