import { useTranslation } from "next-i18next";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { ClientLogger } from "@/scripts/log/client";
import { initCheckRemember, passwordLength, saveRemember } from "../utils";
import useTextField from "./useTextField.hook";

const KEY_REMEMBER_FLAG = "remember_class_flag";
const KEY_REMEMBER_ID = "remember_class_id";

const useClassSignIn = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { remember: initRemeber, value: initId } = useMemo(
    () => initCheckRemember(KEY_REMEMBER_FLAG, KEY_REMEMBER_ID),
    [],
  );

  // 체크 함수 더 넣어야함
  const idProps = useTextField(initId, 30, [
    {
      checker: passwordLength,
      errorText: t("textfield_more_than_6"),
    },
  ]);
  const passwordProps = useTextField("", undefined, [
    {
      checker: passwordLength,
      errorText: t("textfield_more_than_6"),
    },
  ]);

  const [loading, setLoading] = useState(false); // 로그인 버튼 로딩 상태
  const [remember, setRemember] = useState(initRemeber); // 로그인 정보 기억 상태
  const [isForce, setIsForce] = useState(false); // 강제 로그인 상태

  const handleRemember = () => {
    setRemember(!remember);
  };

  const disabledSubmit =
    idProps.error ||
    passwordProps.error ||
    idProps.value.length < 1 ||
    passwordProps.value.length < 1;

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      setLoading(true);
      const body = JSON.stringify({
        id: idProps.value,
        password: passwordProps.value,
        reg_id: localStorage.getItem("pushToken") ?? "",
        is_force: isForce ? "Y" : "N",
      });
      const response = await fetch(`/v1/auth/login/class`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      setLoading(false);
      if (response.ok) {
        const result = await response.json();
        const { code, error } = result;
        switch (code) {
          case 200:
            saveRemember(
              remember,
              KEY_REMEMBER_FLAG,
              idProps.value,
              KEY_REMEMBER_ID,
            );
            window.location.replace("/home");
            break;

          case 2012: // 해당 email로 조회되는 계정이 없을 경우
            idProps.handleError(error.errorMessage);
            break;

          case 2014: // 해당 계정이 이메일로 전환된 경우
            alert(error.errorMessage);
            break;

          case 2015: // 현재 로그인 되어 있는 계정일 경우
            setIsForce(true);
            break;

          case 2016: // 비밀번호가 틀렸을 경우
            passwordProps.handleError(error.errorMessage);
            break;

          default:
            enqueueSnackbar(error.errorMessage);
            break;
        }
      }
    } catch (error) {
      setLoading(false);
      ClientLogger.error("handleSubmit - useClassSignIn", error);
    }
  };

  return {
    idProps,
    passwordProps,
    disabledSubmit,
    loading,
    remember,
    isForce,
    handleRemember,
    handleSubmit,
  };
};

export default useClassSignIn;
