import { useTranslation } from "next-i18next";
import styled from "@emotion/styled";
import { ConvertText, TitleText } from "./LandingStyle";

const Section8 = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Wrapper>
        <TitleTextWrapper>
          <TitleText>
            <ConvertText text={t("land_8_title")} />
          </TitleText>
        </TitleTextWrapper>
        <CardWrapper>
          <Card1>
            <ConvertText text={t(`land_8_b1`)} />
          </Card1>
          <Card2>
            <ConvertText text={t(`land_8_b2`)} />
          </Card2>
          <Card3>
            <ConvertText text={t(`land_8_b3`)} />
          </Card3>
        </CardWrapper>
      </Wrapper>
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  height: 853px;
  padding: 180px 220px;
  min-width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1439px) {
    height: 1210px;
    padding: 140px 153px;
  }

  @media screen and (max-width: 1023px) {
    padding: 140px 60px;
  }

  @media screen and (max-width: 700px) {
    height: 887px;
    padding: 80px 20px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1162px;
`;

const TitleTextWrapper = styled.div`
  /* font-size: var(--fs-h2);
  line-height: var(--lh-h2);
  font-weight: 600; */
  color: var(--gray100);
  margin-bottom: 60px;
  /* @media screen and (max-width: 1439px) {
    font-size: var(--fs-h2);
    line-height: var(--lh-h2);
  } */

  /* @media screen and (max-width: 599px) {
    font-size: var(--fs-h4);
    line-height: var(--lh-h4);
  } */
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1439px) {
    flex-direction: column;
  }
`;

const Card1 = styled.div`
  margin-right: 30px;
  background: url("/img/new_landing/common/card1.png") no-repeat;
  background-color: var(--blue005);
  background-size: contain;
  background-position: right;
  width: 393px;
  height: 220px;
  border-radius: 20px;
  padding: 40px;
  font-size: var(--fs-h4);
  line-height: var(--lh-h4);
  font-weight: 600;
  color: var(--blue100);
  @media screen and (max-width: 1439px) {
    width: 100%;
    height: 180px;
    margin-right: 0;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 700px) {
    height: 180px;
    padding: 28px;
    font-size: var(--fs-h4);
    line-height: var(--lh-h4);
    margin-bottom: 20px;
  }
`;

const Card2 = styled.div`
  margin-right: 30px;
  background: url("/img/new_landing/common/card2.png") no-repeat;
  background-color: var(--violet005);
  background-size: contain;
  background-position: right;
  width: 393px;
  height: 220px;
  border-radius: 20px;
  padding: 40px;
  font-size: var(--fs-h4);
  line-height: var(--lh-h4);
  font-weight: 600;
  color: #7268be;
  @media screen and (max-width: 1439px) {
    width: 100%;
    height: 180px;
    margin-right: 0;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 700px) {
    height: 180px;
    padding: 28px;
    font-size: var(--fs-h4);
    line-height: var(--lh-h4);
    margin-bottom: 20px;
  }
`;

const Card3 = styled.div`
  background: url("/img/new_landing/common/card3.png") no-repeat;
  background-color: var(--blue005);
  background-size: 30% 60%;
  background-position: bottom right;
  width: 393px;
  height: 220px;
  border-radius: 20px;
  padding: 40px;
  font-size: var(--fs-h4);
  line-height: var(--lh-h4);
  font-weight: 600;
  color: var(--blue100);
  @media screen and (max-width: 1439px) {
    width: 100%;
    height: 180px;
    background-size: 10% 60%;
    background-position: bottom right 30px;
  }

  @media screen and (max-width: 1024px) {
    background-size: 20% 60%;
  }

  @media screen and (max-width: 700px) {
    height: 180px;
    padding: 28px;
    font-size: var(--fs-h4);
    line-height: var(--lh-h4);
  }
`;

export default Section8;
