export const playStore = (locale: string) => {
  const url = `https://play.google.com/store/apps/details?id=com.dubberss.koo.play&hl=${locale}`;

  window.open(url, "_blank");
};

export const appStore = (locale: string) => {
  const location =
    {
      ko: "kr",
      en: "us",
      ja: "jp",
    }[locale] ?? "us";

  const url = `https://apps.apple.com/${location}/app/2dub/id1244037112`;

  window.open(url, "_blank");
};

export const deviceStore = (locale: string) => {
  const isIos = navigator.userAgent.match(/iPhone|iPad|iPod/);

  if (isIos) {
    appStore(locale);
  } else {
    playStore(locale);
  }
};
