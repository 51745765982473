import { type TextFieldProps } from "@mui/material/TextField";
import CustomTextField from "./CustomTextField";

const DubSelect = (props: TextFieldProps) => {
  const { children, ...rest } = props;
  return (
    <CustomTextField select {...rest}>
      {children}
    </CustomTextField>
  );
};

export default DubSelect;
