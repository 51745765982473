import getLocales from "@/modules/utils/getLocales";
import { GetServerSidePropsContext } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Layout from "@/modules/layout/Layout";
import Landing from "@/modules/landing/Landing";
import LocaleProvider from "@/modules/provider/LocaleProvider";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import ENV_SERVER from "@/scripts/server/ENV_SERVER";

const LandingPage = ({ socialError, locale }: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (socialError) {
      enqueueSnackbar(socialError);
    }
  }, []);
  return (
    <Layout locale={locale} title={t("browser_landing")}>
      <LocaleProvider value={locale}>
        <Landing />
      </LocaleProvider>
    </Layout>
  );
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const option = {
    header: true,
    cookie: true,
    query: true,
  };
  const locale = getLocales(context, option);

  const socialError = context.req.cookies.social_error ?? "";
  const token = context.req.cookies[ENV_SERVER.TOKEN_ACCESS_KEY];
  if (token) {
    return {
      redirect: {
        destination: `/list`,
        permanent: false,
      },
    };
  }
  return {
    props: {
      locale,
      socialError,
      ...(await serverSideTranslations(locale)),
    },
  };
}

export default LandingPage;
