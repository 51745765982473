import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { B2, H4 } from "../common/fonts";
import { AuthProps } from "./types/useAuth.types";

type Props = DialogProps & AuthProps;

const ClassSignUp = ({ ...props }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { locale = "en" } = router;
  const openTab = () => {
    window.open(`https://learning.2dub.me?dl=${locale}`);
  };

  return (
    <Dialog {...props} fullWidth maxWidth="dialog-md">
      <DialogContent>
        <DialogBody>
          <ImageBox>
            <Image
              width={82}
              height={82}
              alt=""
              src="/img/common/learning.svg"
            />
          </ImageBox>
          <Title>{t("install_title")}</Title>
          <Body dangerouslySetInnerHTML={{ __html: t("install_body1") }} />
          <ButtonContainer>
            <Button
              variant="contained"
              color="magenta"
              size="regular"
              disableElevation
              onClick={openTab}
            >
              {t("install_button_go_learning")}
            </Button>
          </ButtonContainer>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

const DialogBody = styled.div`
  padding: 40px 32px 48px;
  text-align: center;
`;

const ImageBox = styled.div`
  display: inline-block;
  border-radius: 9999px;
  border: 1px solid var(--gray040);
  margin-bottom: 16px;
  padding: 16px 13px 16px 19px;
`;

const Image = styled.img``;

const Title = styled(H4)`
  font-weight: 600;
  margin-bottom: 14px;
`;

const Body = styled(B2)`
  margin-bottom: 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default ClassSignUp;
