import styled from "@emotion/styled";
import { type DialogProps } from "@mui/material/Dialog";
import { useTranslation } from "next-i18next";
import { DubButton } from "../common/button";
import { DubDialog } from "../common/dialog";

type Props = DialogProps & {
  title: string;
  body: string;
  handleClose: () => void;
};
const Alert = ({ open, title, body, handleClose }: Props) => {
  const { t } = useTranslation();
  return (
    <DubDialog
      open={open}
      onClose={handleClose}
      fullWidth
      size="regular"
      header={title}
      body={<DialogBody>{body}</DialogBody>}
      footer={
        <DubButton
          variant="contained"
          size="regular"
          color="magenta"
          onClick={handleClose}
        >
          {t("button_check")}
        </DubButton>
      }
    />
  );
};

const DialogBody = styled.div`
  font-size: var(--fs-b1);
  line-height: var(--lh-b1);
  color: var(--gray090);
  padding: 8px 32px 12px;
`;

export default Alert;
