import { EVENT, send } from "@/lib/amplitude";
import { useModal } from "mui-modal-provider";
import { useTranslation } from "next-i18next";
import { useSnackbar } from "notistack";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { ClientLogger } from "@/scripts/log/client";
import Alert from "../Alert";
import { AuthDialogType } from "../types/useAuth.types";
import { passwordLength, isEmail } from "../utils";
import useTextField from "./useTextField.hook";

/*
  1. 이메일을 입력을 받기
  2. 가입하기 버튼 누르면 이메일 발송 다이얼로그
  3. 10초 주기로 탭이 액티브할때만 Polling으로 이메일 인증 여부 체크
  4. 비밀번호 입력 보여주고 입력 받고
  5. 이메일이랑 비밀번호 보내서 가입하기
  6. 생일 입력 다이얼로그 띄워주기
*/

const useSignUp = (
  handleSwitch: (type: AuthDialogType) => void,
  locale: string,
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [sendedEmail, setSendedEmail] = useState(""); // 전송한 이메일, 이메일 전송 여부,
  const [codeVerifying, setCodeVerifying] = useState(false);
  const [verifyCode, setVerifyCode] = useState(""); // 인증 코드 확인증
  const [codeVerified, setCodeVerified] = useState(false); // 인증 코드 인증 완료

  useEffect(() => {
    send(EVENT.ENTER__SIGNUP);
  }, []);

  // 1. email
  const emailProps = useTextField("", undefined, [
    {
      checker: isEmail,
      errorText: t("textfield_email_wrong_format"),
    },
  ]);

  // 이메일 보내기전에는 가입하기
  // 이메일 보낸 후에는 확인해주세요
  // 인증된 후에는 다음
  const sendButtonText = useMemo(() => {
    if (codeVerified) {
      return t("button_next");
    }

    if (codeVerifying) {
      return t("button_check_mailbox");
    }

    return t("button_join");
  }, [codeVerified, codeVerifying, t]);

  // 1. 이메일 중복 검사
  const handleSendEmail = async () => {
    try {
      const params = new URLSearchParams({
        dl: locale,
        email: emailProps.value,
      });

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/api/v2/auth/account/check?${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.ok) {
        const result = await response.json();
        const { code, error } = result;
        switch (code) {
          case 200:
            handleSendVerifyEmail();
            break;
          case 1102: // 올바른 이메일 식이 아님
            emailProps.handleError(error.errorMessage);
            break;
          case 2017: // 계속된 메일 전송 시도
            enqueueSnackbar(error.errorMessage);
            setSendedEmail(emailProps.value);
            break;
          case 2018: // 이메일 전송실패
            alert(error.errorMessage);
            break;
          case 2024: // 이미 존재하는 이메일 계정
            emailProps.handleError(error.errorMessage);
            break;
          default:
            enqueueSnackbar(error.errorMessage);
            break;
        }
      }
    } catch (error) {
      ClientLogger.error("handleSendEmail - useSignUp", error);
    }
  };

  // 2. 이메일 인증 보내기
  const handleSendVerifyEmail = async () => {
    try {
      if (sendedEmail) {
        send(EVENT.CLICK__SEND_VERIFICATION_EMAIL);
      } else {
        send(EVENT.CLICK__RESEND_VERIFICATION_EMAIL);
      }

      const body = JSON.stringify({
        email: emailProps.value,
        device_platform: "W",
        dl: locale,
      });

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/api/v2/auth/email/verification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        },
      );

      const result = await response.json();
      const { code, data, error } = result;
      switch (code) {
        case 200:
          {
            const modal = showModal(Alert, {
              title: t("dialog_email_sent"),
              body: t("dialog_email_sent_body"),
              handleClose: () => {
                modal.hide();
              },
            });
          }
          setVerifyCode(data.code);
          setSendedEmail(emailProps.value);
          setCodeVerifying(true);
          startVerifyTimeRef.current = Date.now();
          break;

        case 1102: // 올바른 이메일 식이 아님
        case 2024: // 이미 존재하는 이메일 계정
          emailProps.handleError(error.errorMessage);
          break;

        case 2017: // 계속된 메일 전송 시도
          enqueueSnackbar(error.errorMessage);
          setSendedEmail(emailProps.value);
          break;
        case 2018: // 이메일 전송실패
        case 2023: // 이미 존재하는 이메일 계정
          emailProps.handleError(error.errorMessage);
          break;

        default:
          enqueueSnackbar(error.errorMessage);
          break;
      }
    } catch (error) {
      ClientLogger.error("handleSendVerifyEmail - useSignUp", error);
    }
  };

  const verifyTimeIdRef = useRef<number | null>(null);
  const startVerifyTimeRef = useRef<number>(0);

  // 이메일 인증하기
  const handleVerifyEmail = async () => {
    try {
      const params = new URLSearchParams({
        dl: locale,
        code: verifyCode,
      });

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/api/v2/auth/email/verification?${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (!response.ok) {
        return;
      }

      const result = await response.json();
      const { code, error } = result;
      switch (code) {
        case 200:
          send(EVENT.DONE__VERIFY_EMAIL);
          setCodeVerifying(false);
          setCodeVerified(true);
          break;
        case 400:
        case 1028: // 인증코드를 파라미터로 요청하지 않은경우
        case 2019: //	인증코드가 올바르지 않은 경우
          enqueueSnackbar(error.errorMessage);
          break;
        case 2020: // 인증코드의 유효기간이 지난 경우
          setCodeVerifying(false);
          setVerifyCode("");
          break;
        case 2032: // 인증이 완료되지 않은 코드인 경우
          verifyTimeIdRef.current = setTimeout(handleVerifyEmail, 5 * 1000);
          break;
        default:
          enqueueSnackbar(error.errorMessage);
          break;
      }
    } catch (error) {
      ClientLogger.error("handleVerifyEmail - useSignUp", error);
    }
  };

  useEffect(() => {
    if (sendedEmail !== emailProps.value) {
      setCodeVerifying(false);
      setVerifyCode("");
    }
  }, [emailProps.value, sendedEmail]);

  useEffect(() => {
    const handleFocus = () => {
      if (
        !verifyTimeIdRef.current &&
        Date.now() - startVerifyTimeRef.current > 5 * 60 * 1000
      ) {
        handleVerifyEmail();
      }
    };
    const handleBlur = () => {
      if (
        verifyTimeIdRef.current &&
        Date.now() - startVerifyTimeRef.current > 5 * 60 * 1000
      ) {
        clearTimeout(verifyTimeIdRef.current);
        verifyTimeIdRef.current = null;
      }
    };

    if (codeVerifying) {
      handleVerifyEmail();
      window.addEventListener("focus", handleFocus);
      window.addEventListener("blur", handleBlur);

      return () => {
        handleBlur();
        window.removeEventListener("focus", handleFocus);
        window.removeEventListener("blur", handleBlur);
      };
    }
  }, [codeVerifying, verifyCode]);

  // password
  const passwordProps = useTextField("", undefined, [
    {
      checker: passwordLength,
      errorText: t("textfield_more_than_6"),
    },
  ]);

  const disabledSubmit = useMemo(() => {
    // 인증 후
    if (codeVerified) {
      // 비밀번호 값만 확인
      return passwordProps.error || passwordProps.value.length < 1;
    }
    // 인증전
    // 이메일 값만 확인
    return emailProps.error || emailProps.value.length < 1;
  }, [codeVerified, passwordProps, emailProps]);

  const handleSubmit = () => {
    handleSwitch(AuthDialogType.ExtraInfo, {
      email: emailProps.value,
      password: passwordProps.value,
      type: "E",
    });
  };

  // 이메일 인증 전 : sendEmail
  // 인증 후 : email, pw정보 저장 후 설문으로
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (codeVerified) {
      handleSubmit();
    } else {
      handleSendEmail();
    }
  };

  return {
    emailProps,
    passwordProps,
    codeVerified,
    disabledSubmit,
    onSubmit,
    sendButtonText,
  };
};

export default useSignUp;
