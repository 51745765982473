import { EVENT, send } from "@/lib/amplitude";
import { getUTM, removeUTM } from "@/modules/hooks/useUTM.hooks";
import { getUUID } from "@/modules/hooks/useUUID.hooks";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ClientLogger } from "@/scripts/log/client";
import { ACCOUNT_TYPE, AuthInfo } from "../types/useAuth.types";

const useExtraInfo = (auth_info: AuthInfo) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    send(EVENT.ENTER__SIGNUP_2ND_PROCESS, {
      account_type: ACCOUNT_TYPE[auth_info.type],
    });
  }, []);

  // 소셜 로그인 [G:Google, I:애플로그인, K:카카오, N:네이버]
  const social = ["G", "I", "K", "N"];

  const [step, setStep] = useState(0);
  const handleStep = (nextStep: number) => {
    setStep(nextStep);
  };

  // 생녕월일, 유입경로, 초대코드
  const [extraInfo, setExtraInfo] = useState({
    birthday: "",
    selectPath: {
      platform_channel: [],
      visit_route: [],
      visit_route_user_text: "",
    },
    inviteCode: "",
  });

  // 생년월일
  const handleBirthdayProps = (newBirthday: string) => {
    setExtraInfo((curr) => ({ ...curr, birthday: newBirthday }));
  };

  // 유입경로
  const handleSelectPathsProps = (data: any) => {
    setExtraInfo((curr) => ({ ...curr, selectPath: data }));
  };

  // 초대코드
  const handleInviteCode = (code: string) => {
    setExtraInfo((curr) => ({ ...curr, inviteCode: code }));
    if (social.includes(auth_info.type)) {
      handleSubmitSocialRegist();
    } else {
      handleSubmitRegist();
    }
  };

  // 회원가입
  const handleSubmitRegist = async () => {
    setLoading(true);
    const utmData = getUTM();
    const uuid = getUUID();

    try {
      const userInfo = {
        email: auth_info.email,
        password: auth_info.password,
        device_platform: "W",
        birth_date: extraInfo.birthday,
        platform_channel: extraInfo.selectPath.platform_channel,
        invite_code: extraInfo.inviteCode,
        reg_id: localStorage.getItem("pushToken") ?? "",
        timezone_offset: -(new Date().getTimezoneOffset() / 60),
        uuid,
        ...(utmData && { ...utmData }),
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userInfo),
      };

      const response = await fetch(
        `/v2/auth/account/register/email`,
        requestOptions,
      );

      const result = await response.json();
      const { code, data, error } = result;

      setLoading(false);
      switch (code) {
        case 200:
          send(EVENT.DONE__SIGNUP, {
            account_type: ACCOUNT_TYPE[auth_info.type],
            visit_route: extraInfo.selectPath.visit_route,
            visit_route_user_text: extraInfo.selectPath.visit_route_user_text,
          });
          setName(data.user.name);
          setStep(3);
          removeUTM();
          break;
        case 400:
        case 1023: // 이메일을 파라미터로 요청하지 않은 경우
        case 1102: // 올바른 이메일 형식이 아닌경우
        case 1103: // 비밀번호가 6자 미만인경우
        case 2024: //	패스워드를 파라미터로 요청하지 않은경우
          enqueueSnackbar(error.errorMessage);
          break;
        default:
          break;
      }
    } catch (error) {
      setLoading(false);
      ClientLogger.error("handleSubmitRegist - useExtraInfo", error);
    }
  };

  // 소셜 회원가입
  const handleSubmitSocialRegist = async () => {
    setLoading(true);
    const utmData = getUTM();
    const uuid = getUUID();

    try {
      const userInfo = {
        access_token: auth_info.token,
        account_type: auth_info.type,
        device_platform: "W",
        authorization_code: auth_info.authorization_code,
        birth_date: extraInfo.birthday,
        platform_channel: extraInfo.selectPath.platform_channel,
        invite_code: extraInfo.inviteCode,
        reg_id: localStorage.getItem("pushToken") ?? "",
        timezone_offset: -(new Date().getTimezoneOffset() / 60),
        uuid,
        ...(utmData && { ...utmData }),
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userInfo),
      };

      const response = await fetch(
        `/v2/auth/account/register/social`,
        requestOptions,
      );

      const result = await response.json();
      const { code, data, error } = result;
      setLoading(false);
      switch (code) {
        case 200:
          send(EVENT.DONE__SIGNUP, {
            account_type: ACCOUNT_TYPE[auth_info.type],
            visit_route: extraInfo.selectPath.visit_route,
            visit_route_user_text: extraInfo.selectPath.visit_route_user_text,
          });
          setName(data.user.name);
          setStep(3);
          removeUTM();
          break;
        case 400:
        case 1023: // 이메일을 파라미터로 요청하지 않은 경우
        case 1102: // 올바른 이메일 형식이 아닌경우
        case 1103: // 비밀번호가 6자 미만인경우
        case 2024: //	패스워드를 파라미터로 요청하지 않은경우
          enqueueSnackbar(error.errorMessage);
          break;
        default:
          break;
      }
    } catch (error) {
      setLoading(false);
      ClientLogger.error("handleSubmitSocialRegist - useExtraInfo", error);
    }
  };

  const handleCloseWelcomeDialog = () => {
    window.location.replace("/watch-log");
  };

  return {
    handleBirthdayProps,
    handleSelectPathsProps,
    handleInviteCode,
    handleCloseWelcomeDialog,
    handleStep,
    step,
    name,
    loading,
  };
};

export default useExtraInfo;
