import amplitude, { AmplitudeClient } from "amplitude-js";

const ENV_FULL_TEXT = process.env.NEXT_PUBLIC_ENVIRONMENT || "P";
const ENV_CODE = ENV_FULL_TEXT[0].toUpperCase();

export const EVENT = {
  CLICK__LANDING_TERMS_BTN: "click_landingTermsBtn",
  CLICK__LANDING_SNS_BTN: "click_landingSnsBtn",
  CLICK__LANDING_PRIVACY_BTN: "click_landingPrivacyBtn",
  CLICK__LANDING_ABOUT_BTN: "click_landingAboutBtn",
  CLICK__LANDING_CONTACT_BTN: "click_landingContactBtn",
  CLICK__GNB_SIGN_IN: "click_gnbSignin",
  CLICK__GNB_SIGN_UP: "click_gnbSignup",
  CLICK__LANDING_DOWNLOAD_BTN: "click_landingDownloadBtn",
  CLICK__LANDING_ACTION_REC: "click_landingActionRec",
  CLICK__LANDING_ACTION_DUB_PLAY: "click_landingActionDubPlay",
  CLICK__LANDING_ACTION_DUB_PREVIEW: "click_landingActionDubPreview",
  CLICK__LANDING_ACTION_PLAY: "click_landingActionPlay",
  CLICK__MOVE_TO_CLASS_BTN: "click_moveToClassBtn",
  CLICK__LANDING_SIGN_UP: "click_landingSignup",
  CLICK__LANDING_PLAY_DUB_VIDEO: "click_landingPlayDubVideo",
  CLICK__MOVE_TO_GOOGLE_PLAY: "click_moveToGooglePlay",
  CLICK__MOVE_TO_APP_STORE: "click_moveToAppStore",
  CLICK__CONFIRM_SIGN_UP: "click_confirmSignup",
  CLICK__SEND_CODE: "click_sendCode",
  CLICK__CONFIRM_SIGNIN: "click_confirmSignin",
  ENTER__SCREEN: "enter_screen",
  CLICK__RESET_WORD_ORDER_BTN: "click_resetWordOrderBtn",
  CLICK__CONFIRM_WORD_ORDER_BTN: "click_confirmWordOrderBtn",
  CLICK__PLAY_AUDIO_WORD_ORDER_BTN: "click_playAudioWordOrderBtn",
  CLICK__MOVE_TO_PREV_PAGE: "click_moveToPrevPage",
  CLICK__EXIT_WORD_ORDER_BTN: "click_exitWordOrderBtn",
  CLICK__CONTINUE_WORD_ORDER_BTN: "click_continueWordOrderBtn",
  CLICK__MOVE_TO_HOME_BTN: "click_moveToHomeBtn",
  CLICK__RETRY_WORD_ORDER_BTN: "click_retryWordOrderBtn",
  CLICK__START_DUBBING_BTN: "click_startDubbingBtn",
  CLICK__START_WORD_ORDER_BTN: "click_startWordOrderBtn",
  CLICK__RECOMMENDED_VIDEO_ITEM: "click_recommendedVideoItem",
  CLICK_ROW__WORD_ORDER_RANKING: "clickRow_wordOrderRanking",
  CLICK_TIP__WORD_ORDER_RANKING: "clickTip_wordOrderRanking",
  CLICK__EXTERNAL_LINK: "click_externalLink",
  CLICK__PURCHASE: "click_purchase",
  SELECT__PLAN: "select_plan",
  CLICK__GROUP_DISCOUNT: "click_groupDiscount",
  CLICK__DETAIL_BENEFIT: "click_detailBenefit",
  CLICK__REPURCHASE_BANNER: "click_repurchaseBanner",
  ENTER__HALLOWEEN: "enter_halloween",
  CLICK__HALLOWEEN_BANNER: "click_halloweenBanner",
  CLICK__HALLOWEEN_POPUP: "click_halloweenPopup",
  CLICK__MISSION_BTN: "click_missionBtn",
  CLICK__COMPLETE_MISSION_BTN: "click_completeMissionBtn",
  CLICK__OPEN_MISSION: "click_openMission",
  ENTER__THANKSGIVING: "enter_thanksgiving",
  CLICK__ENTER_MISSION: "click_enterMission",
  CLICK__GET_THANKSGIVING_BADGE: "click_getThanksgivingBadge",
  ENTER__THANKSGIVING_QUIZ: "enter_thanksgivingQuiz",
  START__THANKSGIVING_QUIZ: "start_thanksgivingQuiz",
  END__THANKSGIVING_QUIZ: "end_thanksgivingQuiz",
  ENTER__THANKSGIVING_WORD_SEARCH: "enter_thanksgivingWordSearch",
  START__THANKSGIVING_WORD_SEARCH: "start_thanksgivingWordSearch",
  END__THANKSGIVING_WORD_SEARCH: "end_thanksgivingWordSearch",
  ENTER__SIGNIN: "enter_signIn",
  CLICK__SIGNIN_BY_EMAIL: "click_signinByEmail",
  CLICK__SIGNIN_BY_APPLE: "click_signinByApple",
  CLICK__SIGNIN_BY_GOOGLE: "click_signinByGoogle",
  CLICK__SIGNIN_BY_KAKAO: "click_signinByKakao",
  CLICK__SIGNIN_BY_NAVER: "click_signinByNaver",
  ENTER__SIGNUP: "enter_signUp",
  CLICK__SIGNUP_BY_NAVER: "click_signupByNaver",
  CLICK__SIGNUP_BY_KAKAO: "click_signupByKakao",
  CLICK__SIGNUP_BY_APPLE: "click_signupByApple",
  CLICK__SIGNUP_BY_GOOGLE: "click_signupByGoogle",
  CLICK__SEND_VERIFICATION_EMAIL: "click_sendVerificationEmail",
  CLICK__RESEND_VERIFICATION_EMAIL: "click_resendVerificationEmail",
  DONE__VERIFY_EMAIL: "done_verifyEmail",
  ENTER__SIGNUP_2ND_PROCESS: "enter_signUp2ndProcess",
  CLICK__CONFIRM_BIRTHDAY: "click_confirmBirthday",
  CLICK__SKIP_BIRTHDAY: "click_skipBirthday",
  CLICK__CONFIRM_HEAR_ABOUT_US: "click_confirmHearAboutUs",
  CLICK__SKIP_HEAR_ABOUT_US: "click_skipHearAboutUs",
  CLICK__CONFIRM_INVITE_CODE: "click_confirmInviteCode",
  CLICK__SKIP_INVITE_CODE: "click_skipInviteCode",
  DONE__SIGNUP: "done_signup",
  ENTER__WELCOME_DIALOG: "enter_welcomeDialog",
  CLICK__MAKE_SHORT_DUB: "click_makeShortDub",
  ENTER__DUB_ROOM: "enter_dubRoom",
  EXIT__DUB_ROOM: "exit_dubRoom",
  PLAY__ORG_SCRIPT: "play_orgScript",
  PLAY__DUB_SCRIPT: "play_dubScript",
  REC__DUB_SCRIPT: "rec_dubScript",
  SELECT__DUBBING_TIMER: "select_dubbingTimer",
  SELECT__DUBBING_GRAPH: "select_dubbingGraph",
  SELECT__DUBBING_BLIND: "select_dubbingBlind",
  SELECT__DUBBING_TRANS: "select_dubbingTrans",
  SELECT__DUBBING_SPEED: "select_dubbingSpeed",
  CLICK__SAVE_DUBBING: "click_saveDubbing",
  PLAY__DUB_VIDEO: "play_dubVideo",
  DONE__DUB_ROOM: "done_dubRoom",
  POST__BOOKMARK_SCRIPT: "post_bookmarkScript",
  CLICK__DUB_QUESTIOM: "click_dubQuestion",
  SELECT__SHORT_DUB_IMAGE: "select_shortDubImage",
  CLICK__SHORT_DUB_OPTION_TAB: "click_shortDubOptionTab",
  CONFIRM__SHORT_DUB: "confirm_shortDub",
  SELECT__SHORT_DUB_THEME: "select_shortDubTheme",
  SELECT__SHORT_DUB_SCRIPT: "select_shortDubScript",
  EXIT__SHORT_DUB: "exit_shortDub",
  SHARE__SHORT_DUB: "share_shortDub",
  SELECT__SHORT_DUB_TEMPLATE: "select_shortDubTemplate",
  ENTER__COUPON_BOX: "enter_couponBox",
  CLICK__REGISTER_COUPON: "click_registerCoupon",
  DONE__REGISTER_COUPON: "done_registerCoupon",
  FAIL__REGISTER_COUPON: "fail_registerCoupon",
  CLICK__COUPON_CARD: "click_couponCard",
  SWITCH_TAB__COUPON: "switchTab_coupon",
  ENTER__COUPON_HISTORY: "enter_couponHistory",
  USE__TICKET: "use_ticket",
  USE__DUB_ITEM: "use_dubItem",
  CLICK__TOGGLE_DUB_ITEM: "click_toggleDUBItem",
  DONE__PURCHASE: "done_purchase",
  ENTER__PURCHASE_SPK_RPT: "enter_purchaseSpkRpt",
  CLICK_TIP__SPK_RPT: "clickTip_spkRpt",
  CLICK__PURCHASE_SPK_RPT: "click_purchaseSpkRpt",
  DONE__PURCHASE_SPK_RPT: "done_purchaseSpkRpt",
  CLICK__DUBBING_COUPON_BOX: "click_dubbingCouponBox",
  CLICK__DUB_ITEM_COUPON_CARD: "click_dubItemCouponCard",
  ENTER__CHRISTMAS: "enter_christmas",
  SELECT__CHRISTMAS_CARD_VIDEO: "select_christmasCardVideo",
  SELECT__CHRISTMAS_CARD_THEME: "select_christmasCardTheme",
  CLICK__DECORATE_CHRISTMAS_CARD: "click_decorateChristmasCard",
  CLICK__SHARE_CHRISTMAS_CARD: "click_shareChristmasCard",
  SHARE__CHRISTMAS_CARD: "share_christmasCard",
  CLICK__RESET_CHRISTMAS_CARD: "click_resetChristmasCard",
  PLAY__PREVIEW_VIDEO_CHRISTMAS_CARD: "play_previewVideoChristmasCard",
  PLAY__ORG_SCRIPT_CHRISTMAS_CARD: "play_orgScriptChristmasCard",
  REC__DUB_SCRIPT_CHRISTMAS_CARD: "rec_dubScriptChristmasCard",
  PLAY__DUB_SCRIPT_CHRISTMAS_CARD: "play_dubScriptChristmasCard",
  ENTER__CHRISTMAS_DUBBED: "enter_christmasDubbed",
  PLAY__DUB_VIDEO_CHRISTMAS_CARD: "play_dubVideoChristmasCard",
  CLICK__RESHARE_CHRISTMAS_CARD: "click_reshareChristmasCard",
  CLICK__REPLY_CHRISTMAS_CARD: "click_replyChristmasCard",
  ENTER__DUB_EVENT_EXAM: "enter_dubEventExam",
  CLOSE__DUB_EVENT_EXAM_MESSAGE: "close_dubEventExamMessage",
  PASS__DUB_EVENT_EXAM_PASS_STRING: "pass_dubEventExamPassString",
  FAIL__DUB_EVENT_EXAM_PASS_STRING: "fail_dubEventExamPassString",
  CLOSE__DUB_EVENT_EXAM_TIP_DIALOG: "close_dubEventExamTipDialog",
  PLAY__ORG_SCRIPT_EVENT_EXAM: "play_orgScriptEventExam",
  REC__DUB_SCRIPT_EVENT_EXAM: "rec_dubScriptEventExam",
  PLAY__DUB_SCRIPT_EVENT_EXAM: "play_dubScriptEventExam",
  CLICK__COMPLETE_DUB_EVENT_EXAM: "click_completeDubEventExam",
  RETRY__DUB_EVENT_EXAM: "retry_dubEventExam",
  SUBMIT__DUB_EVENT_EXAM: "submit_dubEventExam",
  DONE__DUB_EVENT_EXAM: "done_dubEventExam",
  FAIL__DUB_EVENT_EXAM: "fail_dubEventExam",
  CLICK__TOUR2DUB_EVENT_EXAM: "click_tour2DubEventExam",
  ENTER__REPORT_EVENT_EXAM: "enter_reportEventExam",
  CLICK__REPORT_EVENT_EXAM_MESSAGE: "click_reportEventExamMessage",
  PLAY__DUB_VIDEO_REPORT_EVENT_EXAM: "play_dubVideoReportEventExam",
  CLICK__SPK_RPT_BANNER_REPORT_EVENT_EXAM: "click_spkRptBannerReportEventExam",
  SCRATCH__COUPON_REPORT_EVENT_EXAM: "scratch_couponReportEventExam",
  CLICK__PURCHASE_REPORT_EVENT_EXAM: "click_purchaseReportEventExam",
  ENTER__PURCHASE_EVENT_EXAM: "enter_purchaseEventExam",
  DONE__PURCHASE_EVENT_EXAM: "done_purchaseEventExam",
  CLICK__MOVE_TO_PURCHASE_BTN_ON_LANDING: "click_moveToPurchaseBtnOnLanding",

  CLICK__MOVE_TO_REVIEW: "click_moveToReview",
  ENTER__INTERNAL7DAY_TRIAL: "enter_internal7dayTrial",
  SCRATCH__COUPON_ON_EVENT: "scratch_couponOnEvent",
  CLICK__PURCHASE_ON_EVENT: "click_purchaseOnEvent",
  ENTER__PURCHASE_EVENT: "enter_purchaseEvent",
  DONE__PURCHASE_EVENT: "done_purchaseEvent",
  ENTER__EXTERNAL7DAY_TRIAL: "enter_external7dayTrial",
  PLAY__ORG_SCRIPT_ON_EVENT_DUB: "play_orgScriptOnEventDub",
  REC__DUB_SCRIPT_ON_EVENT_DUB: "rec_dubScriptOnEventDub",
  PLAY__DUB_SCRIPT_ON_EVENT_DUB: "play_dubScriptOnEventDub",
  PLAY__DUB_VIDEO_ON_EVENT_DUB: "play_dubVideoOnEventDub",
  CLICK__MOVE_TO_APP: "click_moveToApp",
  CLICK__REGISTER_EVENT: "click_registerEvent",

  ENTER__RECEIVE_BADGER: "enter_receiveBadger",
  ENTER__PURCHASE_BADGER: "enter_purchaseBadger",
  SCRATCH__COUPON_BADGER: "scratch_couponBadger",
  CLICK__PURCHASE_BADGER: "click_purchaseBadger",

  VIEW__DUB_VIDEO: "view_dubVideo",
  CLICK__LIKE_BTN_ON_DUBBED_PAGE: "click_likeBtnOnDubbedPage",
  CLICK__COMMENT_BTN_ON_DUBBED_PAGE: "click_commentBtnOnDubbedPage",
  CLICK__SHARE_BTN_ON_DUBBED_PAGE: "click_shareBtnOnDubbedPage",
  CLICK__PROFILE_AREA_ON_DUBBED_PAGE: "click_profileAreaOnDubbedPage",
  SWIPE__DUB_VIDEO: "swipe_dubVideo",
  OPEN__DOWNLOAD_APP_POPUP: "open_downloadAppPopup",
  CONFIRM__DOWNLOAD_APP_POPUP: "confirm_downloadAppPopup",
  CLOSE__DOWNLOAD_APP_POPUP: "close_downloadAppPopup",
  ENTER__DOWNLOAD_APP_DIALOG: "enter_downloadAppDialog",
  CONFIRM__DOWNLOAD_APP_DIALOG: "confirm_downloadAppDialog",
  CLOSE__DOWNLOAD_APP_DIALOG: "close_downloadAppDialog",
  SHARE__DUB_VIDEO: "share_dubVideo",

  ENTER__DETAIL: "enter_detail",
  VIEW__ORGVIDEO: "view_orgVideo",
  PLAY__ORGVIDEO: "play_orgVideo",
  CLICK__DETAILDOWNLOADBTN: "click_detailDownloadBtn",
  CLICK__DETAILDUBBINGBTN: "click_detailDubbingBtn",
  PLAY__ORGSCRIPTONDETAIL: "play_orgScriptOnDetail",
  REC__DUBSCRIPTONDETAIL: "rec_dubScriptOnDetail",
  PLAY__DUBSCRIPTONDETAIL: "play_dubScriptOnDetail",
  CLICKTIP__DETAILRANKING: "clickTip_detailRanking",
  CLICK__DETAILRANKINGROW: "click_detailRankingRow",
  CLICK__DETAILWORDCHIP: "click_detailWordChip",
} as const;
export type EVENT_TYPE = typeof EVENT[keyof typeof EVENT];

let instance: AmplitudeClient | null = null;

if (typeof window !== "undefined" && instance === null) {
  if (!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
    console.log("AMPLITUDE_API_KEY NOT FOUND");
  } else {
    // initialize the client
    console.log("initialize the client AMPLITUDE");
    instance = amplitude.getInstance();
    instance.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
    instance.setUserProperties({ environment: ENV_CODE });
  }
}

export const send = (event: string, data?: any) => {
  if (typeof window === "undefined") return;

  if (!instance) {
    console.log("amplitudeClient not found");
    return;
  }
  if (!event) {
    console.log("###[ERROR]### - send log empty_params", JSON.stringify(data));
  }

  instance.logEvent(event, data);
};

export const setIdentify = (data: any) => {
  if (typeof window === "undefined") return;

  if (!instance) {
    console.log("amplitudeClient not found");
    return;
  }

  const identifyObj = new instance.Identify();
  Object.entries(data).forEach(([key, value]) => {
    if (value) identifyObj.setOnce(key, value);
  });
  instance.identify(identifyObj);
};

export const setUserProperties = (
  token: any,
  user: {
    user_idx: number;
    membership: string;
  } | null,
  locale: any,
  utm: {
    utm_content: string;
    utm_medium: string;
    utm_source: string;
    utm_campaign: string;
  } | null,
) => {
  if (typeof window === "undefined") return;

  if (!instance) {
    console.log("amplitudeClient not found");
    return;
  }

  const userProps = {
    ...(user && {
      user_idx: user?.user_idx,
      user_membership: user?.membership,
      membership: user?.membership,
    }),
    dl: locale,
    // device_flag: "",
    // device_model: "",
    // os_version: "",
    token,
  };

  if (utm) {
    setIdentify(utm);
  }

  if (user?.user_idx) {
    instance.setUserId(`${user.user_idx}`);
  }

  instance.setUserProperties({
    environment: ENV_CODE,
    ...userProps,
  });
};

export const clearUserProperties = () => {
  if (typeof window === "undefined") return;

  if (!instance) {
    console.log("amplitudeClient not found");
    return;
  }

  instance.clearUserProperties();
};
