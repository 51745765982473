import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { EVENT, send } from "@/lib/amplitude";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section6 from "./components/Section6";
import Section7 from "./components/Section7";
import Section8 from "./components/Section8";
import Section9 from "./components/Section9";
import Section10 from "./components/Section10";
import Section11 from "./components/Section11";
import Section12 from "./components/Section12";
import BackgroundSection from "./components/BackgroundSection";
import FloatingBar from "./components/FloatingBar";
import Header from "./components/Header";
import Footer from "./components/Footer";

const Landing = () => {
  const [isFloatingBarVisible, setIsFloatingBarVisible] = useState(false);

  const floatingStartRef = useRef<HTMLDivElement>(null);
  const floatingEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.classList.add("scrollbar");

    send(EVENT.ENTER__SCREEN, {
      screen: "Landing",
      is_mobile: false,
    });

    const handleScroll = () => {
      if (!floatingStartRef.current || !floatingEndRef.current) {
        return;
      }
      const { scrollY } = window;
      const section6Top =
        floatingStartRef.current.getBoundingClientRect().top +
        scrollY -
        (window.innerHeight -
          floatingStartRef.current.getBoundingClientRect().height);
      const section11Top =
        floatingEndRef.current.getBoundingClientRect().top +
        scrollY -
        window.innerHeight;

      if (scrollY > section6Top && scrollY < section11Top) {
        setIsFloatingBarVisible(true);
      } else {
        setIsFloatingBarVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      document.body.classList.remove("scrollbar");
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Section>
      <Header />
      <Main>
        <Section1 />
        <Section2 />
        {/* 3, 4, 5 영역 */}
        <BackgroundSection />
        <FloatingStart ref={floatingStartRef}>
          <Section6 />
        </FloatingStart>
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <FloatingEnd ref={floatingEndRef}>
          <Section11 />
        </FloatingEnd>
        <Section12 />
        <Footer />
        <FloatingBar className={isFloatingBarVisible ? "visible" : ""} />
      </Main>
    </Section>
  );
};

const Section = styled.div`
  background: var(--white);
`;
const Main = styled.main`
  margin-top: 0px;
  position: relative;
  z-index: 10;
`;

const FloatingStart = styled.div``;
const FloatingEnd = styled.div``;

export default Landing;
