import { useTranslation } from "next-i18next";
import { useSnackbar } from "notistack";
import { useState } from "react";
import fetchApi from "@/modules/common/fetchApi";
import { isEmail } from "../utils";
import useTextField from "./useTextField.hook";

const useResetPassword = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleError: handleErrorEmail, ...emailProps } = useTextField(
    "",
    1000,
    [
      {
        checker: isEmail,
        errorText: t("textfield_email_wrong_format"),
      },
    ],
  );

  const handleSubmit = async () => {
    const data = {
      email: emailProps.value,
    };

    await fetchApi({
      url: `/api/forgot-password`,
      method: "POST",
      body: JSON.stringify(data),
      success: () => {
        alert(t("toast_reset_pw_email_sent"));
      },
      error: (res) => {
        switch (res.code) {
          case 1102:
            handleErrorEmail(res.error.errorMessage);
            break;
          case 2012:
            handleErrorEmail(res.error.errorMessage);
            break;
          case 2017:
            alert(res.error.errorMessage);
            break;
          default:
            enqueueSnackbar(res.error.errorMessage);
        }
      },
      setLoading,
    });
  };

  return {
    /** EmailField props */
    emailProps,
    /** 작업중인 task가 있는지 유무 */
    loading,
    /** 비밀번호 재설정 이메일을 전송 (성공 유무를 return) */
    submit: handleSubmit,
  };
};

export default useResetPassword;
