import { useTranslation } from "next-i18next";
import { useLocale } from "@/modules/provider/LocaleProvider";
import styled from "@emotion/styled";
import { ConvertText, TitleText } from "./LandingStyle";

const Section7 = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  return (
    <Section>
      <Wrapper>
        <TextWrapper>
          <TitleWrapper>
            <TitleText>
              <ConvertText text={t(`land_7_title`)} />
            </TitleText>
          </TitleWrapper>
        </TextWrapper>
        <LargeImage
          src={
            locale === "ko"
              ? "/img/new_landing/common/image_7_1.png"
              : "/img/new_landing/common/image_7_1_en.png"
          }
          alt=""
        />
        <SmallImage
          src={
            locale === "ko"
              ? "/img/new_landing/common/image_7_2.png"
              : "/img/new_landing/common/image_7_2_en.png"
          }
          alt=""
        />
      </Wrapper>
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  min-width: 360px;
  overflow: hidden;
  background-color: #f6fafe;
  padding: 5rem 20px;
  @media screen and (max-width: 1439px) {
    padding: 5rem 20px;
  }

  @media screen and (max-width: 1023px) {
    padding: 5rem 60px;
  }

  @media screen and (max-width: 600px) {
    padding: 5rem 20px;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
`;

const TextWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const LargeImage = styled.img`
  width: 840px;
  margin-right: 60px;
  @media screen and (max-width: 1023px) {
    max-width: 840px;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const SmallImage = styled.img`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    width: 100%;
  }
`;
const TitleWrapper = styled.div`
  color: #161616;
  margin-bottom: 12px;
  margin-top: 20px;
  @media screen and (max-width: 1023px) {
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export default Section7;
