import { Overline } from "@/modules/common/fonts";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { EVENT, send } from "@/lib/amplitude";
import { useLocale } from "@/modules/provider/LocaleProvider";

const Footer = () => {
  const locale = useLocale();
  const { t } = useTranslation();
  const about2dubURL = (() => {
    switch (locale) {
      case "ko":
        return "https://2meu.notion.site/75f4b3958b4d4ef38691741bd337971c";
      case "ja":
        return "https://2meu.notion.site/2DUB-a79b6d463570498a8e620c99df861a12";
      case "en":
      default:
        return "https://2meu.notion.site/New-to-2DUB-c701a15d017a4caa8605dac77f66fb82";
    }
  })();
  const privacyPolicyURL = (() => {
    switch (locale) {
      case "ko":
        return "https://2meu.notion.site/0841db02e2bf4885a1d69f70c99bd8f4";
      case "ja":
      case "en":
      default:
        return "https://2meu.notion.site/Privacy-Policy-d7a27af03f7944418104cdf56438670d";
    }
  })();
  const termsPolicyURL = (() => {
    switch (locale) {
      case "ko":
        return "https://2meu.notion.site/69d0f52fe5ac4928a7150404ec273d78";
      case "ja":
      case "en":
      default:
        return "https://2meu.notion.site/Terms-of-Service-2fe409324ee44e94ba17e4f41b98041f";
    }
  })();

  const kakaoURL = "https://pf.kakao.com/_EdITd/friend";

  const instagramURL = "https://instagram.com/2dub_kr";

  const youtubeURL = "https://www.youtube.com/channel/UCAtMY2kanK3oJoCfVRuQJlg";

  const handleClick = (url: string) => {
    window.open(url, "_blank");
  };

  const onClickSend = (url: string, event: string, data = {}) => {
    send(event, data);
    handleClick(url);
  };

  return (
    <FooterComponent>
      <Wrapper>
        <LeftSide>
          <LinkRow>
            <About2Dub>
              <LinkText
                onClick={() =>
                  onClickSend(about2dubURL, EVENT.CLICK__LANDING_ABOUT_BTN, {
                    is_mobile: false,
                  })
                }
              >
                <B2>{t("footer_about2dub")}</B2>
              </LinkText>
            </About2Dub>
            <LinkText
              onClick={() =>
                onClickSend(
                  privacyPolicyURL,
                  EVENT.CLICK__LANDING_PRIVACY_BTN,
                  {
                    is_mobile: false,
                  },
                )
              }
            >
              <B2>{t("footer_privacyPolicy")}</B2>
            </LinkText>
            <LinkText
              onClick={() =>
                onClickSend(termsPolicyURL, EVENT.CLICK__LANDING_TERMS_BTN, {
                  is_mobile: false,
                })
              }
            >
              <B2>{t("footer_termsPolicy")}</B2>
            </LinkText>
          </LinkRow>
          <CompanyInfo>{t("footer_all")}</CompanyInfo>
          <CopyRight>
            {t("footer_2meu", { year: `${new Date().getFullYear()}` })}
          </CopyRight>
        </LeftSide>
        <RightSide>
          <SocialLinkRow>
            <KakaoButton
              href={kakaoURL}
              onClick={() =>
                send(EVENT.CLICK__LANDING_CONTACT_BTN, {
                  channel: "kakao",
                })
              }
              target="_blank"
            >
              <Image
                alt="kakao"
                src="/img/new_landing/mobile/kakao_logo.png"
                width={16}
                height={16}
              />
              <Overline>{t("footer_kakaochat")}</Overline>
            </KakaoButton>
            <SocialButton
              href={instagramURL}
              onClick={() =>
                send(EVENT.CLICK__LANDING_SNS_BTN, {
                  is_mobile: false,
                  target_sns: "Instagram",
                })
              }
              target="_blank"
            >
              <Image
                alt="instagram"
                src="/img/new_landing/mobile/btn_instagram.png"
                width={32}
                height={32}
              />
            </SocialButton>
            <SocialButton
              href={youtubeURL}
              onClick={() =>
                send(EVENT.CLICK__LANDING_SNS_BTN, {
                  is_mobile: false,
                  target_sns: "Youtube",
                })
              }
              target="_blank"
            >
              <Image
                alt="youtube"
                src="/img/new_landing/mobile/btn_youtube.png"
                width={32}
                height={32}
              />
            </SocialButton>
          </SocialLinkRow>
        </RightSide>
      </Wrapper>
    </FooterComponent>
  );
};

const FooterComponent = styled.footer`
  width: 100%;
  background-color: var(--gray020);
`;

const Wrapper = styled.div`
  max-width: 1640px;
  height: 100%;
  margin: 0 auto;
  padding: 24px 60px 105px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1339px) {
    padding: 24px 60px 109px;
  }

  @media screen and (max-width: 1279px) {
    padding: 14px 60px 14px;
  }

  @media screen and (max-width: 777px) {
    padding: 24px 16px 80px 16px;
    flex-direction: column;
    align-items: start;
  }
`;

const LeftSide = styled.div``;

const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 27px;
  padding-bottom: 12px;
  @media screen and (max-width: 1279px) {
    display: none;
  }
  @media screen and (max-width: 777px) {
    display: flex;
  }
`;

const B2 = styled.div`
  font-size: 14px;
  line-height: 20px;

  @media screen and (max-width: 777px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const About2Dub = styled.div`
  @media screen and (max-width: 777px) {
    display: none;
  }
`;

const LinkText = styled.a`
  cursor: pointer;
  color: var(--gray080);
  font-weight: 600;
  @media screen and (max-width: 777px) {
    color: var(--gray070);
  }
`;

const CompanyInfo = styled.div`
  color: var(--gray070);
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 18px;
  @media screen and (max-width: 1279px) {
    width: 471px;
  }
  @media screen and (max-width: 777px) {
    width: 100%;
    font-size: 10px;
    line-height: 15px;
  }
`;

const CopyRight = styled.div`
  display: flex;
  color: var(--gray060);
  font-size: 12px;
  line-height: 18px;
  @media screen and (max-width: 777px) {
    font-size: 10px;
    line-height: 15px;
  }
`;

const RightSide = styled.div`
  /* margin-left: auto; */
  /* margin-bottom: auto; */
  display: none;
  @media screen and (max-width: 777px) {
    display: flex;
  }
`;

const SocialLinkRow = styled.div`
  display: flex;
  margin-top: 24px;
  /* flex-direction: row;
  justify-content: start;
  align-items: center; */
  gap: 8px;
`;

const KakaoButton = styled.a`
  /* display: flex; */
  display: none;
  align-items: center;
  border-radius: 8px;
  padding: 8px 13px 7px 14px;
  background-color: var(--Yellow);
  gap: 8px;
  font-weight: 600;
  white-space: nowrap;
`;

const SocialButton = styled.a``;

const Image = styled.img``;

export default Footer;
