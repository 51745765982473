import { EVENT, send } from "@/lib/amplitude";
import { useTranslation } from "next-i18next";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";

import { useRouter } from "next/router";
import { useLocale } from "@/modules/provider/LocaleProvider";
import ENV_PUBLIC from "@/scripts/client/ENV_PUBLIC";
import { ClientLogger } from "@/scripts/log/client";
import {
  initCheckRemember,
  isEmail,
  passwordLength,
  saveRemember,
} from "../utils";
import useTextField from "./useTextField.hook";

const KEY_REMEMBER_FLAG = "remember_2dub_flag";
const KEY_REMEMBER_EMAIL = "remember_2dub_email";

const useSignIn = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const locale = useLocale();

  useEffect(() => {
    send(EVENT.ENTER__SIGNIN);
  }, []);

  const { remember: initRemeber, value: initEmail } = useMemo(
    () => initCheckRemember(KEY_REMEMBER_FLAG, KEY_REMEMBER_EMAIL),
    [],
  );

  const emailProps = useTextField(initEmail, undefined, [
    {
      checker: isEmail,
      errorText: t("textfield_email_wrong_format"),
    },
  ]); // 이메일 필드

  const passwordProps = useTextField("", undefined, [
    {
      checker: passwordLength,
      errorText: t("textfield_more_than_6"),
    },
  ]); // 패스워드 필드

  const [loading, setLoading] = useState(false); // 로그인 버튼 로딩 상태
  const [remember, setRemember] = useState(initRemeber); // 로그인 정보 기억 상태

  const [isBlocked, setIsBlocked] = useState(false); // 정지된 계정
  const [blockMessage, setIsBlockMessage] = useState("");

  const handleRemember = () => {
    setRemember(!remember);
  };

  const disabledSubmit =
    emailProps.error ||
    passwordProps.error ||
    emailProps.value.length < 1 ||
    passwordProps.value.length < 1;

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      setLoading(true);
      send(EVENT.CLICK__SIGNIN_BY_EMAIL);

      const body = JSON.stringify({
        email: emailProps.value,
        password: passwordProps.value,
        service_platform: ENV_PUBLIC.NEXT_PUBLIC_SERVICE_PLATFORM,
        dl: locale,
      });

      const response = await fetch(`/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      const result = await response.json();

      const { code, error } = result;
      switch (code) {
        case 200:
          saveRemember(
            remember,
            KEY_REMEMBER_FLAG,
            emailProps.value,
            KEY_REMEMBER_EMAIL,
          );

          router.replace("/list");
          break;

        case 1102: // 올바른 이메일 형식이 아닌 경우
        case 1103: // 비밀번호가 6자 미만인경우
        case 1023: // 이메일을 파라미터로 요청하지 않은 경우
        case 1024: // 패스워드를 파라미터로 요청하지 않은 경우
        case 2012: // 해당 email로 조회되는 계정이 없을 경우
          emailProps.handleError(error.errorMessage);
          break;

        case 2013: // 해당 email이 소셜로 가입된 계정일 경우
          enqueueSnackbar(error.errorMessage);
          break;

        // case 2015: // 현재 로그인 되어 있는 계정일 경우
        //   setIsForce(true);
        //   break;

        case 2016: // 비밀번호가 틀렸을 경우
          passwordProps.handleError(error.errorMessage);
          break;

        case 2029: // 이용정지 중인 계정인 경우
          setIsBlocked(true);
          setIsBlockMessage(error.errorMessage);
          break;
        case 2030: // 탈퇴 보류중인 계정인 경우
          enqueueSnackbar(error.errorMessage);
          break;
        default:
          enqueueSnackbar(error.errorMessage);
          break;
      }
    } catch (error) {
      ClientLogger.error("handleSubmit - useSignIn", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    emailProps,
    passwordProps,
    disabledSubmit,
    loading,
    remember,
    isBlocked,
    blockMessage,
    handleRemember,
    handleSubmit,
  };
};

export default useSignIn;
