import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { useModal } from "mui-modal-provider";
import React, { useState, useEffect } from "react";
import videoThumbnailUrl from "@/modules/utils/videoThumbnailUrl";
import ENV_PUBLIC from "@/scripts/client/ENV_PUBLIC";
import { ClientLogger } from "@/scripts/log/client";
import VideoDialog from "./VideoDialog";
import {
  ConvertText,
  Section,
  TitleText,
  SubTitleText,
  Wrapper,
} from "./LandingStyle";

interface UserModel {
  idx: number;
  name: string;
  profile_url: string;
  membership: string;
  dub_user_flag: string;
  created_at: string;
}

interface DubbedModel {
  idx: number;
  file_url: string;
  video: {
    thumbnail_url: string;
    watch_flag: string;
    language: string;
    title: string;
    category_type: string;
  };
  users: Array<UserModel>;
}

const Section2 = () => {
  const { t } = useTranslation();
  const [dubbedList, setDubbedList] = useState<Array<DubbedModel> | null>();
  const { showModal } = useModal();

  useEffect(() => {
    fetchTrendDubbed();
  }, []);

  const fetchTrendDubbed = async () => {
    try {
      if (dubbedList) {
        return;
      }
      const params = new URLSearchParams({
        limit: "4",
        start: "0",
        flag: "S",
      });
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      const response = await fetch(
        `${ENV_PUBLIC.NEXT_PUBLIC_2DUB_URL}/api/video/dubbed/recent/trend?${params}`,
        requestOptions,
      );

      const json = await response.json();
      const { code, data } = json;
      switch (code) {
        case 200:
          setDubbedList(data);
          break;
        default:
          break;
      }
    } catch (error) {
      ClientLogger.error("fetchTrendDubbed - Section2", error);
    }
  };

  const getDayDiff = (dayStr: string) => {
    const nowDate = new Date();
    const targetDateStr = dayStr.substring(0, 10);
    const targetDateArr = targetDateStr.split("-");

    const targetYear = Number(targetDateArr[0]);
    const targetMonth = Number(targetDateArr[1]);
    const targetDay = Number(targetDateArr[2]);

    const nowYear = nowDate.getFullYear();
    const nowMonth = nowDate.getMonth() + 1;
    const nowDay = nowDate.getDate();

    const startDate = new Date(targetYear, targetMonth, targetDay);
    const endDate = new Date(nowYear, nowMonth, nowDay);

    const btMs = endDate.getTime() - startDate.getTime();
    const btDay = btMs / (1000 * 60 * 60 * 24);

    return `${btDay}`;
  };

  const onClickDubbed = (data: DubbedModel) => {
    const modal = showModal(VideoDialog, {
      data,
      onClose: () => {
        modal.destroy();
      },
    });
  };

  const getCategoryIcon = (category: string) => {
    let imgPath = "/img/new_landing/common/";
    switch (category) {
      case "M":
        imgPath += "movie.png";
        break;
      case "S":
        imgPath += "tvshow.png";
        break;
      case "A":
        imgPath += "commercial.png";
        break;
      case "O":
        imgPath += "other.png";
        break;
      default:
        imgPath += "other.png";
        break;
    }
    return imgPath;
  };

  return (
    <Section bgColor="var(--gray020)">
      <Wrapper>
        <TextWrapper>
          <SubTitleWrapper>
            <SubTitleText>
              <ConvertText text={t("land_2_title1")} />
            </SubTitleText>
          </SubTitleWrapper>
          <TitleText>
            <ConvertText text={t("land_2_title2")} />
          </TitleText>
        </TextWrapper>
        <VideoCardWrapper>
          {dubbedList
            ? dubbedList.map((val) => {
                const { video } = val;
                const user = val.users[0];
                const diffDay = getDayDiff(user.created_at);
                return (
                  <VideoContainer
                    key={val.idx}
                    onClick={() => {
                      onClickDubbed(val);
                    }}
                  >
                    <VideoCard src={videoThumbnailUrl(video)} />
                    <Container />
                    <PlayIcon
                      src="/img/new_landing/common/play_btn.png"
                      alt=""
                    />
                    <VideoLargeText>
                      <ConvertText text={user.name} />
                    </VideoLargeText>
                    <VideoSmallText>
                      <ConvertText
                        text={t("land_user2dub_days", {
                          count: Number(diffDay),
                        })}
                      />
                    </VideoSmallText>
                    <CategoryContainer />
                    <CategoryIcon
                      src={getCategoryIcon(video.category_type)}
                      alt=""
                    />
                  </VideoContainer>
                );
              })
            : null}
        </VideoCardWrapper>
      </Wrapper>
    </Section>
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  @media screen and (max-width: 599px) {
    margin-bottom: 40px;
  }
`;

const SubTitleWrapper = styled.div`
  margin-bottom: 10px;
  @media screen and (max-width: 777px) {
    margin-bottom: 5px;
  }
`;

const VideoCardWrapper = styled.div`
  display: grid;
  justify-content: center;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 1279px) {
    grid-template-columns: repeat(2, minmax(auto, 20px));
    grid-template-rows: repeat(2, 1fr);
  }
  @media screen and (max-width: 777px) {
    grid-template-columns: repeat(1, minmax(auto, 20px));
    grid-template-rows: repeat(4, 1fr);
  }
`;

const VideoContainer = styled.div`
  width: 295px;
  height: 180px;
  border-radius: 17.5px;
  position: relative;
  cursor: pointer;
`;

const VideoCard = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  background-color: #ff705e;
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: inherit;
`;

const PlayIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const VideoSmallText = styled.div`
  position: absolute;
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  left: 7%;
  top: 65%;
`;

const VideoLargeText = styled.div`
  position: absolute;
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  top: 78%;
  left: 7%;
`;

const CategoryContainer = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: var(--white);
  border-radius: 20px;
  top: 7%;
  left: 84%;
`;

const CategoryIcon = styled.img`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 7%;
  left: 84%;
  padding: 4px;
`;

export default Section2;
