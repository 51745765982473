import { useTranslation } from "next-i18next";
import Head from "next/head";
import React from "react";

interface Props {
  children: React.ReactNode;
  locale: string;
  title?: string;
  meta?: React.ReactNode;
}

const Layout = ({ children, title, meta, locale }: Props) => {
  const { t } = useTranslation();
  const image_locale = locale === "ko" ? "ko" : "en";

  return (
    <>
      <Head>
        <title>{title ?? t("browser_landing")}</title>
        <meta name="viewport" content="width=device-width,minimum-scale=1.0" />
        {meta || (
          <>
            <meta property="og:title" content={t("share_thumb_title")} />
            <meta property="og:url" content="https://2dub.me" />
            <meta
              property="og:image"
              content={`https://2dub.me/img/snsshare2dub_${image_locale}.png`}
            />
            <meta property="og:description" content={t("share_thumb_body")} />
            <meta property="og:type" content="website" />
          </>
        )}
      </Head>
      {children}
    </>
  );
};

export default Layout;
