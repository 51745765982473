import { useTranslation } from "next-i18next";
import styled from "@emotion/styled";
import { ConvertText, Section, TitleText, Wrapper } from "./LandingStyle";

const Section9 = () => {
  const { t } = useTranslation();

  return (
    <Section bgColor="#F6FAFE">
      <Wrapper>
        <TitleTextWrapper>
          <TitleText>
            <ConvertText text={t(`land_9_title`)} />
          </TitleText>
        </TitleTextWrapper>
        <ImageWrapper>
          <Image1 src="/img/new_landing/common/image_9_1.png" alt="" />
          <Image2 src="/img/new_landing/common/image_9_2.png" alt="" />
        </ImageWrapper>
        <Image3 src="/img/new_landing/common/image_9_3.png" alt="" />
        <CardWrapper>
          <Card>
            <Icon>🎧</Icon>
            <CardText>
              {t(`land_9_t1`)}
              <br />
              <span>80%</span>
              {t(`land_9_value_increased`)}
            </CardText>
            <Divider />
            <DesText>{t(`land_9_t1_body`)}</DesText>
          </Card>
          <Card>
            <Icon>💬</Icon>
            <CardText>
              {t(`land_9_t2`)}
              <br />
              <span>20%</span>
              {t(`land_9_value_increased`)}
            </CardText>
            <Divider />
            <DesText>{t(`land_9_t2_body`)}</DesText>
          </Card>
          <Card>
            <Icon>📝</Icon>
            <CardText>
              {t(`land_9_t3`)}
              <br />
              <span>20%</span>
              {t(`land_9_value_increased`)}
            </CardText>
            <Divider />
            <DesText>{t(`land_9_t3_body`)}</DesText>
          </Card>
        </CardWrapper>
      </Wrapper>
    </Section>
  );
};

const TitleTextWrapper = styled.div`
  color: var(--gray100);
  margin-bottom: 60px;
  text-align: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Image1 = styled.img`
  height: 450px;
  @media screen and (max-width: 1023px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 599px) {
    margin-top: auto;
    height: 297px;
  }
  @media screen and (max-width: 499px) {
    margin-top: auto;
  }
`;

const Image2 = styled.img`
  margin-top: 3rem;
  height: 450px;
  @media screen and (max-width: 599px) {
    margin-top: 0rem;
    height: 297px;
  }
`;

const Image3 = styled.img`
  width: 100%;
  max-width: 651px;
  display: block;
  margin: 0 auto;
  margin-bottom: 120px;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  column-gap: 60px;
  margin-bottom: 10rem;
  @media screen and (max-width: 1439px) {
    flex-direction: column;
    column-gap: 0;
    row-gap: 60px;
  }
  @media screen and (max-width: 599px) {
    margin-bottom: 0rem;
  }
`;

const Card = styled.div`
  margin: 0 auto;
  background-color: white;
  border-radius: 30px;
  padding: 40px 42px 48px;
  width: 374px;
  height: 445px;
  @media screen and (max-width: 599px) {
    width: 320px;
    height: 381px;
    padding: 35px 35px 40px;
  }
`;

const Icon = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 9999px;
  font-size: 34.41px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3.64878px 32px rgba(39, 64, 197, 0.12);
  margin-bottom: 24px;
`;

const CardText = styled.div`
  font-size: var(--fs-h2);
  line-height: var(--lh-h2);
  font-weight: 600;
  color: var(--gray100);
  margin-bottom: 32px;
  span {
    font-size: 65px;
    line-height: 96px;
    font-weight: 700;
    color: var(--violet050);
    margin-right: 4px;
  }
  @media screen and (max-width: 599px) {
    font-size: var(--fs-h3);
    line-height: var(--lh-h3);
    span {
      font-size: 50px;
      line-height: 79px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray040);
  margin-bottom: 16px;
`;

const DesText = styled.div`
  font-size: var(--fs-b1);
  line-height: var(--lh-b1);
  color: var(--gray080);
  @media screen and (max-width: 599px) {
    font-size: var(--fs-b2);
    line-height: var(--lh-b2);
  }
`;

export default Section9;
