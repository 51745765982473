import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

export const BUTTON_STATUS = {
  ENABLED: "enabled",
  HOVER: "hover",
  PRESSED: "pressed",
  DISABLED: "disabled",
  LOADING: "loading",
};

const VARIANT = {
  fill: "contained",
  contained: "contained",
  line: "outlined",
  outlined: "outlined",
  text: "text",
};

interface Props extends LoadingButtonProps {
  round?: boolean;
}
/*
  type = fill, line, text
  (
    1. type 은 button 기본 프로퍼티가 있어서 정의할수가 없어서 variant 로 정의합니다.
    2. fill, line 은 기존 mui 에 없지만 변환해서 사용합니다.
      (fill -> contained) (line -> outlined)
    3. 그밖에 필요한게 있으면 muiTheme.ts 파일의 ButtonPropsVariantOverrides 인터페이스를 수정해주세요.
  )

  size = extraSmall, small, regular, large
  (
    1. size 는 extraSmall, regular 가 추가되었습니다.
    2. 스타일 정의는 muiTheme.ts 파일의 customTheme -> components -> MuiButton -> variants 에 정의되어있습니다.
  )

  fullLarge... 이외에 너비값을 부모 최대크기로 만들때
  (
    1. fullWidth 프로퍼티를 true 로 할당해주세요.
  )

  color = magenta, gray, violet, lightViolet, blue
  (
    1. 컬러 정의는 muiTheme.ts 파일의 customTheme -> palette 에 정의 됩니다.


    disabled 상태 컬러 정의
    (
      1. muiTheme.ts 파일의 customTheme -> components -> MuiButton -> styleOverrides -> root -> &.Mui-disabled
    )
  )

  status 안쓰고 disabled, loading 개별적으로 사용하기
  status = enabled(hover, pressed), disabled, loading
  (
    1. enabled : 기본 상태
      - hover, pressed: enabled 상태에서 css로 컨트롤
    2. disabled : 선택불가 상태
    3. loading : 응답을 기다리고 있을때 상태
  )
*/

const DubButton = (props: Props) => {
  const {
    variant: variant_props,
    children,
    sx: sx_props,
    round,
    ...rest
  } = props;

  const variant = (
    variant_props ? VARIANT[variant_props] : "contained"
  ) as LoadingButtonProps["variant"];
  const sx = { ...sx_props, ...(round && { borderRadius: "20px" }) };

  return (
    <LoadingButton
      variant={variant}
      disableFocusRipple
      disableElevation
      sx={sx}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};

DubButton.defaultProps = {
  round: undefined,
};

export default DubButton;
