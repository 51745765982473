import { PlyrInstance, PlyrSource } from "plyr-react";
import { VideoModel } from "./player.types";

export const getIsYoutube = ({ watch_flag }: { watch_flag: string }) => {
  // 유튜브 시청 관련 플래그 [N: 재생불가, P: 재생가능, S: 특정국가 시청 제한]
  return !["A", "X", "N"].includes(watch_flag);
};

export const videoSrc = ({ watch_flag, link_url, baseurl }: VideoModel) => {
  if (getIsYoutube({ watch_flag })) {
    return link_url;
  }
  return `${baseurl}.mp4`;
};

export const getDuration = ({
  play_time,
  end_point,
  start_point,
}: VideoModel) => {
  if (
    end_point === 0 ||
    end_point === null ||
    end_point === undefined ||
    start_point === undefined
  ) {
    const [min, sec] = play_time.split(":");
    return parseInt(min, 10) * 60 + parseInt(sec, 10);
  }

  return (end_point - start_point) / 1000;
};

export const isMobile = {
  Android() {
    return typeof window === "undefined"
      ? false
      : navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
    return typeof window === "undefined"
      ? false
      : navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
    return typeof window === "undefined"
      ? false
      : navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera() {
    return typeof window === "undefined"
      ? false
      : navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return typeof window === "undefined"
      ? false
      : navigator.userAgent.match(/IEMobile/i) ||
          navigator.userAgent.match(/WPDesktop/i);
  },
  any() {
    return !!(
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export const fixedTime = (
  playerState: "idel" | "record" | "video" | "voice",
) => {
  if (isMobile.Android()) {
    return 0.2;
  }
  if (isMobile.iOS()) {
    if (playerState === "voice") {
      return 0;
    }
    if (playerState === "record") {
      return -0.2;
    }
    return 0.2;
  }

  return 0;
};

export const usePlayerUtil = (video: VideoModel) => {
  const isYoutube = getIsYoutube(video);
  const duration = getDuration(video);

  const adaptedStartPosition = isYoutube ? video.start_point / 1000 : 0;
  const adaptedEndPosition = isYoutube
    ? video.end_point
      ? video.end_point / 1000
      : duration
    : duration;

  const convertAdaptedTime = (position: number) => {
    return position + adaptedStartPosition;
  };

  const convertOriginTime = (position: number) => {
    return position - adaptedStartPosition;
  };

  const isTimeBetweenStartAndEnd = (
    time: number,
    start: number,
    end: number,
  ) => {
    const number = 0.1; // 짧은 문장일때 문장이 재생한후 끝지점이 앞부분으로 이동되는 버그 수정요
    const originTime = convertOriginTime(time);
    return originTime < start || end - number < originTime;
  };

  const isScriptFinished = (time: number, end: number) => {
    const originTime = convertOriginTime(time);
    return originTime >= end;
  };

  const syncAudioByVideoTime = (
    videoParam: PlyrInstance,
    audioParam: HTMLAudioElement,
    gap: number,
    startPos: number,
  ) => {
    const originTime = convertOriginTime(videoParam.currentTime - startPos);
    const audio = audioParam;
    const audioTime = audio.currentTime;
    if (Math.abs(originTime - audioTime) > gap) {
      audio.currentTime = originTime;
    }
  };

  return {
    duration,
    isMobile: isMobile.any(),
    convertAdaptedTime,
    convertOriginTime,
    isTimeBetweenStartAndEnd,
    isScriptFinished,
    syncAudioByVideoTime,
    startPosition: adaptedStartPosition,
    endPosition: adaptedEndPosition,
  };
};

export const getPlyrParams = ({
  watch_flag,
  baseurl,
  link_url,
  title,
  thumbnail_url,
}: VideoModel) => {
  const isYoutube = getIsYoutube({ watch_flag });
  const sources = isYoutube
    ? [
        {
          src: link_url,
          provider: "youtube",
        },
      ]
    : [
        {
          src: `${baseurl}/video/HD.mp4`,
          type: "video/mp4",
        },
        {
          src: `${baseurl}.mp4`,
          type: "video/mp4",
        },
      ];
  return {
    source: {
      type: "video",
      title,
      sources,
      poster: isYoutube ? thumbnail_url : `${baseurl}.jpg`,
    } as PlyrSource,
    options: {
      controls: [],
      ratio: "16:9",
      // debug: true,
      clickToPlay: false,
      speed: {
        selected: 1,
        options: [0.25, 0.5, 0.75, 1],
      },
    },
  };
};
