type FetchProps = {
  url: string;
  method: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
  body?: any;
  success?: (res: any) => void;
  error?: (res: any) => void;
  setLoading?: (bool: boolean) => void;
};

const fetchApi = async ({
  url,
  method,
  body,
  success,
  error,
  setLoading,
}: FetchProps) => {
  try {
    if (setLoading) {
      setLoading(true);
    }

    const response = await fetch(url, {
      method,
      ...(method !== "GET" && { body }),
    });
    const resData = await response.json();
    switch (resData.code) {
      case 200:
        if (success) {
          success(resData);
        }
        return resData;
      default:
        if (error) {
          error(resData);
        }
        return resData;
    }
  } catch (e) {
    console.error(e);
    if (error) {
      error({ code: 500, error: { errorMessage: e } });
    }
    return { code: 500, error: { errorMessage: e } };
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};

export default fetchApi;
