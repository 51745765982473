import { EVENT, send } from "@/lib/amplitude";
import DubButton from "@/modules/common/button/DubButton";
import styled from "@emotion/styled";
import React from "react";
import useAppleSign from "../hooks/useAppleSign.hook";

type AppleProps = {
  locale: string;
  children: React.ReactNode;
  type: string;
};

const Apple = ({ locale, children, type }: AppleProps) => {
  const { handleClick } = useAppleSign(locale);

  const handleSignApple = () => {
    if (type === "signin") {
      send(EVENT.CLICK__SIGNIN_BY_APPLE);
    } else {
      send(EVENT.CLICK__SIGNUP_BY_APPLE);
    }
    handleClick();
  };

  return (
    <DubButton
      variant="line"
      color="gray"
      size="large"
      fullWidth
      sx={{ color: "var(--black)", border: "1px solid var(--gray050)" }}
      onClick={handleSignApple}
    >
      <AppleLogo src="/img/common/apple-logo.png" />
      {children}
    </DubButton>
  );
};

const AppleLogo = styled.img`
  width: 14px;
  margin-right: 8px;
`;
export default Apple;
