import { ChangeEvent, useEffect, useState } from "react";

type ValidCheckType = {
  checker: (data: string) => boolean | undefined;
  errorText: string;
};
const useTextField = (
  defaultValue: string,
  maxLength?: number,
  validCheckers?: Array<ValidCheckType>,
) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    if (value.length > 0) {
      if (validCheckers && validCheckers.length > 0) {
        let errorText = "";
        for (let i = 0; i < validCheckers.length; i += 1) {
          if (!validCheckers[i].checker(value)) {
            errorText = validCheckers[i].errorText;
            break;
          }
        }

        if (errorText) {
          setError(true);
          setHelperText(errorText);
          return;
        }
      }
    }

    setError(false);
    setHelperText("");
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;

    if (/\s/g.test(inputValue)) return;

    if (maxLength && inputValue.length > maxLength) {
      return;
    }

    setValue(inputValue);
  };

  const handleError = (text: string) => {
    setError(true);
    setHelperText(text);
  };

  const handleReset = () => {
    setValue(defaultValue);
  };

  const handleValue = (text: string) => {
    setValue(text);
  };

  return {
    value,
    error,
    helperText,
    onChange: handleChange,
    setValue: handleValue,
    handleError,
    handleReset,
  };
};

export default useTextField;
