import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { ConvertText } from "./LandingStyle";

const FloatingBar = ({ className }: { className: string }) => {
  const { t } = useTranslation();
  const experienceURL = "https://solution.2dub.me/dubbing/tqd6ffsgj2?from=2dub";
  return (
    <Section>
      <FloatingContainer className={className}>
        <FloatingWrapper>
          <TextRow>
            <FloatingText>
              <ConvertText text={t("land_float_title")} />
            </FloatingText>
            <PinBox>
              <PinBoxText>
                <ConvertText text={t("land_float_pin")} />
              </PinBoxText>
            </PinBox>
          </TextRow>
          <ButtonRow>
            <Container>
              <PinBoxText>
                <ConvertText text={t("land_float_pin")} />
              </PinBoxText>
            </Container>
            <FloatingButton href={experienceURL}>
              <FloatingButtonText>
                <ConvertText text={t("button_exp_dub")} />
              </FloatingButtonText>
            </FloatingButton>
          </ButtonRow>
        </FloatingWrapper>
      </FloatingContainer>
    </Section>
  );
};

const Section = styled.div<{ bgColor?: string }>`
  width: 100%;
  min-width: 360px;
  position: relative;
  ${({ bgColor }) => bgColor && `background: ${bgColor};`}
  overflow: hidden;
`;

const FloatingContainer = styled.div`
  width: 100%;
  background-color: var(--primary050);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  cursor: pointer;

  // animation
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FloatingWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1240px;
  padding: 28px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1439px) {
    padding: 28px 60px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px 60px;
    flex-direction: column;
    justify-content: start;
  }

  @media screen and (max-width: 499px) {
    padding: 20px 40px;
  }
`;

const TextRow = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 499px) {
    flex-direction: column;
    justify-content: centerc;
  }
`;

const FloatingText = styled.div`
  color: var(--white);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  @media screen and (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 777px) {
    font-weight: 600;
  }
  @media screen and (max-width: 599px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 499px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const PinBox = styled.div`
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.16);
  margin-left: 16px;
  border-radius: 12px;
  @media screen and (max-width: 1023px) {
    padding: 4px 12px;
  }
  @media screen and (max-width: 599px) {
    padding: 4px 12px;
  }
  @media screen and (max-width: 499px) {
    display: none;
    margin-top: 8px;
    margin-left: 0px;
  }
`;

const PinBoxText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: var(--white);
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media screen and (max-width: 499px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const ButtonRow = styled.div`
  display: flex;

  @media screen and (max-width: 1023px) {
    justify-content: center;
    width: 100%;
  }
  @media screen and (max-width: 499px) {
    justify-content: space-between;
    width: 100%;
    gap: 5%;
  }
`;

const Container = styled.div`
  display: none;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 12px;
  @media screen and (max-width: 499px) {
    display: initial;
    padding: 8px 0px;
    width: 30%;
    margin-top: 16px;
    text-align: center;
  }
`;

const FloatingButton = styled.a`
  padding: 12px 20px;
  border-radius: 100px;
  background-color: var(--white);
  cursor: pointer;
  @media screen and (max-width: 1023px) {
    margin-top: 16px;
  }
  @media screen and (max-width: 499px) {
    padding: 8px 16px;
    width: 65%;
    margin-top: 16px;
    text-align: center;
  }
`;

const FloatingButtonText = styled.div`
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: var(--primary050);
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (max-width: 499px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export default FloatingBar;
