import { useLocale } from "@/modules/provider/LocaleProvider";
import { useModal } from "mui-modal-provider";
import ClassSignIn from "../ClassSignIn";
import ClassSignUp from "../ClassSignUp";
import ResetPassword from "../ResetPassword";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import { AuthDialogType, AuthInfo } from "../types/useAuth.types";
import WelcomeDialog from "../WelcomeDialog";
import ExtraInfo from "../ExtraInfo";

const useAuth = () => {
  const locale = useLocale();
  const { showModal } = useModal();

  const renderModal = (target: any, auth_info?: AuthInfo) => {
    const modal = showModal(target, {
      handleClose: () => {
        modal.hide();
      },
      handleSwitch: (type: AuthDialogType, authInfo?: AuthInfo) => {
        modal.hide();
        showDialog(type, authInfo);
      },
      locale,
      auth_info,
    });
  };

  const showSignUp = () => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/solution" &&
      window.location.pathname !== "/oauth/extra-info"
    ) {
      window.location.assign("/");
      return;
    }

    renderModal(SignUp);
  };

  const showWelcome = () => {
    renderModal(WelcomeDialog);
  };

  const showSignIn = () => {
    // TODO /ko, /en, /ja 등의 언어 설정이 되어있는 경우 회피
    // if (
    //   window.location.pathname !== "/" &&
    //   window.location.pathname !== "/solution" &&
    //   window.location.pathname !== "/oauth/extra-info"
    // ) {
    //   window.location.assign("/");
    //   return;
    // }

    renderModal(SignIn);
  };

  const showClassSignIn = () => {
    renderModal(ClassSignIn);
  };

  const showClassSignUp = () => {
    renderModal(ClassSignUp);
  };

  const showResetPasswordDialog = () => {
    renderModal(ResetPassword);
  };

  const showExtraInfo = (auth_info: AuthInfo) => {
    renderModal(ExtraInfo, auth_info);
  };

  const showDialog = (type: AuthDialogType, auth_info?: AuthInfo) => {
    switch (type) {
      case AuthDialogType.SignInDialog:
        showSignIn();
        break;
      case AuthDialogType.SignUpDialog:
        showSignUp();
        break;
      case AuthDialogType.ExtraInfo:
        showExtraInfo(auth_info);
        break;
      case AuthDialogType.WelcomeDialog:
        showWelcome();
        break;
      case AuthDialogType.ClassSignInDialog:
        showClassSignIn();
        break;
      case AuthDialogType.ClassSignUpDialog:
        showClassSignUp();
        break;

      case AuthDialogType.ResetPasswordDialog:
        showResetPasswordDialog();
        break;
      default:
    }
  };

  return {
    showSignUp,
    showSignIn,
    showExtraInfo,
  };
};

export default useAuth;
