import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { appStore, playStore } from "@/modules/utils/redirect";
import { useLocale } from "@/modules/provider/LocaleProvider";
import { ConvertText, Section } from "./LandingStyle";

const Section1 = () => {
  const { t } = useTranslation();
  const locale = useLocale();

  const goToPlayStore = () => {
    playStore(locale);
  };
  const goToAppStore = () => {
    appStore(locale);
  };

  return (
    <Section>
      <ContentWrapper>
        <Image
          src="/img/new_landing/common/landing_phone_1.png"
          alt="landing_phone_1"
        />
        <RightWrapper>
          <TitleWrapper>
            <ConvertText
              text={t("land_1_title")}
              target={t("land_1_title_txt")}
              targetColor="var(--primary050)"
              targetBold
            />
          </TitleWrapper>
          <ButtonWrapper>
            <PlayStoreButton onClick={goToPlayStore} />
            <AppStoreButton onClick={goToAppStore} />
          </ButtonWrapper>
        </RightWrapper>
      </ContentWrapper>
    </Section>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1127;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: right;

  @media screen and (max-width: 1279px) {
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 777px) {
    padding: 0px 20px;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 100px;
  @media screen and (max-width: 1279px) {
    margin: 0;
    width: 100%;
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  @media screen and (max-width: 777px) {
  }
`;

const TitleWrapper = styled.div`
  font-size: 50px;
  font-weight: 700;
  line-height: normal;
  color: var(--gray090);
  text-align: left;
  margin-bottom: 40px;
  @media screen and (max-width: 1279px) {
    font-size: 40px;
    text-align: center;
    margin-bottom: 24px;
    padding: 0;
  }

  @media screen and (max-width: 777px) {
    margin-top: 104px;
  }

  @media screen and (max-width: 499px) {
    font-size: 24px;
    line-height: normal;
    margin-top: 104px;
    /* margin-bottom: 16px; */
  }
`;

const Image = styled.img`
  width: 560px;
  min-width: 400px;
  @media screen and (max-width: 1279px) {
    width: 500px;
    margin-top: 60px;
  }

  @media screen and (max-width: 499px) {
    width: 100%;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 20px;
  }
`;

const PlayStoreButton = styled.button`
  width: 180px;
  height: 60px;
  display: inline-block;
  margin-right: 16px;
  background: url("/img/new_landing/common/play_store_btn.png");
  background-position: center;
  background-size: contain;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    width: 140px;
    height: 47px;
    background-size: contain;
  }
  @media screen and (max-width: 499px) {
    width: 99px;
    height: 33px;
    margin-right: 8px;
  }
`;

const AppStoreButton = styled.button`
  width: 180px;
  height: 60px;
  display: inline-block;
  background: url("/img/new_landing/common/app_store_btn.png");
  background-position: center;
  background-size: contain;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    width: 140px;
    height: 47px;
    background-size: contain;
  }
  @media screen and (max-width: 499px) {
    width: 99px;
    height: 33px;
    background-size: contain;
  }
`;

export default Section1;
