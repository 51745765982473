import React, { useContext, useMemo } from "react";
import "plyr-react/plyr.css";
import styled from "@emotion/styled";
import { VideoContext } from "./player.provider";
import { PlayerProps } from "./player.types";

const VideoPlayer = () => {
  const playerProps = useContext<PlayerProps>(VideoContext);
  const plyrVideo = useMemo(() => {
    return (
      <Video
        ref={playerProps.ref}
        className="plyr-react plyr"
        crossOrigin="anonymous"
      />
    );
  }, [playerProps]);

  return plyrVideo;
};

const Video = styled.video``;

export default VideoPlayer;
