import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { type DialogProps } from "@mui/material/Dialog";
import { useTranslation } from "next-i18next";
import { EVENT, send } from "@/lib/amplitude";
import { B2 } from "../common/fonts";
import Google from "./component/Google";
import useSignUp from "./hooks/useSignUp.hook";
import { AuthProps, AuthDialogType } from "./types/useAuth.types";
import DubDialog from "../common/dialog/DubDialog";
import DubButton from "../common/button/DubButton";
import { DubPassword, EmailAutoComplete } from "../common/textfield";
import Apple from "./component/Apple";
import useKakao from "./hooks/useKakao.hook";

type Props = DialogProps & AuthProps;

const SignUp = ({ open, handleClose, handleSwitch, locale }: Props) => {
  const {
    emailProps,
    passwordProps,
    codeVerified,
    disabledSubmit,
    sendButtonText,
    onSubmit,
  } = useSignUp(handleSwitch, locale);

  const onClose = () => {
    handleClose();
  };

  const onSwitch = (type: AuthDialogType) => {
    handleSwitch(type);
  };

  const { t } = useTranslation();

  const { handleAuthorize } = useKakao();

  const handleSignupKakao = () => {
    send(EVENT.CLICK__SIGNUP_BY_KAKAO);
    handleAuthorize();
  };

  return (
    <DubDialog
      open={open}
      onClose={onClose}
      size="medium"
      header={t("register_title")}
      center
      body={
        <DialogBody>
          <form onSubmit={onSubmit} autoComplete="off">
            <EmailRow>
              <EmailAutoComplete
                value={emailProps.value}
                error={emailProps.error}
                helperText={emailProps.helperText}
                onChange={emailProps.onChange}
                fullWidth
                label={t("textfield_email")}
                disabled={codeVerified}
                confirm={codeVerified}
                onClickItem={(item: string) => emailProps.setValue(item)}
              />
            </EmailRow>
            {codeVerified && (
              <Box style={{ paddingBottom: 16 }}>
                <DubPassword
                  value={passwordProps.value}
                  error={passwordProps.error}
                  helperText={passwordProps.helperText}
                  onChange={passwordProps.onChange}
                  fullWidth
                  label={t("textfield_pw")}
                />
              </Box>
            )}

            <Box>
              <DubButton
                type="submit"
                variant="contained"
                color="magenta"
                size="large"
                fullWidth
                disableElevation
                style={{
                  minWidth: 143,
                }}
                disabled={disabledSubmit}
              >
                {sendButtonText}
              </DubButton>
            </Box>
          </form>

          <Terms
            dangerouslySetInnerHTML={{
              __html: t("register_term_policy"),
            }}
          />

          <Box style={{ paddingBottom: 12 }}>
            <Google type="signup">{t("button_signup_google")}</Google>
          </Box>
          <Box style={{ paddingBottom: 12 }}>
            <Apple type="signup">{t("button_signup_apple")}</Apple>
          </Box>

          {locale === "ko" && (
            <>
              <AuthDivider>
                <B2>{t("register_or")}</B2>
              </AuthDivider>
              <Box style={{ paddingBottom: 20 }}>
                <FieldBox>
                  <Kakao onClick={handleSignupKakao} />
                </FieldBox>
              </Box>
            </>
          )}

          <OtherOptions>
            <DubButton
              variant="text"
              color="gray"
              size="small"
              disableElevation
              onClick={() => onSwitch(AuthDialogType.SignInDialog)}
              style={{
                padding: 8,
                minWidth: 0,
                margin: "0 auto",
              }}
            >
              {t("button_login")}
            </DubButton>
          </OtherOptions>
        </DialogBody>
      }
    />
  );
};

const DialogBody = styled.div`
  padding: 24px 32px 32px;
`;

const EmailRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-bottom: 16px;
`;

const AuthDivider = styled.div`
  margin: 16px 0;
  position: relative;
  width: 100%;
  height: 20px;
  ::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 100%;
    height: 1px;
    background-color: var(--gray030);
  }

  ${B2} {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--white);
    padding: 0 8px;
    color: var(--gray060);
  }
`;

const Kakao = styled.div`
  width: 55px;
  height: 55px;
  background: url("/img/auth/social-kakao.png");
  background-size: contain;
  cursor: pointer;
`;

const OtherOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Terms = styled(B2)`
  color: var(--gray080);
  text-align: center;
  margin: 20px 0 60px;
`;

const FieldBox = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
`;
export default SignUp;
