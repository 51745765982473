import styled from "@emotion/styled";
import { type DialogProps } from "@mui/material/Dialog";
import { useTranslation } from "next-i18next";
import { DubButton } from "../common/button";
import { DubDialog } from "../common/dialog";
import { B2, H3 } from "../common/fonts";
import { DubTextField } from "../common/textfield";
import useInviteCode from "./hooks/useInviteCode.hook";
import { AuthInfo } from "./types/useAuth.types";

type Props = DialogProps & {
  loading: boolean;
  handleInviteCode: (code: string) => void;
  auth_info: AuthInfo;
  locale: string;
};
const InviteCode = ({
  open,
  loading,
  handleInviteCode,
  auth_info,
  locale,
}: Props) => {
  const { t } = useTranslation();

  const {
    codeProps,
    handleVerifyCode,
    verified,
    verifying,
    handleClickNext,
    buttonCodeDisabled,
  } = useInviteCode(handleInviteCode, auth_info, locale);

  return (
    <DubDialog
      open={open}
      fullWidth
      size="medium"
      center
      transitionDuration={0}
      header={t("register_title")}
      body={
        <DialogBody>
          <Title>{t("invite_received_code_title")}</Title>
          <Description
            dangerouslySetInnerHTML={{ __html: t("invite_received_code_body") }}
          />
          <Wrapper>
            <DubTextField
              placeholder={t("invite_code_hint_write")}
              value={codeProps.value.toUpperCase()}
              error={codeProps.error}
              helperText={codeProps.helperText}
              onChange={codeProps.onChange}
              confirm={verified}
              disabled={verified}
              sx={{ flex: 1, paddingRight: "12px" }}
            />
            <DubButton
              variant="contained"
              color="lightCoral"
              size="large"
              sx={{ minWidth: "76px", maxHeight: "56px" }}
              disabled={buttonCodeDisabled}
              loading={verifying}
              onClick={handleVerifyCode}
            >
              {t("button_check")}
            </DubButton>
          </Wrapper>
        </DialogBody>
      }
      footer={
        <DubButton
          variant="contained"
          color="magenta"
          size="regular"
          loading={loading}
          onClick={handleClickNext}
        >
          {t("button_join_2dub")}
        </DubButton>
      }
    />
  );
};

const DialogBody = styled.div`
  padding: 24px 32px 36px;
`;

const Title = styled(H3)`
  font-weight: 600;
  text-align: center;
  padding-bottom: 8px;
`;

const Description = styled(B2)`
  color: var(--gray090);
  text-align: center;
  & span {
    color: #1873ff;
  }
  padding-bottom: 24px;
`;

const Wrapper = styled.div`
  display: flex;
`;

export default InviteCode;
