import Logger, { LOG_LEVEL } from "./log";

export class ClientLogger extends Logger {
  private static async clientCallback(level: LOG_LEVEL, message: string) {
    fetch("/api/kafka", {
      method: "POST",
      body: JSON.stringify({ level, message }),
    })
      .then((res) => res.json())
      .catch((error) => console.log(error));
  }

  static fatal(message: string, ...optionalParams: any[]) {
    this._log({
      level: LOG_LEVEL.FATAL,
      message,
      ...optionalParams,
      callback: this.clientCallback,
    });
  }

  static error(message: string, ...optionalParams: any[]) {
    this._log({
      level: LOG_LEVEL.ERROR,
      message,
      ...optionalParams,
      callback: this.clientCallback,
    });
  }

  static warn(message: string, ...optionalParams: any[]) {
    this._log({
      level: LOG_LEVEL.WARN,
      message,
      ...optionalParams,
      callback: this.clientCallback,
    });
  }

  static info(message: string, ...optionalParams: any[]) {
    this._log({
      level: LOG_LEVEL.INFO,
      message,
      ...optionalParams,
      callback: this.clientCallback,
    });
  }

  static debug(message: string, ...optionalParams: any[]) {
    this._log({
      level: LOG_LEVEL.DEBUG,
      message,
      ...optionalParams,
      callback: this.clientCallback,
    });
  }

  static verbose(message: string, ...optionalParams: any[]) {
    this._log({
      level: LOG_LEVEL.VERBOSE,
      message,
      ...optionalParams,
      callback: this.clientCallback,
    });
  }
}
