import { EVENT, send } from "@/lib/amplitude";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { ACCOUNT_TYPE, AuthInfo } from "../types/useAuth.types";

const useSelectPath = (
  handleStep: (type: number) => void,
  handleSelectPathsProps: (data: any) => void,
  auth_info: AuthInfo,
) => {
  const { t } = useTranslation();
  const [showTextField, setShowTextField] = useState(false);
  const [text, setText] = useState("");
  const [pathList, setPathList] = useState<
    Array<{ type: string; label: string }>
  >([]);
  const path = [
    { type: "friends", label: t("common_friends") },
    { type: "parents", label: t("common_parents") },
    { type: "academy", label: t("common_academy") },
    { type: "school", label: t("common_school") },
    { type: "instagram", label: t("common_instagram") },
    { type: "fb", label: t("common_fb") },
    { type: "blog", label: t("common_blog") },
    { type: "search", label: t("common_search") },
    { type: "youtube", label: t("common_youtube") },
    { type: "event", label: t("common_event") },
    { type: "others", label: t("common_other") },
    { type: "appstore", label: t("common_appstore") },
    { type: "playstore", label: t("common_playstore") },
  ] as { type: string; label: string }[];

  const handleClickChecked = (item: { type: string; label: string }) => {
    if (item.type === "others") {
      setShowTextField(!showTextField);
    }
    const isChecked = !!pathList.find((el) => el.type === item.type);

    if (isChecked) {
      setPathList(pathList.filter((el) => el.type !== item.type));
    } else {
      setPathList((curr) => [...curr, item]);
    }
  };

  const handleChangeTextField = (e) => {
    setText(e.target.value);
  };

  const handleClickSkip = () => {
    send(EVENT.CLICK__SKIP_HEAR_ABOUT_US, {
      account_type: ACCOUNT_TYPE[auth_info.type],
    });
    handleStep(2);
  };

  const handleClickNext = () => {
    send(EVENT.CLICK__CONFIRM_HEAR_ABOUT_US, {
      account_type: ACCOUNT_TYPE[auth_info.type],
    });
    const platform_channel = pathList.map((el) =>
      el.type === "others" ? `${el.label}-${text}` : el.label,
    );
    const visit_route = pathList.map((el) => el.type);
    const visit_route_user_text = text;

    handleSelectPathsProps({
      platform_channel,
      visit_route,
      visit_route_user_text,
    });
    handleStep(2);
  };

  return {
    path,
    pathList,
    showTextField,
    handleChangeTextField,
    handleClickChecked,
    handleClickSkip,
    handleClickNext,
  };
};

export default useSelectPath;
