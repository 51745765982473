import styled from "@emotion/styled";

export const H1 = styled.div`
  font-size: 48px;
  line-height: 71px;
`;

export const H2 = styled.div`
  font-size: 34px;
  line-height: 50px;
`;

export const H3 = styled.div`
  font-size: 24px;
  line-height: 36px;
`;

export const H4 = styled.div`
  font-size: 20px;
  line-height: 29px;
`;

export const H5 = styled.div`
  font-size: 18px;
  line-height: 27px;
`;

export const Title = styled.div`
  font-size: 28px;
  line-height: 41px;
`;

export const B1 = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const B2 = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const Caption = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

export const Overline = styled.div`
  font-size: 10px;
  line-height: 15px;
`;
