import { useEffect, useState } from "react";
import { ClientLogger } from "@/scripts/log/client";
import useScript from "./useScript";

const SCRIPT_PATH = {
  en: "en_US",
  ko: "ko_KR",
  ja: "ja_JP",
};

const useAppleSign = (locale = "en") => {
  const [isForce, setIsForce] = useState(false);

  const [loaded] = useScript(
    `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${SCRIPT_PATH[locale]}/appleid.auth.js`,
  );

  useEffect(() => {
    if (loaded) {
      const clientId = "com.dubberss.koo.dub2web";
      const redirectURI =
        typeof window === "undefined"
          ? "https://2dub.me/"
          : `${window.location.protocol}//${window.location.host}`;
      const state = "";
      const scope = "email name";
      const nonce = "";
      const usePopup = true;

      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId,
          scope,
          redirectURI,
          state,
          nonce,
          usePopup,
        });
      }
    }
  }, [loaded]);

  const handleClick = async () => {
    try {
      // eslint-disable-next-line no-undef
      const data = await AppleID.auth.signIn();
      const { id_token, code } = data.authorization;
      window.location.assign(
        `/api/oauth/apple?code=${id_token}&authorization_code=${code}`,
      );
    } catch (error) {
      ClientLogger.error("handleClick - useAppleSign", error);
    }
  };

  const handleClose = () => {
    setIsForce(false);
  };

  return {
    isForce,
    handleClick,
    handleClose,
  };
};

export default useAppleSign;
