import ENV_PUBLIC from "../client/ENV_PUBLIC";

export const LOG_LEVEL = {
  NONE: "none",
  FATAL: "fatal",
  ERROR: "error",
  WARN: "warn",
  INFO: "info",
  DEBUG: "debug",
  VERBOSE: "verbose",
  SILLY: "silly",
};
export type LOG_LEVEL = typeof LOG_LEVEL[keyof typeof LOG_LEVEL];

export const LOG_LEVELS_VALS = Object.values(LOG_LEVEL);

type LogConfig = {
  formatWithLevel?: boolean;
  formatWithTimestamp?: boolean;
};

export default class Logger {
  private static serviceName: string = "2DUB_WEB";

  private static fileLogLevel: string =
    process.env.STAGE == "T" ? LOG_LEVEL.NONE : LOG_LEVEL.DEBUG;

  private static consoleLogLevel: string = LOG_LEVEL.DEBUG;

  private static logConfig: LogConfig = {
    formatWithLevel: true,
    formatWithTimestamp: true,
  };

  protected static _log({
    level,
    message,
    callback,
    ...optionalParams
  }: {
    level: LOG_LEVEL;
    message: string;
    callback: (level: LOG_LEVEL, message: string) => Promise<void>;
  }) {
    const output = this.makeOutput({ level, message, ...optionalParams });
    if (ENV_PUBLIC.IS_PROD) {
      this.logToKafka({ level, message: output, callback });
    } else {
      this.logToConsole({ level, message: output });
    }
  }

  protected static logToKafka({
    level,
    message,
    callback,
  }: {
    level: LOG_LEVEL;
    message: string;
    callback: (level: LOG_LEVEL, message: string) => Promise<void>;
  }) {
    if (
      LOG_LEVELS_VALS.indexOf(level) <=
      LOG_LEVELS_VALS.indexOf(this.fileLogLevel)
    ) {
      // kafka
      callback(level, message);
    }
  }

  protected static logToConsole({
    level,
    message,
  }: {
    level: LOG_LEVEL;
    message: string;
  }) {
    if (
      LOG_LEVELS_VALS.indexOf(level) <=
      LOG_LEVELS_VALS.indexOf(this.consoleLogLevel)
    ) {
      if (message.split("\\n").length > 1) {
        const msg = message.split("\\n").join("\n");
        if (level === LOG_LEVEL.ERROR) {
          console.error(msg);
          return;
        }
        console.log(msg);
        return;
      }
      if (level === LOG_LEVEL.ERROR) {
        console.error(message);
        return;
      }
      console.log(message);
    }
  }

  protected static makeOutput({
    level,
    message,
    ...optionalParams
  }: {
    level: LOG_LEVEL;
    message: string;
  }) {
    let output = `${this.serviceName} | `;
    if (this.logConfig.formatWithLevel) {
      output += `[${level.toUpperCase()}] `;
    }
    if (this.logConfig.formatWithTimestamp) {
      // date formatting
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      output += `${dateString} - `;
    }
    if (optionalParams) {
      if (Object.values(optionalParams).length > 0) {
        const newParams = Object.values(optionalParams).map(
          (el: any) => el.stack ?? el,
        );

        output += `[${JSON.stringify(Object.values(newParams))}] `;
      }
    }
    output += message;
    return output;
  }
}
