import { useContext } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { DubDialog } from "@/modules/common/dialog";
import { VideoModel } from "@/modules/common/video-player/player.types";
import VideoProvider, {
  VideoContext,
} from "@/modules/common/video-player/player.provider";
import { H5 } from "@/modules/common/fonts";
import ProfileImage from "@/modules/common/profile";
import VideoPlayer from "@/modules/common/video-player/videoPlayer";
import videoThumbnailUrl from "@/modules/utils/videoThumbnailUrl";
import { EVENT, send } from "@/lib/amplitude";
import { ConvertText } from "./LandingStyle";

interface UserModel {
  idx: number;
  name: string;
  profile_url: string;
  membership: string;
  dub_user_flag: string;
  created_at: string;
}

interface DubbedModel {
  idx: number;
  file_url: string;
  video: {
    thumbnail_url: string;
    watch_flag: string;
    language: string;
    title: string;
  };
  users: Array<UserModel>;
}

type Props = {
  open: boolean;
  onClose: () => void;
  data: DubbedModel;
};

const VideoDialog = (props: Props) => {
  const { t } = useTranslation();
  const { open, data, onClose } = props;

  const video = data.video as VideoModel;

  return (
    <DubDialog
      size="medium"
      open={open}
      center={!(window.innerWidth <= 777)}
      header={data.users[0].name}
      onHeaderClose
      body={
        <DialogBody>
          <Container>
            <VideoProvider video={video}>
              <DubbingCard dubbed={data} />
            </VideoProvider>
          </Container>
          <ButtonRow>
            <CloseButton onClick={onClose}>
              <ConvertText text={t("button_close")} />
            </CloseButton>
          </ButtonRow>
        </DialogBody>
      }
    />
  );
};

const DialogBody = styled.div`
  padding: 24px 32px 28px;
  @media screen and (max-width: 777px) {
    padding: 18px;
  }
`;

const Container = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background-color: black;

  position: relative;
  @media screen and (max-width: 777px) {
    border-radius: 17px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
`;

const CloseButton = styled.div`
  padding: 8px 18px;
  border-radius: 16px;
  background-color: #ff475d;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  @media screen and (max-width: 777px) {
    width: 100%;
    padding: 12px 18px;
  }
`;

const DubbingCard = ({ dubbed }: { dubbed: DubbedModel }) => {
  const { playing, playDubbed, pause } = useContext(VideoContext);

  const handlePlay = () => {
    playDubbed({ url: dubbed.file_url });
  };

  const handlePause = () => {
    pause();
  };

  const togglePlay = () => {
    if (playing) {
      handlePause();
    } else {
      send(EVENT.CLICK__LANDING_PLAY_DUB_VIDEO, {
        is_mobile: false,
      });
      handlePlay();
    }
  };

  return (
    <VideoContainer>
      <PlayerBox>
        <VideoPlayer />
      </PlayerBox>
      {!playing && <CardImage src={videoThumbnailUrl(dubbed.video)} />}
      <ControlLayer playing={playing} onClick={togglePlay}>
        <PlayIcon
          className={
            playing ? "icon icon-player_pause" : "icon icon-player_play"
          }
        />
        <UserRow>
          <ProfileImage
            size="xxsmall"
            src={dubbed.users[0].profile_url}
            membership={dubbed.users[0].membership}
          />
          <UserName>{dubbed.users[0].name}</UserName>
        </UserRow>
      </ControlLayer>
    </VideoContainer>
  );
};

const CardImage = styled.img`
  position: absolute;
  top: 0;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
`;

const ControlLayer = styled.div<{ playing: boolean }>`
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  ${(props) => !props.playing && `opacity: 1;`}
`;

const PlayIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 32px;
  height: 32px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: var(--white);
`;

const UserRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: start;
  align-items: center;
  bottom: 24px;
  gap: 8px;
  width: 100%;
  padding: 0 24px;
`;

const UserName = styled(H5)`
  color: var(--white);
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PlayerBox = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export default VideoDialog;
