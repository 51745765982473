import "../styles/globals.css";
import "../styles/styles.css";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import ModalProvider from "mui-modal-provider";
import customTheme from "theme/muiTheme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import Grow from "@mui/material/Grow";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Head from "next/head";
import NextNProgress from "nextjs-progressbar";
import ENV_SERVER from "@/scripts/server/ENV_SERVER";
import useSessionCheck from "@/scripts/useSessionCheck";
import * as ga from "../lib/ga";
import ErrorBoundary from "../modules/maintenance/ErrorBoundary";

const theme = createTheme(customTheme);

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useSessionCheck();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,minimum-scale=1.0" />
      </Head>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={2000}
          TransitionComponent={Grow}
          preventDuplicate
          style={{
            whiteSpace: "pre-line",
          }}
        >
          <ErrorBoundary {...pageProps}>
            <ModalProvider>
              <NextNProgress
                color="#ff334b"
                options={{
                  showSpinner: false,
                }}
              />
              <Component {...pageProps} />
            </ModalProvider>
          </ErrorBoundary>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
};
export const getStaticProps = () => {
  ENV_SERVER.init_ENV_SERVER();
};
export default appWithTranslation(MyApp);
