import styled from "@emotion/styled";
import { useEffect } from "react";
import { B1, H3 } from "../common/fonts";
import MaintenanceHeader from "./common/MaintenanceHeader";

type Props = {
  img: string;
  width: number;
  height: number;
  title: string;
  body: string;
  button: any;
};

const Maintenance = (props: Props) => {
  const { img, width, height, title, body, button } = props;

  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.style.backgroundColor = "var(--gray030)";
  });

  return (
    <Container>
      <MaintenanceHeader />
      <Wrapper>
        <Card>
          <Image src={img} width={width} height={height} alt="stopped-img" />
          <Title>{title}</Title>
          <Body dangerouslySetInnerHTML={{ __html: body }} />
          <ButtonWrapper>{button}</ButtonWrapper>
        </Card>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 600px;
  height: 100vh;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 60px 24px;
  background-color: var(--gray030);
`;

const Card = styled.div`
  width: 100%;
  max-width: 720px;
  max-height: 460px;
  background-color: var(--white);
  padding: 55px 72px;
  text-align: center;
  border-radius: 16px;
  margin: 0 auto;
`;

const Image = styled.img`
  margin-bottom: 32px;
`;

const Title = styled.div`
  font-size: var(--fs-t1);
  line-height: var(--lh-t1);
  color: var(--gray090);
  font-weight: 600;
`;

const Body = styled(B1)`
  color: var(--gray070);
  padding: 16px 0 24px;
`;

const ButtonWrapper = styled.div``;

export default Maintenance;
