import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";

import { useTranslation } from "next-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { B2 } from "../common/fonts";
import CustomDialogTitle from "../dialog/CustomDialogTitle";
import PasswordField from "./component/passwordField";
import ForceSignIn from "./ForceSignIn";
import useClassSignIn from "./hooks/useClassSignIn.hook";
import { AuthProps } from "./types/useAuth.types";

type Props = DialogProps & AuthProps;

const ClassSignIn = ({ open, handleClose }: Props) => {
  const {
    idProps,
    passwordProps,
    disabledSubmit,
    loading,
    remember,
    isForce,
    handleRemember,
    handleSubmit,
  } = useClassSignIn();

  const { t } = useTranslation();

  const onClose = () => {
    if (loading) return;
    handleClose();
  };

  if (isForce) {
    return (
      <ForceSignIn
        open={open}
        loading={loading}
        handleClose={onClose}
        handleSubmit={handleSubmit}
      />
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="dialog-md">
      <CustomDialogTitle id="login_class" center onClose={onClose}>
        {t("login_class")}
      </CustomDialogTitle>
      <DialogContent>
        <DialogBody>
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box style={{ paddingBottom: 16 }}>
              <TextField
                value={idProps.value}
                error={idProps.error}
                helperText={idProps.helperText}
                onChange={idProps.onChange}
                fullWidth
                label={t("textfield_studentID")}
              />
            </Box>
            <Box style={{ paddingBottom: 12 }}>
              <PasswordField
                value={passwordProps.value}
                error={passwordProps.error}
                helperText={passwordProps.helperText}
                onChange={passwordProps.onChange}
                fullWidth
                label={t("textfield_pw")}
              />
            </Box>
            <Box style={{ paddingBottom: 24 }}>
              <OptionRow>
                <RememberID onClick={handleRemember}>
                  {remember ? (
                    <Check className="icon icon-check_button" />
                  ) : (
                    <UnCheck />
                  )}
                  <RememberIdText>{t("login_remember_me")}</RememberIdText>
                </RememberID>
              </OptionRow>
            </Box>
            <LoadingButton
              variant="contained"
              color="magenta"
              size="large"
              fullWidth
              disableElevation
              loading={loading}
              disabled={disabledSubmit}
              onClick={handleSubmit}
            >
              {t("button_login")}
            </LoadingButton>
          </form>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

const DialogBody = styled.div`
  padding: 24px 32px 32px;
`;

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Check = styled.div`
  font-size: 16px;
  color: var(--blue070);
`;

const UnCheck = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid var(--selectionControl_base_gray);
  border-radius: 9999px;
`;

const RememberID = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;

  &:hover {
    ${Check} {
      color: var(--blue080);
    }
  }
  &:hover {
    ${UnCheck} {
      background-color: var(--gray020);
    }
  }
`;

const RememberIdText = styled(B2)`
  color: var(--gray080);
`;

export default ClassSignIn;
