import { type DialogProps } from "@mui/material/Dialog";
import EnterBirthday from "./EnterBirthday";
import useExtraInfo from "./hooks/useExtraInfo.hook";
import InviteCode from "./InviteCode";
import SelectPath from "./SelectPath";
import { AuthInfo } from "./types/useAuth.types";
import WelcomeDialog from "./WelcomeDialog";

interface Props extends DialogProps {
  locale: string;
  auth_info: AuthInfo;
}

const ExtraInfo = ({ open, locale, auth_info }: Props) => {
  const {
    handleBirthdayProps,
    handleSelectPathsProps,
    handleInviteCode,
    handleStep,
    handleCloseWelcomeDialog,
    step,
    name,
    loading,
  } = useExtraInfo(auth_info);

  if (step === 0) {
    return (
      <EnterBirthday
        auth_info={auth_info}
        open={open}
        locale={locale}
        handleStep={handleStep}
        handleBirthdayProps={handleBirthdayProps}
      />
    );
  }
  if (step === 1) {
    return (
      <SelectPath
        auth_info={auth_info}
        open={open}
        handleStep={handleStep}
        handleSelectPathsProps={handleSelectPathsProps}
      />
    );
  }
  if (step === 2) {
    return (
      <InviteCode
        auth_info={auth_info}
        open={open}
        locale={locale}
        loading={loading}
        handleInviteCode={handleInviteCode}
      />
    );
  }

  return (
    <WelcomeDialog
      open={open}
      auth_info={auth_info}
      handleClose={handleCloseWelcomeDialog}
      name={name}
    />
  );
};

export default ExtraInfo;
