import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { type DialogProps } from "@mui/material/Dialog";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { EVENT, send } from "@/lib/amplitude";
import { B2 } from "../common/fonts";
import Apple from "./component/Apple";
import Google from "./component/Google";
import useSignIn from "./hooks/useSignIn.hook";
import { AuthProps, AuthDialogType } from "./types/useAuth.types";
import ForceSignIn from "./ForceSignIn";
import DubDialog from "../common/dialog/DubDialog";
import DubButton from "../common/button/DubButton";
import { DubPassword, DubTextField } from "../common/textfield";
import BlockedUser from "./BlockedUser";
import useKakao from "./hooks/useKakao.hook";

type Props = DialogProps & AuthProps;

const SignIn = ({ open, handleClose, handleSwitch, locale }: Props) => {
  const {
    emailProps,
    passwordProps,
    disabledSubmit,
    loading,
    remember,
    isForce,
    isBlocked,
    blockMessage,
    handleRemember,
    handleSubmit,
  } = useSignIn();

  const { t } = useTranslation();

  const onClose = () => {
    if (loading) return;
    handleClose();
  };

  const onSwitch = (type: AuthDialogType) => {
    if (loading) return;
    handleSwitch(type);
  };

  useEffect(() => {
    if (open) {
      localStorage.setItem("referrer_location", window.location.href);
    }
  }, [open]);

  const { handleAuthorize } = useKakao();

  const handleSigninKakao = () => {
    send(EVENT.CLICK__SIGNIN_BY_KAKAO);
    handleAuthorize();
  };

  if (isForce) {
    return (
      <ForceSignIn
        open={open}
        loading={loading}
        handleClose={onClose}
        handleSubmit={handleSubmit}
      />
    );
  }

  // 계정정지 회원
  if (isBlocked) {
    return (
      <BlockedUser
        open={open}
        locale={locale}
        blockMessage={blockMessage}
        handleClose={onClose}
      />
    );
  }

  return (
    <DubDialog
      open={open}
      onClose={onClose}
      size="medium"
      center
      header={t("login_title")}
      body={
        <DialogBody>
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box style={{ paddingBottom: 16 }}>
              <DubTextField
                label={t("textfield_email")}
                value={emailProps.value}
                error={emailProps.error}
                helperText={emailProps.helperText}
                onChange={emailProps.onChange}
              />
            </Box>
            <Box style={{ paddingBottom: 12 }}>
              <DubPassword
                label={t("textfield_pw")}
                error={passwordProps.error}
                helperText={passwordProps.helperText}
                onChange={passwordProps.onChange}
              />
            </Box>

            <Box style={{ paddingBottom: 36 }}>
              <OptionRow>
                <RememberID onClick={handleRemember}>
                  {remember ? (
                    <Check className="icon icon-check_button" />
                  ) : (
                    <UnCheck />
                  )}
                  <RememberIdText>{t("login_remember_me")}</RememberIdText>
                </RememberID>
                {/* <DubButton
                  variant="text"
                  color="gray"
                  size="small"
                  onClick={() => onSwitch(AuthDialogType.ResetPasswordDialog)}
                  style={{
                    padding: 8,
                    transform: "translateX(8px)",
                  }}
                >
                  {t("button_forgot_pw")}
                </DubButton> */}
              </OptionRow>
            </Box>
            <DubButton
              type="submit"
              variant="fill"
              color="magenta"
              size="large"
              fullWidth
              disabled={disabledSubmit}
              onClick={handleSubmit}
              loading={loading}
            >
              {t("button_login")}
            </DubButton>
          </form>

          <Box style={{ paddingTop: 40, paddingBottom: 12 }}>
            <Google type="signin">{t("button_login_withGoogle")}</Google>
          </Box>
          <Box style={{ paddingBottom: 12 }}>
            <Apple type="signin" locale={locale}>
              {t("button_login_withApple")}
            </Apple>
          </Box>

          {locale !== "ja" && (
            <>
              <AuthDivider>
                <B2>{t("register_or")}</B2>
              </AuthDivider>
              <Box style={{ paddingBottom: 20 }}>
                <FieldBox>
                  <Kakao onClick={handleSigninKakao} />
                </FieldBox>
              </Box>
            </>
          )}
        </DialogBody>
      }
    />
  );
};

const DialogBody = styled.div`
  padding: 24px 32px 32px;
`;

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Check = styled.div`
  font-size: 16px;
  color: var(--coral050);
`;

const UnCheck = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid var(--selectionControl_base_gray);
  border-radius: 9999px;
`;

const RememberID = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;

  &:hover {
    ${Check} {
      color: var(--coral050);
    }
  }
  &:hover {
    ${UnCheck} {
      background-color: var(--gray020);
    }
  }
`;

const RememberIdText = styled(B2)`
  color: var(--gray080);
`;

const AuthDivider = styled.div`
  margin: 16px 0;
  position: relative;
  width: 100%;
  height: 20px;
  ::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 100%;
    height: 1px;
    background-color: var(--gray030);
  }

  ${B2} {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--white);
    padding: 0 8px;
    color: var(--gray060);
  }
`;

const FieldBox = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
`;

const Kakao = styled.div`
  width: 55px;
  height: 55px;
  background: url("/img/auth/social-kakao.png");
  background-size: contain;
  cursor: pointer;
`;

const OtherOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default SignIn;
