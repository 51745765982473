import { useTranslation } from "next-i18next";
import styled from "@emotion/styled";
import { B2 } from "@/modules/common/fonts";
import { useLocale } from "@/modules/provider/LocaleProvider";
import { appStore, playStore } from "@/modules/utils/redirect";
import { ConvertText, Section } from "./LandingStyle";

const Section12 = () => {
  const { t } = useTranslation();

  const locale = useLocale();

  const goToPlayStore = () => {
    playStore(locale);
  };

  const goToAppStore = () => {
    appStore(locale);
  };

  return (
    <Section bgColor="#2a2a2a">
      <Wrapper>
        <ContentWrapper>
          <LeftWrapper>
            <TitleText>
              <ConvertText
                text={t("land_12")}
                target={t("land_12_txt")}
                targetBold
                targetColor="var(--primary050)"
              />
            </TitleText>
            <ButtonWrapper>
              <PlayStoreButton onClick={goToPlayStore} />
              <AppStoreButton onClick={goToAppStore} />
            </ButtonWrapper>
          </LeftWrapper>
          <QRWrapper>
            <QrContainer>
              <Qr />
            </QrContainer>
            <QRText>
              <ConvertText text={t("land_12_qr")} />
            </QRText>
          </QRWrapper>
        </ContentWrapper>
      </Wrapper>
    </Section>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1240px;
  padding: 150px 0;
  position: relative;

  @media screen and (max-width: 1439px) {
    padding: 150px 60px;
  }

  @media screen and (max-width: 1023px) {
    padding: 150px 60px;
  }

  @media screen and (max-width: 599px) {
    padding: 80px 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.div`
  color: var(--white);
  font-size: 50px;
  font-weight: 700;
  line-height: 71px;
  @media screen and (max-width: 777px) {
    font-size: 40px;
    line-height: normal;
  }
  @media screen and (max-width: 599px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 24px;
  @media screen and (max-width: 599px) {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 32px;
  }
`;

const PlayStoreButton = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background: url("/img/new_landing/common/google_logo.png");
  background-position: center;
  background-size: contain;
  cursor: pointer;
  @media screen and (max-width: 599px) {
    width: 66px;
    height: 66px;
  }
`;

const AppStoreButton = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background: url("/img/new_landing/common/apple_logo.png");
  background-position: center;
  background-size: contain;
  cursor: pointer;
  @media screen and (max-width: 599px) {
    width: 66px;
    height: 66px;
  }
`;

const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QrContainer = styled.div`
  width: 200px;
  height: 200px;

  display: flex;
  justify-content: center;
  border: 3.6px solid transparent;
  border-radius: 8px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(135deg, #ff923c 0%, #ff4747 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  @media screen and (max-width: 599px) {
    width: 177px;
    height: 177px;
  }
`;

const Qr = styled.div`
  width: 80%;
  height: 80%;
  background: url("/img/new_landing/desktop/section_12_image_4.png");
  background-position: center;
  background-size: contain;
  margin: auto;
`;

const QRText = styled(B2)`
  margin-top: 16px;
  font-size: 20px;
  font-weight: 400;
  color: var(--gray080);
  white-space: pre-wrap;
  @media screen and (max-width: 599px) {
    margin-top: 11px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export default Section12;
