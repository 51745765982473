import { DubButton } from "../common/button";
import Maintenance from "./Maintenance";

const STRINGS = {
  title: {
    en: "Whoops!",
    ko: "이런! 투덥이 멈췄어요",
    ja: "あら！2DUBが止まってしまいました",
  },
  body: {
    en: "Let's go back to the screen or<br/>let us know via Contact us.",
    ko: "홈으로 이동하거나 문의하기를 통해<br/>자세한 내용을 알아보세요.",
    ja: "前の画面に戻ったり<br/>詳しくはお問い合わせください。",
  },
  contact: {
    en: "Contact us",
    ko: "문의하기",
    ja: "お問い合わせ",
  },
  home: {
    en: "Home",
    ko: "홈으로",
    ja: "ホーム",
  },
};

const Stopped = ({ locale = "en" }: { locale: "en" | "ko" | "ja" }) => {
  return (
    <Maintenance
      img="/img/maintenance/stopped.png"
      width={150}
      height={150}
      title={STRINGS.title[locale]}
      body={STRINGS.body[locale]}
      button={
        <>
          <a href="mailto:2dub@2meu.me">
            <DubButton
              color="lightCoral"
              size="regular"
              sx={{ marginRight: "12px" }}
            >
              {STRINGS.contact[locale]}
            </DubButton>
          </a>
          <DubButton
            color="magenta"
            size="regular"
            onClick={() => window.location.replace("/")}
          >
            {STRINGS.home[locale]}
          </DubButton>
        </>
      }
    />
  );
};

export default Stopped;
