import { EVENT, send } from "@/lib/amplitude";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ClientLogger } from "@/scripts/log/client";
import { ACCOUNT_TYPE, AuthInfo } from "../types/useAuth.types";
import useTextField from "./useTextField.hook";

const useInviteCode = (
  handleInviteCode: (code: string) => void,
  auth_info: AuthInfo,
  locale: string,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);
  const codeProps = useTextField("");

  const handleVerifyCode = async () => {
    setVerifying(true);
    try {
      const response = await fetch(
        `${
          process.env.NEXT_PUBLIC_API_URL
        }/api/data/invite/code/verify?code=${codeProps.value.toUpperCase()}&dl=${locale}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const result = await response.json();
      const { code, error } = result;
      setVerifying(false);
      switch (code) {
        case 200:
          setVerified(true);
          break;
        case 1010: // 초대코드를 Query 파리미터로 요청하지 않았을 때
          enqueueSnackbar(error.errorMessage);
          break;
        case 2200: // 존재하지 않는 초대코드일 때
          codeProps.handleError(error.errorMessage);
          break;
        case 400:
        default:
          enqueueSnackbar(error.errorMessage);
          break;
      }
    } catch (error) {
      setVerifying(false);
      ClientLogger.error("handleVerifyCode - useInviteCode", error);
    }
  };
  const handleClickNext = () => {
    send(EVENT.CLICK__CONFIRM_INVITE_CODE, {
      account_type: ACCOUNT_TYPE[auth_info.type],
    });
    handleInviteCode(codeProps.value);
  };

  const buttonCodeDisabled =
    verified || codeProps.value.length === 0 || codeProps.error;

  return {
    codeProps,
    handleVerifyCode,
    verified,
    verifying,
    handleClickNext,
    buttonCodeDisabled,
  };
};

export default useInviteCode;
