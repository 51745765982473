import styled from "@emotion/styled";
import { useEffect, useState } from "react";

const MaintenanceHeader = () => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    function scrollListner() {
      setScrollY(window.scrollY);
    }

    scrollListner();
    window.addEventListener("scroll", scrollListner);

    return () => {
      window.removeEventListener("scroll", scrollListner);
    };
  }, []);

  return (
    <HeaderContainer className={scrollY > 0 ? "" : "top"}>
      <HeaderWrapper>
        <HeaderLogo>
          <a href="/">
            <Image
              src="/img/common/logo-text.png"
              alt="2dub"
              width="83px"
              height="24px"
            />
          </a>
        </HeaderLogo>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  min-height: 64px;
  background-color: var(--white);
  box-shadow: var(--bar_elevation_lightGray);
  &.top {
    box-shadow: inset 0 -1px 0 0 #eeeeee;
  }
`;

const HeaderWrapper = styled.div`
  max-width: 1440px;
  height: 100%;
  padding: 0 24px;
  margin: 0 auto;

  display: flex;
  align-items: center;
`;

const HeaderLogo = styled.div`
  cursor: pointer;
`;

const Image = styled.img``;

export default MaintenanceHeader;
