import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { type TextFieldProps } from "@mui/material/TextField";
import styled from "@emotion/styled";
import CustomTextField from "./CustomTextField";

export type Props = TextFieldProps & {
  maxLength?: number;
  loading?: boolean;
  confirm?: boolean;
  suffix?: string;
};

const DubTextField = (props: Props) => {
  const { maxLength, helperText, value, confirm, loading, suffix, ...rest } =
    props;

  const showHelperText = maxLength || helperText;
  const inputProps = {
    ...(loading && {
      endAdornment: (
        <InputAdornment position="end">
          <CircularProgress size="24px" />
        </InputAdornment>
      ),
    }),
    ...(confirm && {
      endAdornment: (
        <InputAdornment position="end">
          <IconChecked className="icon icon-checked" />
        </InputAdornment>
      ),
    }),
    ...(suffix && {
      endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
    }),
  };

  return (
    <CustomTextField
      value={value}
      helperText={
        showHelperText ? (
          <Box component="span" display="flex">
            {helperText && <span>{helperText}</span>}
            {maxLength && typeof value === "string" && (
              <Box component="span" ml="auto" color="var(--gray080)">
                {value.length}/{maxLength}
              </Box>
            )}
          </Box>
        ) : null
      }
      InputProps={inputProps}
      {...rest}
    />
  );
};

const IconChecked = styled.div`
  font-size: 24px;
  color: #4fcb6b;
  user-select: none;
`;

export default DubTextField;
