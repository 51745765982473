import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { MouseEventHandler } from "react";
import { Breakpoint } from "@mui/system/createTheme/createBreakpoints";
import styled from "@emotion/styled";

const SIZE = {
  regular: "dialog-sm", // 400px
  medium: "dialog-md", // 560px
  large: "dialog-lg", // 980px
};

interface Props extends DialogProps {
  size: keyof typeof SIZE;
  center?: boolean;
  onHeaderClose?: boolean;
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  loading?: boolean;
}

/*
    단독으로 사용할수도있고
    Modal-Provider 활용해서 사용할수도 있음
    open, onClose (handleClose) 필수값
  
    기존의 close 는 onHeaderClose로 바뀜
    center : 타이틀에 가운데 정렬이 되어 있으면 true
  */

const DubDialog = (props: Props) => {
  const {
    size,
    center,
    header,
    body,
    footer,
    loading,
    onHeaderClose,
    open,
    onClose,
    ...rest
  } = props;

  const textAlign = center ? "center" : "left";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={SIZE[size] as Breakpoint}
      {...rest}
    >
      {header && (
        <DialogTitle sx={{ textAlign }}>
          {header}
          {onHeaderClose && onClose && (
            <CloseIcon
              onClick={onClose as MouseEventHandler<HTMLButtonElement>}
              className="icon icon-close"
            />
          )}
        </DialogTitle>
      )}
      {body && (
        <DialogContent
          className="scrollbar"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          {body}
        </DialogContent>
      )}
      {footer && <DialogActions>{footer}</DialogActions>}
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" size={32} />
        </Backdrop>
      )}
    </Dialog>
  );
};

DubDialog.defaultProps = {
  center: undefined,
  onHeaderClose: undefined,
  header: undefined,
  body: undefined,
  footer: undefined,
};

const CloseIcon = styled.button`
  position: absolute;
  top: 32px;
  right: 32px;
  border: 0;
  outline: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  font-size: 24px;
  &:hover {
    color: var(--violet050);
  }
`;

export default DubDialog;
