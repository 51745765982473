import { useRouter } from "next/router";
import { useEffect } from "react";

const exclusion = [
  "/",
  "/solution",
  "/admin",
  "/dubbed/[code]",
  "/original/[code]",
];

export default async function useSessionCheck() {
  const router = useRouter();
  useEffect(() => {
    const check = async () => {
      if (exclusion.includes(router.pathname)) {
        return;
      }
      fetch("/api/sessionCheck", { cache: "no-store" })
        .then((res) => res.json())
        .then((res) => {
          if (res.code !== 200) {
            router.push("/");
          }
        });
    };
    const interval = setInterval(check, 300000);
    return () => clearInterval(interval);
  }, [router]);
}
