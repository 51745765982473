import { ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    "dialog-sm": true;
    "dialog-md": true;
    "dialog-lg": true;
  }

  interface Palette {
    sky: Palette["primary"];
    lightBlue: Palette["primary"];
    subBlue: Palette["primary"];
    blue: Palette["primary"];
    lightCoral: Palette["primary"];
    coral: Palette["primary"];
    red: Palette["primary"];
    magenta: Palette["primary"];
    lightMagenta: Palette["primary"];
    green: Palette["primary"];
    darkGreen: Palette["primary"];
    gray: Palette["primary"];
    grayMobile: Palette["primary"];
    violet: Palette["primary"];
    lightViolet: Palette["primary"];
    black: Palette["primary"];
    amber: Palette["primary"];
    autumn: Palette["primary"];
    orange: Palette["primary"];
    yellow: Palette["primary"];
    colorPlaying: Palette["primary"];
    colorDubbing: Palette["primary"];
    playing: Palette["primary"];
    colorVoicing: Palette["primary"];
    white: Palette["primary"];
    brightSubBlue: Palette["primary"];
  }
  interface PaletteOptions {
    sky: PaletteOptions["primary"];
    lightBlue: PaletteOptions["primary"];
    subBlue: PaletteOptions["primary"];
    blue: PaletteOptions["primary"];
    lightCoral: PaletteOptions["primary"];
    coral: PaletteOptions["primary"];
    magenta: PaletteOptions["primary"];
    lightMagenta: PaletteOptions["primary"];
    green: PaletteOptions["primary"];
    darkGreen: PaletteOptions["primary"];
    red: PaletteOptions["primary"];
    gray: PaletteOptions["primary"];
    grayMobile: PaletteOptions["primary"];
    violet: PaletteOptions["primary"];
    lightViolet: PaletteOptions["primary"];
    black: PaletteOptions["primary"];
    amber: PaletteOptions["primary"];
    autumn: PaletteOptions["primary"];
    orange: PaletteOptions["primary"];
    yellow: PaletteOptions["primary"];
    colorPlaying: PaletteOptions["primary"];
    colorDubbing: PaletteOptions["primary"];
    playing: PaletteOptions["primary"];
    colorVoicing: PaletteOptions["primary"];
    white: PaletteOptions["primary"];
    brightSubBlue: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Slider" {
  interface SliderPropsColorOverrides {
    subBlue: true;
  }
}

declare module "@mui/material/Tabs" {
  interface TabsPropsTextColorOverrides {
    red: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    sky: true;
  }
}

declare module "@mui/material/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    subBlue: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    blue: true;
    lightBlue: true;
    coral: true;
    lightCoral: true;
    red: true;
    magenta: true;
    lightMagenta: true;
    green: true;
    gray: true;
    grayMobile: true;
    violet: true;
    lightViolet: true;
    black: true;
    amber: true;
    autumn: true;
    orange: true;
    darkGreen: true;
    yellow: true;
    colorPlaying: true;
    colorDubbing: true;
    playing: true;
    colorVoicing: true;
    white: true;
    subBlue: true;
    brightSubBlue: true;
  }
  interface ButtonPropsSizeOverrides {
    extraSmall: true;
    regular: true;
    regular_mobile: true;
    regular_upload: true;
  }

  interface ButtonPropsVariantOverrides {
    fill: true;
    line: true;
    text: true;
  }
}

const customTheme: ThemeOptions = {
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl", "dialog-sm", "dialog-md", "dialog-lg"],
    values: {
      // extra-small
      xs: 0,
      // small
      sm: 600,
      // medium
      md: 900,
      // large
      lg: 1200,
      // extra-large
      xl: 1536,
      "dialog-sm": 400,
      "dialog-md": 560,
      "dialog-lg": 980,
    },
  },
  typography: {
    fontFamily:
      "Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif",
  },
  palette: {
    error: {
      main: "#c7000d", // coral 100
    },
    primary: {
      light: "#59DA88", // primary040
      main: "#00D16C", // primary050
      dark: "#00934C", // primary080
      contrastText: "#fff", // white
    },
    sky: {
      light: "#81d6f1", // blue 030
      main: "#50c5ec", // blue 040
      dark: "#27b9ea", // blue 050
      contrastText: "#fff",
    },
    lightBlue: {
      light: "#f6fcfe", // blue 005
      main: "#e1f5fc", // blue 010
      dark: "#b3e6f6", // blue 020
      contrastText: "#007bb5", // blue 090
    },
    blue: {
      light: "#00ace9", // blue 070
      main: "#009edb", // blue 090
      dark: "#008cc8", // blue 100
      contrastText: "#fff",
    },
    subBlue: {
      light: "#1873FF",
      main: "#1873FF",
      dark: "#1873FF",
      contrastText: "#fff",
    },
    brightSubBlue: {
      light: "#E8F1FF",
      main: "#E8F1FF",
      dark: "#C2DAFF",
      contrastText: "#1873FF",
    },
    lightCoral: {
      light: "#fff9fa", // coral 005
      main: "#ffebee", // coral 010
      dark: "#ffccd1", // coral 020
      contrastText: "#ff5f5f",
    },
    coral: {
      light: "#f46d6d", // coral 040
      main: "#ff5f5f", // coral 045
      dark: "#ed4747", // coral 055
      contrastText: "#fff",
    },
    red: {
      light: "#e41023", // coral 080
      main: "#d7001b", // coral 090
      dark: "#c7000d", // coral 100
      contrastText: "#fff",
    },
    magenta: {
      light: "#ff7e8a", // primary 040 ??
      main: "#ff475d", // primary 050
      dark: "#d80039", // primary 080
      contrastText: "#fff",
    },
    lightMagenta: {
      light: "#FFEDEF",
      main: "#FFEDEF",
      dark: "#FFC0C7",
      contrastText: "#FF475D",
    },
    green: {
      light: "#4FCB6B", //
      main: "#4FCB6B", //
      dark: "#4FCB6B", //
      contrastText: "#fff",
    },
    darkGreen: {
      light: "#3A8254", //
      main: "#3A8254", //
      dark: "#3A8254", //
      contrastText: "#fff",
    },
    gray: {
      light: "#757575", // gray 070
      main: "#616161", // gray 080
      dark: "#424242", // gray 090
      contrastText: "#fff",
    },
    grayMobile: {
      light: "#EEEEEE", // gray 040
      main: "#EEEEEE", // gray 040
      dark: "#E0E0E0", // gray 040
      contrastText: "#616161",
    },
    black: {
      light: "#616161", // gray 080
      main: "#424242", // gray 090
      dark: "#212121", // gray 100
      contrastText: "#fff",
    },
    white: {
      light: "#fff", // gray 080
      main: "#fff", // gray 090
      dark: "#fff", // gray 100
      contrastText: "#000",
    },
    violet: {
      light: "#a796fd", // violet 030
      main: "#7c6bff", // violet 040
      dark: "#514aff", // violet 050
      contrastText: "#fff",
    },
    lightViolet: {
      light: "#f9f7ff", // violet 005
      main: "#ece5ff", // violet 010
      dark: "#ccc0fd", // violet 020
      contrastText: "#514aff", // violet 050
    },
    amber: {
      light: "#FF7742",
      main: "#FF7742",
      dark: "#FF7742",
      contrastText: "#fff",
    },
    autumn: {
      light: "#a86c55",
      main: "#a86c55",
      dark: "#a86c55",
      contrastText: "#fff",
    },
    orange: {
      light: "#F67949",
      main: "#F67949",
      dark: "#F67949",
      contrastText: "#fff",
    },
    yellow: {
      light: "#FFAF16",
      main: "#FFAF16",
      dark: "#FFAF16",
      contrastText: "#fff",
    },
    colorPlaying: {
      light: "#E8F1FF",
      main: "#E8F1FF",
      dark: "#B0CFFF", // 더빙화면
      // dark: "#E8F1FF", // 크리스마스
      contrastText: "#1873FF",
    },
    colorDubbing: {
      light: "#FFEDEF",
      main: "#FFEDEF",
      dark: "#FFC0C7", // 더빙화면
      // dark: "#FFEDEF", // 크리스마스
      contrastText: "#FF475D",
    },
    playing: {
      light: "#FF475D",
      main: "#FF475D",
      dark: "#FF475D",
      contrastText: "#FFEDEF",
    },
    colorVoicing: {
      light: "#F3FBF6", // primary010
      main: "#EAFAEF", // primary020
      dark: "#B3F2C7", // primary030
      contrastText: "#00C767", // primary060
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "var(--fs-b1)",
          lineHeight: "var(--lh-b1)",
          color: "#616161",
          "&.Mui-focused:not(.Mui-error)": {
            color: "#f8c030",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: "#e0e0e0",
            opacity: 1,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          color: "#212121",
          fontSize: "var(--fs-b1)",
          lineHeight: "var(--lh-b1)",
          "&.Mui-disabled": {
            color: "#9e9e9e",
            backgroundColor: "#ffffff",
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e0e0e0",
          },
          "&:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f8c030",
          },
          "&:not(.Mui-error):not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#616161",
            },
        },
        input: {
          height: "auto",
          padding: "16px",
          "&::placeholder": {
            color: "#616161",
            opacity: 1,
          },
          "&.MuiInputBase-inputMultiline": {
            padding: "0px",
          },
          WebkitBoxShadow: "0 0 0 1000px var(--white) inset",
        },
        notchedOutline: {
          borderColor: "#e0e0e0",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "var(--fs-c1)",
          lineHeight: "var(--lh-c1)",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "var(--gray030)",
          },
          "&.Mui-selected": {
            color: "var(--primary050)",
            backgroundColor: "var(--white)",
            "&.Mui-focusVisible": {
              backgroundColor: "var(--white)",
            },
            "&:hover": { backgroundColor: "var(--gray030)" },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "var(--gray060)",
            backgroundColor: "var(--gray040)",
          },
          "&:active": {
            transform: "scale(0.96)",
          },
          textTransform: "none",
          fontWeight: 600,
          borderRadius: "16px",
        },
      },
      variants: [
        {
          props: { size: "extraSmall" },
          style: {
            minWidth: "32px",
            minHeight: "28px",
            padding: "4px 7px",
            fontSize: "var(--fs-c1)",
            lineHeight: "var(--lh-c1)",
            borderRadius: "8px",
          },
        },
        {
          props: { size: "small" },
          style: {
            minWidth: "32px",
            minHeight: "36px",
            padding: "7px 11px",
            fontSize: "var(--fs-b2)",
            lineHeight: "var(--lh-b2)",
            borderRadius: "10px",
          },
        },
        {
          props: { size: "regular" },
          style: {
            minWidth: "88px",
            minHeight: "40px",
            padding: "7px 17px",
            fontSize: "var(--fs-b1)",
            lineHeight: "var(--lh-b1)",
            borderRadius: "16px",
          },
        },

        {
          props: { size: "large" },
          style: {
            minWidth: "98px",
            minHeight: "56px",
            padding: "15px 23px",
            fontSize: "var(--fs-b1)",
            lineHeight: "var(--lh-b1)",
            borderRadius: "16px",
          },
        },
        {
          props: { variant: "contained" },
          style: {
            minWidth: "120px",
          },
        },
        {
          props: { size: "extraSmall" },
          style: {
            minWidth: "36px",
          },
        },
        {
          props: { variant: "text", size: "small" },
          style: {
            minWidth: "16px",
            minHeight: "28px",
            padding: "4px 8px",
            fontSize: "var(--fs-b2)",
            lineHeight: "var(--lh-b2)",
            borderRadius: "10px",
          },
        },

        {
          props: { variant: "outlined" },
          style: {
            ":disabled": {
              color: "var(--gray060)",
              backgroundColor: "var(--white)",
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            minWidth: "36px",
            ":disabled": {
              color: "var(--gray060)",
              backgroundColor: "var(--white)",
            },
          },
        },
        {
          props: { variant: "text", size: "regular_upload" },
          style: {
            minWidth: "88px",
            minHeight: "40px",
            padding: "7px 17px",
            fontSize: "var(--fs-b1)",
            lineHeight: "var(--lh-b1)",
            borderRadius: "16px",
          },
        },
        {
          props: { size: "regular_mobile" },
          style: {
            minWidth: "120px",
            minHeight: "48px",
            padding: "12px 18px",
            fontSize: "var(--fs-b1)",
            lineHeight: "var(--lh-b1)",
            borderRadius: "16px",
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "16px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "var(--fs-h4)",
          lineHeight: "var(--lh-h4)",
          color: "var(--black)",
          padding: "32px 32px 0",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "12px 32px 32px",
          "&>:not(:first-of-type)": {
            marginLeft: "12px",
          },
        },
      },
    },
  },
};

export default customTheme;
