import { useTranslation } from "next-i18next";
import styled from "@emotion/styled";
import { ConvertText, SubTitleText, TitleText } from "./LandingStyle";

const Section6 = () => {
  const { t } = useTranslation();
  // const translateSpan = (origin: string, target: string) => {
  //   return origin.replace(target, `<span>${target}</span>`);
  // };
  return (
    <Section>
      <Wrapper>
        <TitleTextWrapper>
          <TitleText>
            <ConvertText text={t(`land_6_title`)} />
          </TitleText>
        </TitleTextWrapper>
        <SubTextWrapper>
          <SubTitleText>
            <ConvertText text={t("land_6_body")} />
          </SubTitleText>
        </SubTextWrapper>
      </Wrapper>
    </Section>
  );
};
const Section = styled.div`
  position: relative;
  overflow: hidden;
  min-width: 360px;
  width: 100%;
  height: 600px;
  padding: 0 330px;
  background: url("/img/solution_intro/image_6.png") no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 1439px) {
    height: 600px;
    padding: 0 174px;
  }

  @media screen and (max-width: 1023px) {
    height: 600px;
    padding: 0 69px;
  }

  @media screen and (max-width: 600px) {
    height: 300px;
    padding: 0 29px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TitleTextWrapper = styled.div`
  max-width: 650px;
  color: var(--gray090);
  margin-bottom: 12px;
  margin-top: 20px;
  padding: 0px 28px;
  @media screen and (max-width: 1023px) {
    text-align: center;
  }
`;
const SubTextWrapper = styled.div`
  max-width: 620px;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #4d4d4d;
  font-weight: light;
  margin-bottom: 32px;
  @media screen and (max-width: 1023px) {
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
  }
  @media screen and (max-width: 777px) {
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-align: center;
  }
`;

export default Section6;
