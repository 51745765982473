import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import {
  ContentWrapper,
  ConvertText,
  FunctionExplainTextWrapper,
  FunctionExplainVideoWrapper,
  IphoneImage,
  IphoneVideoWrapper,
  IphoneWrapper,
  Section,
  ChipText,
  SubTitleText,
  TitleText,
  Video,
  VideoWrapper,
  ContentSection,
  BackRectSection,
  BackRect,
} from "./LandingStyle";

const Section4 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <BackRectSection isRight={false}>
        <ContentSection>
          <VideoWrapper>
            <ContentWrapper isReverse>
              <FunctionExplainVideoWrapper>
                <IphoneContainer>
                  <IphoneWrapper>
                    <IphoneImage src="/img/solution_intro/iphone.png" alt="" />
                    <IphoneVideoWrapper>
                      <Video
                        src="/media/together_landing_fast.mp4"
                        autoPlay
                        loop
                        muted
                      />
                    </IphoneVideoWrapper>
                  </IphoneWrapper>
                </IphoneContainer>
              </FunctionExplainVideoWrapper>
              <FunctionExplainTextWrapper>
                <ChipWrapper>
                  <ChipContainer>
                    <ChipText color="#AD973A">
                      <ConvertText text={t("land_4_chip")} />
                    </ChipText>
                  </ChipContainer>
                </ChipWrapper>
                <TitleWrapper>
                  <TitleText>
                    <ConvertText text={t("land_4_title")} />
                  </TitleText>
                </TitleWrapper>
                <SubTitleWrapper>
                  <SubTitleText>
                    <ConvertText
                      text={`${t("land_4_body")}`}
                      target={`${t("land_4_body")}`}
                      targetColor="var(--gray090)"
                    />
                  </SubTitleText>
                </SubTitleWrapper>
              </FunctionExplainTextWrapper>
            </ContentWrapper>
          </VideoWrapper>
        </ContentSection>
        <BackRect color="#6fc78c" isRight={false} />
      </BackRectSection>
    </Section>
  );
};

const IphoneContainer = styled.div`
  width: 600px;
  height: 850px;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  /* background-color: #ff705e; */
  @media screen and (max-width: 1279px) {
    width: 600px;
    height: 600px;
    border-top-right-radius: 450px;
    border-top-left-radius: 450px;
    background-color: #6fc78c;
    justify-content: center;
  }
  @media screen and (max-width: 499px) {
    width: 350px;
    height: 420px;
  }
`;

const ChipWrapper = styled.div`
  display: flex;
  /* margin-bottom: 24px; */
`;

const ChipContainer = styled.div`
  /* padding: 9px 22px; */
  /* background-color: #d7af81;
  border-radius: 16px; */
  @media screen and (max-width: 499px) {
    /* padding: 8px 16px; */
  }
`;

const TitleWrapper = styled.div`
  color: var(--gray090);
  margin-bottom: 10px;
  @media screen and (max-width: 777px) {
    /* margin-bottom: 4px; */
  }
`;

const SubTitleWrapper = styled.div`
  @media screen and (max-width: 1279px) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: 499px) {
    margin-bottom: 40px;
  }
`;

export default Section4;
