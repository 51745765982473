import styled from "@emotion/styled";
import { type DialogProps } from "@mui/material/Dialog";
import { useTranslation } from "next-i18next";
import { DubButton } from "../common/button";
import { DubDialog } from "../common/dialog";
import { H3 } from "../common/fonts";
import { DubTextField } from "../common/textfield";
import useSelectPath from "./hooks/useSelectPath.hooks";
import { AuthInfo } from "./types/useAuth.types";

type Props = DialogProps & {
  handleSelectPathsProps: (paths: []) => void;
  handleStep: (step: number) => void;
  auth_info: AuthInfo;
};
const SelectPath = ({
  open,
  handleStep,
  handleSelectPathsProps,
  auth_info,
}: Props) => {
  const { t } = useTranslation();

  const {
    path,
    pathList,
    showTextField,
    handleChangeTextField,
    handleClickChecked,
    handleClickSkip,
    handleClickNext,
  } = useSelectPath(handleStep, handleSelectPathsProps, auth_info);

  return (
    <DubDialog
      open={open}
      fullWidth
      size="medium"
      center
      transitionDuration={0}
      header={t("register_title")}
      body={
        <DialogBody>
          <Title>{t("register_hear")}</Title>
          <Container>
            {path.map((p) => {
              return (
                <Chip
                  key={p.type}
                  active={pathList.findIndex((el) => el.type === p.type) !== -1}
                  onClick={() => handleClickChecked(p)}
                >
                  {p.label}
                </Chip>
              );
            })}
          </Container>

          <TextField show={showTextField} onChange={handleChangeTextField} />
        </DialogBody>
      }
      footer={
        <>
          <DubButton
            variant="text"
            color="gray"
            size="small"
            onClick={handleClickSkip}
          >
            {t("button_skip")}
          </DubButton>
          <DubButton
            variant="contained"
            color="magenta"
            size="regular"
            disabled={pathList.length === 0}
            onClick={handleClickNext}
          >
            {t("button_next")}
          </DubButton>
        </>
      }
    />
  );
};

const DialogBody = styled.div`
  padding: 24px 32px 32px;
`;

const Title = styled(H3)`
  font-weight: 600;
  text-align: center;
  padding-bottom: 32px;
`;

const Container = styled.div`
  display: flex;
  width: 350px;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 24px;
  margin: 0 auto;
`;

const Chip = styled.li<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 0 4px;
  cursor: pointer;
  padding: 6px 12px;
  background-color: var(--white);
  border-radius: 9999px;
  border: 1px solid var(--gray050);
  color: var(--gray090);
  font-size: var(--fs-b2);
  line-height: var(--lh-b2);

  &:hover {
    background-color: var(--gray030);
  }

  ${(props) =>
    props.active &&
    `
      background-color: #FDF0CD;
      border-color: #F8C030;
      
      &:hover {
        background-color: #F8C030;
      }
  `}
`;

const TextField = styled(DubTextField)<{ show: boolean }>`
  display: ${(props) => (props.show ? `block` : `none`)};
`;

export default SelectPath;
