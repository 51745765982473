import React, { useContext } from "react";

interface Props {
  value: "ko" | "en" | "ja";
  children: React.ReactNode;
}

const LocaleContext = React.createContext<Props["value"]>("en");

const LocaleProvider: React.FC<Props> = ({ value, children }) => {
  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
};

export const useLocale = () => {
  return useContext(LocaleContext);
};

export default LocaleProvider;
