/**
 * styled-component children 으로 사용
 * @param {string} text 입력 문자
 * @param {string} target 변환할 문자
 * @param {boolean} targetBold 변환할 문자 bold 지정
 * @param {string}  targetColor 변환할 문자 color 지정
 */

import styled from "@emotion/styled";

type ConvertTextProps = {
  text: string;
  target?: string;
  targetBold?: boolean;
  targetColor?: string;
};

export const ConvertText = (props: ConvertTextProps) => {
  const { text, target, targetBold, targetColor } = props;
  let textSplit: string[] = [];

  if (target) {
    textSplit = text.split(target);
    return (
      <>
        {brSplit(textSplit[0])}
        <span
          style={{ color: targetColor, fontWeight: targetBold ? "600" : "400" }}
        >
          {brSplit(target)}
        </span>
        {textSplit[1] && brSplit(textSplit[1])}
      </>
    );
  }

  return <>{brSplit(text)}</>;
};

ConvertText.defaultProps = {
  target: "",
  targetBold: false,
  targetColor: "",
};

const brSplit = (replaceText: string) => {
  const splitText = replaceText.split("<br/>");
  return splitText.map((el, i) => (
    <span key={el}>
      {el}
      {i !== splitText.length - 1 && <br />}
    </span>
  ));
};

export const TitleText = styled.div<{
  isAlone?: boolean;
  center?: boolean;
  isWhite?: boolean;
}>`
  font-size: 34px;
  line-height: 50px;
  font-weight: 600;
  word-break: keep-all;
  ${({ isAlone }) => !isAlone && `margin-bottom: 12px;`}
  ${({ center }) => center && `text-align: center;`}
    ${({ isWhite }) => isWhite && `color: white;`}
  
    @media screen and (max-width: 599px) {
    font-size: 20px;
    line-height: 29px;
    ${({ isAlone }) => !isAlone && `margin-bottom: 8px;`}
  }
`;

export const SubTitleText = styled.div<{
  center?: boolean;
  isWhite?: boolean;
}>`
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  word-break: keep-all;
  ${({ center }) => center && `text-align: center;`}
  ${({ isWhite }) => (isWhite ? `color: white;` : `color: var(--gray080);`)}
  
    @media screen and (max-width: 599px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ChipText = styled.div<{
  center?: boolean;
  color?: string;
}>`
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  word-break: keep-all;
  ${({ center }) => center && `text-align: center;`}
  ${({ color }) => color && `color: ${color};`}
  @media screen and (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Section = styled.div<{ bgColor?: string }>`
  width: 100%;
  min-width: 360px;
  position: relative;
  ${({ bgColor }) => bgColor && `background: ${bgColor};`}
  overflow: hidden;
`;

export const Wrapper = styled.div<{ deletePaddingBottom?: boolean }>`
  width: 100%;
  margin: 0 auto;
  max-width: 1240px;
  padding: 100px 0;
  position: relative;
  ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}

  @media screen and (max-width: 1439px) {
    padding: 100px 60px;
    ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}
  }

  @media screen and (max-width: 1023px) {
    padding: 100px 60px;
    ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}
  }

  @media screen and (max-width: 599px) {
    padding: 80px 20px;
    ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}
  }
`;

export const LargeWrapper = styled.div<{ deletePaddingBottom?: boolean }>`
  width: 100%;
  margin: 0 auto;
  max-width: 1240px;
  padding: 120px 0;
  position: relative;
  ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}

  @media screen and (max-width: 1023px) {
    padding: 120px 0px;
    ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}
  }

  @media screen and (max-width: 499px) {
    padding: 60px 20px;
    ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}
  }
`;

// 기능 설명 컴포넌트

export const VideoWrapper = styled.div<{ deletePaddingBottom?: boolean }>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1240px;
  padding: 0px 0;
  display: flex;

  ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}

  @media screen and (max-width: 1439px) {
    padding: 100px 60px;
    ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}
  }

  @media screen and (max-width: 1023px) {
    padding: 100px 60px;
    ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}
  }

  @media screen and (max-width: 599px) {
    padding: 80px 20px;
    ${({ deletePaddingBottom }) => deletePaddingBottom && `padding-bottom: 0;`}
  }
`;

export const ContentSection = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  @media screen and (max-width: 1279px) {
    position: initial;
  }
`;

export const BackRectSection = styled.div<{ isRight: boolean }>`
  width: 100%;
  height: 1050px;
  position: relative;
  display: flex;
  ${({ isRight }) =>
    isRight ? `justify-content: right;` : `justify-content: left;`}
  align-items: center;
  @media screen and (max-width: 1279px) {
    height: auto;
  }
`;

export const BackRect = styled.div<{ color: string; isRight: boolean }>`
  width: 70%;
  height: 850px;
  ${({ color }) => color && `background: ${color};`}
  ${({ isRight }) =>
    isRight
      ? `border-top-left-radius: 900px;
      border-bottom-left-radius: 900px;`
      : `border-top-right-radius: 900px;
      border-bottom-right-radius: 900px;`}
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div<{ isReverse: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1279px) {
    width: 100%;
    align-items: start;
    justify-content: start;
    ${({ isReverse }) =>
      isReverse ? `flex-direction: column-reverse;` : `flex-direction: column;`}
  }
`;

export const FunctionExplainTextWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) {
    width: 720px;
  }
  @media screen and (max-width: 777px) {
    width: 100%;
  }
`;

export const FunctionExplainVideoWrapper = styled.div`
  @media screen and (max-width: 1279px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

// iphone frame image
export const Video = styled.video`
  width: 100%;
`;

export const IphoneWrapper = styled.div`
  width: 400px;
  margin-top: 360px;

  position: relative;

  @media screen and (max-width: 1279px) {
    width: 330px;
    margin-top: 200px;
  }

  @media screen and (max-width: 499px) {
    width: 200px;
    margin-top: 200px;
  }
`;

export const IphoneImage = styled.img`
  width: 100%;
`;

export const IphoneVideoWrapper = styled.div`
  width: 88%;
  position: absolute;
  top: 3.3%;
  left: 50%;
  transform: translateX(-50%);
`;
