import useAuth from "@/modules/auth/hooks/useAuth.hook";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import styled from "@emotion/styled";
import { EVENT, send } from "@/lib/amplitude";
import DubButton from "@/modules/common/button/DubButton";
import { useRouter } from "next/router";
import { useLocale } from "@/modules/provider/LocaleProvider";
import { deviceStore } from "@/modules/utils/redirect";
import { Caption } from "@/modules/common/fonts";
import { ClickAwayListener } from "@mui/base";
import LanguageSelector from "./LanguageSelector";

const Header = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const locale = useLocale();
  const [isShow, setIsShow] = useState(false);
  const { showSignIn } = useAuth();

  const onClickSignIn = () => {
    send(EVENT.CLICK__GNB_SIGN_IN);
    showSignIn();
  };

  const onClickSolution = () => {
    if (router.pathname === "/solution") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsShow(false);
    } else {
      router.push("/solution");
    }
  };

  const onClick2dub = () => {
    if (router.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsShow(false);
    } else {
      router.push("/");
    }
  };

  const handleDownloadApp = () => {
    deviceStore(locale);
  };

  const onClickMenu = () => {
    setIsShow((prev) => !prev);
  };

  const handleClickAway = () => {
    if (isShow) {
      setIsShow((prev) => !prev);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <HeaderComponent>
          <Wrapper>
            <Logo />
            <RightSide>
              <ButtonWrapper>
                <DubButton
                  variant="text"
                  size="small"
                  color={router.pathname === "/" ? "black" : "gray"}
                  onClick={onClick2dub}
                >
                  {t(`common_2dub`)}
                </DubButton>
                <DubButton
                  variant="text"
                  size="small"
                  color={router.pathname === "/solution" ? "black" : "gray"}
                  onClick={onClickSolution}
                >
                  {t(`common_dub_solution`)}
                </DubButton>
              </ButtonWrapper>
              <LanguageSelector />
              <Divider />
              <DubButton
                variant="text"
                size="small"
                color="coral"
                sx={{ minWidth: "90px" }}
                onClick={onClickSignIn}
              >
                {t("button_login")}
              </DubButton>
              <Hamberger
                className={
                  isShow ? `icon icon-close` : `icon icon-hambergerMenu`
                }
                onClick={onClickMenu}
              />
            </RightSide>
            <MobileRightSide>
              <DownloadButton onClick={handleDownloadApp}>
                <DownloadButtonText>
                  {t("button_download2dub")}
                </DownloadButtonText>
              </DownloadButton>
              <LanguageSelector />
              <Hamberger
                className={
                  isShow ? `icon icon-close` : `icon icon-hambergerMenu`
                }
                onClick={onClickMenu}
              />
            </MobileRightSide>
          </Wrapper>
        </HeaderComponent>
        <DropDownMenu isShow={isShow}>
          <MenuItem isBold={router.pathname === "/"} onClick={onClick2dub}>
            {t(`common_2dub`)}
          </MenuItem>
          <MenuItem
            isBold={router.pathname === "/solution"}
            onClick={onClickSolution}
          >
            {t(`common_dub_solution`)}
          </MenuItem>
        </DropDownMenu>
      </div>
    </ClickAwayListener>
  );
};

const Hamberger = styled.div`
  display: none;
  font-size: 24px;
  padding: 20px;
  cursor: pointer;
  @media screen and (max-width: 1023px) {
    display: block;
    padding: 1px 0 0 12px;
  }

  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
`;

const HeaderComponent = styled.header`
  position: fixed;
  z-index: 12;
  top: 0;
  width: 100%;
  height: 64px;
  background-color: var(--white);
  @media screen and (max-width: 600px) {
    height: 44px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  column-gap: 8px;
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const DropDownMenu = styled.div<{ isShow: boolean }>`
  width: 100%;
  background-color: white;
  position: fixed;
  left: 0;
  z-index: 11;
  padding: 16px 0;
  transition: all 0.5s;
  display: none;
  @media screen and (max-width: 1023px) {
    display: block;
  }
  ${({ isShow }) => (isShow ? `top: 64px;` : `top: -185px;`)}
  @media screen and (max-width: 700px) {
    padding: 16px 0;
    ${({ isShow }) => (isShow ? `top: 44px;` : `top: -140px;`)}
  }
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.03),
    0px 3px 8px rgba(0, 0, 0, 0.08);
`;

const MenuItem = styled.div<{ isBold: boolean }>`
  position: relative;
  cursor: pointer;
  padding: 12px 72px;
  width: 100%;
  height: 51px;
  font-size: var(--fs-h5);
  line-height: var(--lh-h5);
  color: var(--gray080);
  ${({ isBold }) => isBold && `font-weight: 600; color: black;`}
  :hover {
    background-color: var(--gray030);
  }
  @media screen and (max-width: 700px) {
    font-size: var(--fs-b2);
    line-height: var(--lh-b2);
    padding: 8px 28px;
    height: 36px;
  }
`;

const Wrapper = styled.div`
  max-width: 1640px;
  height: 100%;
  margin: 0 auto;
  padding: 0 60px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 777px) {
    padding: 0px 16px;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 14px;
  background-color: var(--gray030);
  margin-left: 8px;
`;

const Logo = styled.div`
  width: 83px;
  height: 24px;
  background: url("/img/common/logo-text.png");
  background-position: center;
  background-size: 83px 24px;
`;

const RightSide = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0 8px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const MobileRightSide = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: flex;
    gap: 0 12px;
    align-items: center;
    margin-left: auto;
  }
`;

const DownloadButton = styled.button`
  display: flex;
  border-radius: 16px;
  padding: 5px 16px;
  background-color: #ff475d;
  border: 0;
  margin: 0;
  outline: 0;
`;

const DownloadButtonText = styled(Caption)`
  font-weight: 600;
  color: var(--white);
`;

export default Header;
