import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { type DialogProps } from "@mui/material/Dialog";
import Paper, { PaperProps } from "@mui/material/Paper";
import { useTranslation } from "next-i18next";
import Lottie from "react-lottie";
import { EVENT, send } from "@/lib/amplitude";
import { DubDialog } from "../common/dialog";
import welcomeLoti from "./welcomeLoti.js";
import { DubButton } from "../common/button";
import { ACCOUNT_TYPE, AuthInfo } from "./types/useAuth.types";

const CustomPaperComponent: React.JSXElementConstructor<PaperProps<"div">> = (
  props,
) => {
  const { children, ...others } = props;

  return (
    <Paper {...others} style={{ overflow: "visible" }}>
      <LottieBox>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: welcomeLoti,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={700}
          width={700}
          style={{
            position: "absolute",
            left: "-40%",
            top: "-100%",
            zIndex: -1,
          }}
        />
        <Character />
      </LottieBox>
      {children}
    </Paper>
  );
};

interface Props extends DialogProps {
  handleClose: () => void;
  name: string;
  auth_info: AuthInfo;
}

const WelcomeDialog = ({ open, handleClose, name, auth_info }: Props) => {
  const { t } = useTranslation();
  useEffect(() => {
    send(EVENT.ENTER__WELCOME_DIALOG, {
      account_type: ACCOUNT_TYPE[auth_info.type],
    });
  }, []);
  return (
    <DubDialog
      open={open}
      fullWidth
      size="regular"
      BackdropProps={{
        sx: {
          display: "none",
        },
      }}
      PaperComponent={CustomPaperComponent}
      header={t("dialog_welcome2dub")}
      body={
        <DialogContent>
          {t("dialog_welcome2dub_body", {
            nickname: name,
          })}
        </DialogContent>
      }
      footer={
        <DubButton
          variant="contained"
          color="magenta"
          size="regular"
          onClick={handleClose}
        >
          {t("button_start")}
        </DubButton>
      }
    />
  );
};

const DialogContent = styled.div`
  font-size: var(--fs-b1);
  line-height: var(--lh-b1);
  color: var(--gray090);
  padding: 8px 32px 12px;
  white-space: pre-wrap;
`;

const LottieBox = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 130px;
`;

const Character = styled.div`
  position: absolute;
  width: 221px;
  height: 147px;
  left: 25%;
  bottom: 0px;
  background: no-repeat center/100% url("/img/auth/welcome-header.png");
`;

export default WelcomeDialog;
