import ENV_PUBLIC from "@/scripts/client/ENV_PUBLIC";

const useKakao = () => {
  const REST_API_KEY = ENV_PUBLIC.NEXT_PUBLIC_KAKAO_REST_API_KEY ?? "";
  const REDIRECT_URI = `${
    window.location.protocol
  }//${window.location.host.replace("www.", "")}/api/oauth/kakao`;
  const handleAuthorize = async () => {
    const url = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&prompt=login`;

    window.location.assign(url);
  };

  return {
    handleAuthorize,
  };
};

export default useKakao;
