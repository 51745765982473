export const storageAvailable = (type) => {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // Firefox를 제외한 모든 브라우저
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // 코드가 존재하지 않을 수도 있기 떄문에 이름 필드도 확인합니다.
        // Firefox를 제외한 모든 브라우저
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // 이미 저장된 것이있는 경우에만 QuotaExceededError를 확인하십시오.
      storage &&
      storage.length !== 0
    );
  }
};

export function getCookie(cname: string) {
  const name = `${cname}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(idx: string, name: string) {
  const cookie = getCookie(name);
  let str;
  if (cookie.length) {
    const arr = cookie.split("|");
    arr.push(idx);
    str = arr.join("|");
  } else {
    str = idx;
  }

  document.cookie = `${name}=${str}; path=/`;
}

export const isEmail = (email: string) => {
  const emailRegex =
    /^(([^ㄱ-ㅎ가-힣<>()[\].,;:\s@“]+(\.[^ㄱ-ㅎ가-힣<>()[\].,;:\s@“]+)*)|(“.+“))@(([^ㄱ-ㅎ가-힣<>()[\].,;:\s@“]+\.)+[^ㄱ-ㅎ가-힣<>()[\].,;:\s@“]{2,})$/i;

  // ^(([^ㄱ-ㅎ가-힣<>()\[\].,;:\s@“]+(\.[^ㄱ-ㅎ가-힣<>()\[\].,;:\s@“]+)*)|(“.+“))@(([^ㄱ-ㅎ가-힣<>()\[\].,;:\s@“]+\.)+[^ㄱ-ㅎ가-힣<>()\[\].,;:\s@“]{2,})$

  return emailRegex.test(email);
};

export const passwordLength = (password: string) => {
  if (password.length > 0 && password.length < 6) {
    return false;
  }
  return true;
};

const getStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const initCheckRemember = (FLAG_KEY: string, VALUE_KEY: string) => {
  const flag = getStorage(FLAG_KEY);

  if (flag === null || flag === "0") {
    return {
      remember: false,
      value: "",
    };
  }

  const value = getStorage(VALUE_KEY) ?? "";
  return {
    remember: true,
    value,
  };
};

export const saveRemember = (
  checked: boolean,
  FLAG_KEY: string,
  value: string,
  VALUE_KEY: string,
) => {
  if (!storageAvailable("localStorage")) return;

  localStorage.setItem(FLAG_KEY, checked ? "1" : "0");

  if (checked) {
    localStorage.setItem(VALUE_KEY, value);
  } else {
    localStorage.removeItem(VALUE_KEY);
  }
};
