// eslint-disable-next-line no-shadow
export enum AuthDialogType {
  SignInDialog,
  SignUpDialog,
  ExtraInfo,
  EnterBirthdayDialog,
  SelectPathDialog,
  InviteCodeDialog,
  WelcomeDialog,
  ClassSignUpDialog,
  ClassSignInDialog,
  ResetPasswordDialog,
}

export interface AuthInfo {
  type: string;
  email?: string;
  password?: string;
  token?: string;
  access_token?: string;
  account_type?: string;
  authorization_code?: string;
}

export const ACCOUNT_TYPE = {
  E: "email",
  G: "google",
  I: "apple",
  K: "kakao",
  N: "naver",
};

export interface User {
  key: string;
  user: {
    idx: number;
  };
}

export type AuthProps = {
  handleClose: () => void;
  handleSwitch: (type: AuthDialogType, user?: User) => void;
  locale?: string;
};
