import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

interface Props extends DialogProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
}

/*
    단독으로 사용할수도있고
    Modal-Provider 활용해서 사용할수도 있음
    open, onClose (handleClose) 필수값
  
    기존의 close 는 onHeaderClose로 바뀜
    center : 타이틀에 가운데 정렬이 되어 있으면 true
  */

const DubDialogMobile = (props: Props) => {
  const { header, body, footer, open, onClose, ...rest } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth {...rest}>
      {header && (
        <DialogTitle
          sx={{
            p: "24px 24px 6px",
            fontSize: "var(--fs-h5)",
            lineHeight: "var(--lh-h5)",
            fontWeight: 600,
          }}
        >
          {header}
        </DialogTitle>
      )}
      {body && (
        <DialogContent
          className="scrollbar"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          {body}
        </DialogContent>
      )}
      {footer && (
        <DialogActions
          sx={{
            p: "20px 18px 18px",
            justifyContent: "center",
            "& > :not(:first-of-type)": { marginLeft: "8px" },
          }}
        >
          {footer}
        </DialogActions>
      )}
    </Dialog>
  );
};

DubDialogMobile.defaultProps = {
  header: undefined,
  body: undefined,
  footer: undefined,
};

export default DubDialogMobile;
