import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { ConvertText, Section, TitleText, Wrapper } from "./LandingStyle";

const Section11 = () => {
  const { t } = useTranslation();

  return (
    <Section bgColor="var(--gray010)">
      <Wrapper>
        <TitleText center>
          <ConvertText text={t(`land_11`)} />
        </TitleText>
        <CardWrapper>
          <Card>
            <Image src="/img/new_landing/common/image_10_1.png" alt="" />
            <SmallWrapper>
              <Star src="/img/new_landing/common/image_10_4.png" alt="" />
              <CardText>{t(`land_11_review1`)}</CardText>
              <UserNameText>{t(`land_11_review1_user`)}</UserNameText>
            </SmallWrapper>
          </Card>
          <Card>
            <Image src="/img/new_landing/common/image_10_2.png" alt="" />
            <SmallWrapper>
              <Star src="/img/new_landing/common/image_10_4.png" alt="" />
              <CardText>{t(`land_11_review2`)}</CardText>
              <UserNameText>{t(`land_11_review2_user`)}</UserNameText>
            </SmallWrapper>
          </Card>
          <Card>
            <Image src="/img/new_landing/common/image_10_3.png" alt="" />
            <SmallWrapper>
              <Star src="/img/new_landing/common/image_10_4.png" alt="" />
              <CardText>{t(`land_11_review3`)}</CardText>
              <UserNameText>{t(`land_11_review3_user`)}</UserNameText>
            </SmallWrapper>
          </Card>
        </CardWrapper>
      </Wrapper>
    </Section>
  );
};

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 30px;
  margin-top: 80px;
  @media screen and (max-width: 1279px) {
    flex-direction: column;
    align-items: center;
    column-gap: 0;
    row-gap: 30px;
    margin-top: 60px;
  }

  @media screen and (max-width: 777px) {
    margin-top: 40px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 48px;
  border-radius: 20px;
  width: 393px;
  @media screen and (max-width: 1279px) {
    flex-direction: row;
    width: 100%;
    max-width: 718px;
  }
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`;

const SmallWrapper = styled.div`
  width: 100%;
  text-align: center;
  @media screen and (max-width: 1279px) {
    text-align: left;
    margin-left: 40px;
  }
  @media screen and (max-width: 599px) {
    text-align: center;
    margin-left: 0;
  }
`;

const Star = styled.img`
  width: 190px;
  margin-bottom: 20px;
`;

const Image = styled.img`
  height: 154px;
  margin-bottom: 32px;
  @media screen and (max-width: 1279px) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 599px) {
    height: 146px;
    margin-bottom: 30px;
  }
`;

const CardText = styled.div`
  height: 220px;
  font-size: var(--fs-h5);
  line-height: var(--lh-h5);
  color: var(--gray100);
  @media screen and (max-width: 1279px) {
    height: auto;
  }
  @media screen and (max-width: 599px) {
    height: auto;
  }
`;

const UserNameText = styled.div`
  margin-top: 20px;
  font-size: var(--fs-h5);
  line-height: var(--1h-h5);
  color: var(--gray100);
  font-weight: 600;
  @media screen and (max-width: 1279px) {
    margin-top: 16px;
  }
  @media screen and (max-width: 599px) {
    text-align: center;
    margin-top: 16px;
  }
`;

export default Section11;
