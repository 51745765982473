import styled from "@emotion/styled";
import { type DialogProps } from "@mui/material/Dialog";
import { useTranslation } from "next-i18next";
import { DubButton } from "../common/button";
import { DubDialog } from "../common/dialog";
import { B2, H3 } from "../common/fonts";
import { DubTextField } from "../common/textfield";
import useEnterBirthday from "./hooks/useEnterBirthday.hooks";
import { AuthInfo } from "./types/useAuth.types";

type Props = DialogProps & {
  handleStep: (step: number) => void;
  handleBirthdayProps: (newValue: string) => void;
  locale: string;
  auth_info: AuthInfo;
};

const EnterBirthday = ({
  open,
  handleStep,
  handleBirthdayProps,
  locale,
  auth_info,
}: Props) => {
  const { handleClickSkip, handleClickNext, value, onChange, mask, disabled } =
    useEnterBirthday(handleStep, handleBirthdayProps, locale, auth_info);
  const { t } = useTranslation();

  const url = (() => {
    switch (locale) {
      case "ko":
        return "https://2meu.notion.site/011811ed94b94a819e0e1837dd86f8c4";
      case "ja":
        return "https://2meu.notion.site/81a3acd94270482f8f478c425ad09ee5";
      case "en":
      default:
        return "https://2meu.notion.site/Why-do-we-ask-for-your-birthday-0d4ab3c9745a41228db521395433d1eb";
    }
  })();

  const handleOpenNotion = () => {
    window.open(url, "_blank");
  };

  return (
    <DubDialog
      open={open}
      fullWidth
      size="medium"
      center
      header={t("register_title")}
      body={
        <DialogBody>
          <Title>{t("register_dob")}</Title>
          <Description
            dangerouslySetInnerHTML={{ __html: t("register_dob_body") }}
            onClick={handleOpenNotion}
          />
          <Wrapper>
            <DubTextField value={value} onChange={onChange} autoFocus />
            <MaskWrapper
              isEdit={value.length > 0}
              dangerouslySetInnerHTML={{ __html: mask }}
            />
          </Wrapper>
        </DialogBody>
      }
      footer={
        <>
          <DubButton
            variant="text"
            color="gray"
            size="small"
            onClick={handleClickSkip}
          >
            {t("button_skip")}
          </DubButton>
          <DubButton
            variant="contained"
            color="magenta"
            size="regular"
            disabled={disabled}
            onClick={handleClickNext}
          >
            {t("button_next")}
          </DubButton>
        </>
      }
    />
  );
};

const DialogBody = styled.div`
  padding: 24px 32px 32px;
`;

const Title = styled(H3)`
  font-weight: 600;
  text-align: center;
  padding-bottom: 8px;
`;

const Description = styled(B2)`
  color: var(--gray090);
  text-align: center;
  & span {
    color: #1873ff;
  }
  margin-bottom: 24px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;
`;

const MaskWrapper = styled.div<{ isEdit: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: var(--fs-b1);
  line-height: var(--lh-b1);
  padding: 16px;
  pointer-events: none;
  & span {
    color: ${({ isEdit }) => (isEdit ? "#616161" : "var(--gray090)")};
  }
`;

export default EnterBirthday;
