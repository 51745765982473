import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

const BackgroundSection = () => {
  const [backgroundColor, setBackgroundColor] = useState("#B0E189");

  const topSectionRef = useRef<HTMLDivElement>(null);
  const botSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window;
      // const innerWid = window.innerWidth;

      const firstColor = [176, 225, 137]; // #B0E189
      const secondColor = [255, 234, 161]; // #FFEAA1
      const thirdColor = [157, 173, 255]; // #9DADFF

      const correctValue = 0;

      if (!topSectionRef.current || !botSectionRef.current) {
        return;
      }

      // if (innerWid <= 499) {
      //   correctValue = -250;
      // } else if (innerWid <= 1279) {
      //   correctValue = innerWid * -0.11;
      // } else {
      //   correctValue = innerWid * -0.18;
      // }

      const topPosition = Math.floor(
        scrollY +
          topSectionRef.current.getBoundingClientRect().top +
          correctValue,
      );
      const botPosition = Math.floor(
        scrollY +
          botSectionRef.current.getBoundingClientRect().top +
          correctValue,
      );

      let r = 0;
      let g = 0;
      let b = 0;
      // test start
      // const y = scrollY + window.innerHeight;
      // const s1 = midSection.getBoundingClientRect().top + scrollY;
      // const s2 = midSection.getBoundingClientRect().bottom + scrollY;
      // const s3 = botSection.getBoundingClientRect().bottom + scrollY;

      // test end

      const divideSectionHeight = (topPosition + botPosition) * 0.25;

      if (scrollY < divideSectionHeight) {
        [r, g, b] = firstColor;
      } else if (scrollY < divideSectionHeight * 2) {
        const interpolationValue =
          (scrollY - divideSectionHeight) / divideSectionHeight;
        r =
          firstColor[0] - (firstColor[0] - secondColor[0]) * interpolationValue;
        g =
          firstColor[1] - (firstColor[1] - secondColor[1]) * interpolationValue;
        b =
          firstColor[2] - (firstColor[2] - secondColor[2]) * interpolationValue;
      } else if (scrollY < divideSectionHeight * 3) {
        const interpolationValue =
          (scrollY - divideSectionHeight * 2) / divideSectionHeight;
        r =
          secondColor[0] -
          (secondColor[0] - thirdColor[0]) * interpolationValue;
        g =
          secondColor[1] -
          (secondColor[1] - thirdColor[1]) * interpolationValue;
        b =
          secondColor[2] -
          (secondColor[2] - thirdColor[2]) * interpolationValue;
      } else {
        [r, g, b] = thirdColor;
      }

      setBackgroundColor(`rgb(${r}, ${g}, ${b})`);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ColorAnimationSection bgColor={backgroundColor}>
      <TopSection ref={topSectionRef}>
        <Section3 />
      </TopSection>
      <Section4 />
      <EndSection ref={botSectionRef}>
        <Section5 />
      </EndSection>
    </ColorAnimationSection>
  );
};

const ColorAnimationSection = styled.div<{ bgColor?: string }>`
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
`;

const TopSection = styled.div``;
const EndSection = styled.div``;

export default BackgroundSection;
