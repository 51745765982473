import styled from "@emotion/styled";
import Popper from "@mui/material/Popper";
import React, { useEffect, useMemo, useRef, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { type TextFieldProps } from "@mui/material/TextField";

export type Props = TextFieldProps & {
  confirm?: boolean;
  onClickItem: (email: string) => void;
};

const DEFAULT_WIDTH = 340;

const checkText = (inputText: string, sampleText: string) => {
  if (inputText === null || inputText === undefined) return false;
  if (inputText === sampleText) return false;

  return inputText.split("").every((text, index) => text === sampleText[index]);
};

const EmailAutoComplete = (props: Props) => {
  const {
    value,
    error,
    helperText,
    onChange,
    label,
    disabled,
    confirm,
    onClickItem,
  } = props;

  const inputRef = useRef<HTMLDivElement>(null);
  const { current: anchorEl } = inputRef;

  const [focus, setFocus] = useState(false);
  const [matchedList, setMatchedList] = useState<Array<string>>([]);

  const emailList = useMemo(() => {
    return [
      "gmail.com",
      "naver.com",
      "hanmail.net",
      "hotmail.com",
      "yahoo.com",
      "outlook.com",
    ];
  }, []);

  useEffect(() => {
    if ((value as string).includes("@")) {
      const splitedText = (value as string).split("@")[1];
      const matchedEmailList: Array<string> = [];
      emailList.forEach((item: string) => {
        if (checkText(splitedText, item)) {
          matchedEmailList.push(item);
        }
      });
      setMatchedList([...matchedEmailList]);
    }
  }, [value, emailList]);

  const handleClick = (item: string) => {
    return () => {
      onClickItem(item);
    };
  };

  const isOpen =
    focus && (value as string).includes("@") && matchedList.length > 0;

  return (
    <>
      <TextField
        value={value}
        error={error}
        helperText={helperText}
        onChange={onChange}
        ref={inputRef}
        label={label}
        fullWidth
        autoComplete="off"
        disabled={disabled}
        InputProps={{
          onFocus: () => {
            setFocus(true);
          },
          onBlur: () => {
            setFocus(false);
          },
          readOnly: confirm,
          endAdornment: (
            <InputAdornment position="end">
              {confirm && <InputIcon className="icon icon-checked" />}
            </InputAdornment>
          ),
        }}
      />
      <Popper
        id="auto_complete"
        open={isOpen}
        anchorEl={anchorEl}
        style={{ zIndex: 9999 }}
        placement="bottom-start"
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -18],
              },
            },
          ],
        }}
      >
        <Listbox
          style={{
            width: anchorEl?.offsetWidth ?? DEFAULT_WIDTH,
          }}
        >
          {matchedList.map((item) => {
            const splitedEmail = (value as string).split("@")[0] ?? "";
            return (
              <ListItem
                key={item}
                onPointerDown={handleClick(`${splitedEmail}@${item}`)}
              >
                <Text>{splitedEmail}@</Text>
                <Text
                  style={{
                    color: "#1873FF",
                  }}
                >
                  {item}
                </Text>
              </ListItem>
            );
          })}
        </Listbox>
      </Popper>
    </>
  );
};

const Listbox = styled.ul`
  position: absolute;
  padding: 8px 0;
  z-index: 10;
  border-radius: 8px;
  box-shadow: var(--elevation_06dp);
  background-color: var(--white);
`;

const ListItem = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  &:hover {
    cursor: pointer;
    background-color: var(--gray020);
  }
`;

const Text = styled.div`
  font-size: var(--fs-b1);
  line-height: var(--lh-b1);
`;

const InputIcon = styled.div`
  font-size: 24px;
  color: #4fc86b;
`;

export default EmailAutoComplete;
