import { setIdentify } from "@/lib/amplitude";
import { useEffect } from "react";

interface IUTM {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_id?: string;
  utm_term?: string;
  utm_content?: string;
  created_at?: number;
}

const searchParamsToJSON = (searchParams: string): IUTM => {
  const params = new URLSearchParams(searchParams);
  const searchJson = {} as any;
  params.forEach((value, key) => {
    if (value) {
      searchJson[key] = value;
    }
  });

  setIdentify(searchJson);
  searchJson.created_at = Date.now();

  return searchJson;
};

const searchHasUTM = (path: string) => {
  const params = new URLSearchParams(path);
  return (
    params.has("utm_source") &&
    params.has("utm_medium") &&
    params.has("utm_campaign")
  );
};

export const UTM_KEY = "2DUB_UTM_KEY";
// const VALID_DATE = 1000 * 60 * 60 * 24 * 30; // 30일?

const setUTM = () => {
  const { search } = window.location;
  if (search === undefined || search === null || search === "") return null;
  if (!searchHasUTM(search)) return null;

  const searchJson = searchParamsToJSON(search);
  localStorage.setItem(UTM_KEY, JSON.stringify(searchJson));
  return searchJson;
};

export const useUTM = () => {
  useEffect(() => {
    if (getUTM()) return;
    // UTM 파라미터 저장하기
    setUTM();
  }, []);
};

export const getUTM = () => {
  // UTM 파라미터 가져오기
  const item = localStorage.getItem(UTM_KEY);
  if (item) {
    return JSON.parse(item) as IUTM;
  }

  return setUTM();
};

export const removeUTM = () => {
  localStorage.removeItem(UTM_KEY);
};
