import React from "react";
import DialogTitle, { type DialogTitleProps } from "@mui/material/DialogTitle";
import { H4 } from "../fonts";

interface DubDialogTitleProps extends DialogTitleProps {
  align?: "left" | "center";
}

const DubDialogTitle = (props: DubDialogTitleProps) => {
  const { children, align, ...other } = props;
  const textAlign = align ?? "left";

  return (
    <DialogTitle
      sx={{
        textAlign,
        p: "28px 32px 8px",
        fontWeight: 600,
        color: "var(--gray090)",
      }}
      {...other}
    >
      <H4>{children}</H4>
    </DialogTitle>
  );
};

DubDialogTitle.defaultProps = {
  align: "left",
};

export default DubDialogTitle;
