/* eslint-disable react/require-default-props */
import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "@emotion/styled";

export interface DialogTitleProps {
  id: string;
  center?: boolean;
  children?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, center, ...other } = props;
  const textAlign = center ? "center" : "left";

  return (
    <DialogTitle sx={{ textAlign }} {...other}>
      {children}
      {onClose ? (
        <CloseIcon onClick={onClose} className="icon icon-close" />
      ) : null}
    </DialogTitle>
  );
};

const CloseIcon = styled.button`
  position: absolute;
  top: 32px;
  right: 32px;
  border: 0;
  outline: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  font-size: 24px;
  &:hover {
    color: var(--blue090);
  }
`;

export default CustomDialogTitle;
