import { useTranslation } from "next-i18next";
import styled from "@emotion/styled";
import {
  Section,
  VideoWrapper,
  TitleText,
  SubTitleText,
  ConvertText,
  ChipText,
  ContentWrapper,
  FunctionExplainTextWrapper,
  FunctionExplainVideoWrapper,
  IphoneImage,
  IphoneVideoWrapper,
  Video,
  IphoneWrapper,
  ContentSection,
  BackRectSection,
  BackRect,
} from "./LandingStyle";

const Section5 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <BackRectSection isRight>
        <ContentSection>
          <VideoWrapper>
            <ContentWrapper isReverse={false}>
              <FunctionExplainTextWrapper>
                <ChipWrapper>
                  <ChipContainer>
                    <ChipText color="rebeccapurple">
                      <ConvertText text={t("land_5_chip")} />
                    </ChipText>
                  </ChipContainer>
                </ChipWrapper>
                <TitleWrapper>
                  <TitleText>
                    <ConvertText text={t("land_5_title")} />
                  </TitleText>
                </TitleWrapper>
                <SubTitleWrapper>
                  <SubTitleText>
                    <ConvertText
                      text={t("land_5_body")}
                      target={t("land_5_body")}
                      targetColor="var(--gray090)"
                    />
                  </SubTitleText>
                </SubTitleWrapper>
              </FunctionExplainTextWrapper>
              <FunctionExplainVideoWrapper>
                <IphoneContainer>
                  <IphoneWrapper>
                    <IphoneImage src="/img/solution_intro/iphone.png" alt="" />
                    <IphoneVideoWrapper>
                      <Video
                        src="/media/miracle_fast.mp4"
                        autoPlay
                        loop
                        muted
                      />
                    </IphoneVideoWrapper>
                  </IphoneWrapper>
                </IphoneContainer>
              </FunctionExplainVideoWrapper>
            </ContentWrapper>
          </VideoWrapper>
        </ContentSection>
        <BackRect color="#7c8ad0" isRight />
      </BackRectSection>
    </Section>
  );
};

// const BigSvgContainer = styled.div`
//   @media screen and (max-width: 499px) {
//     display: none;
//   }
// `;
// const SmallSvgContainer = styled.div`
//   display: none;
//   @media screen and (max-width: 500px) {
//     display: contents;
//   }
// `;

// const IphoneWrapper = styled.div`
//   position: absolute;
//   width: 248px;
//   @media screen and (max-width: 499px) {
//     width: 137px;
//   }
// `;

const ChipWrapper = styled.div`
  display: flex;
  /* margin-bottom: 24px; */
`;

const ChipContainer = styled.div`
  /* padding: 9px 22px;
  background-color: #7c8ad0;
  border-radius: 16px;
  @media screen and (max-width: 499px) {
    padding: 8px 16px;
  } */
`;

const TitleWrapper = styled.div`
  color: var(--gray090);
  margin-bottom: 10px;
  @media screen and (max-width: 777px) {
    /* margin-bottom: 4px; */
  }
`;

const SubTitleWrapper = styled.div`
  @media screen and (max-width: 1279px) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: 499px) {
    margin-bottom: 40px;
  }
`;

const IphoneContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: right;
  align-items: center;
  width: 600px;
  height: 850px;
  /* border-radius: 450px; */
  /* background-color: #f1be83; */
  /* background-color: #ff705e; */

  @media screen and (max-width: 1279px) {
    width: 600px;
    height: 600px;
    border-radius: 450px;
    background-color: #ffc8cc;
    justify-content: center;
  }

  @media screen and (max-width: 499px) {
    width: 350px;
    height: 350px;
  }
`;

export default Section5;
