/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

interface ProfileImageProps {
  size: string;
  src: string;
  membership: "S" | "V" | "P" | "F";
  alt?: string;
  style?: any;
}

const ProfileImage = ({
  size = "regular",
  src = "/img/common/thumb-profile.png",
  alt = "Profile",
  membership = "F",
  style = {},
}: ProfileImageProps) => {
  const defaultImg = "/img/common/thumb-profile.png";
  const handleImgError: React.ReactEventHandler<HTMLImageElement> = () => {
    setImgSrc(defaultImg);
  };

  const [imgSrc, setImgSrc] = useState(defaultImg);
  useEffect(() => {
    const date = new Date();
    const crossOriginDate = `?t=${date.getFullYear()}${date.getMonth()}${date.getDate()}`;
    const localSrc = src ? `${src}${crossOriginDate}` : defaultImg;
    setImgSrc(localSrc);
  }, []);

  return (
    <ProfileComponent
      className={size}
      data-membership={membership}
      style={style}
    >
      <img
        className="profile__image"
        src={imgSrc}
        alt={alt}
        onError={handleImgError}
        crossOrigin="anonymous"
      />
    </ProfileComponent>
  );
};

ProfileImage.defaultProps = {
  alt: "Profile",
};

const ProfileComponent = styled.div`
  --profile_line_regular: var(--gradient45);
  position: relative;
  width: var(--size, 48px);
  height: var(--size, 48px);
  border-radius: 9999px;
  flex: none;

  &::before {
    content: "";
    display: none;
    position: absolute;
    z-index: 5;
    border-radius: 9999px;
    overflow: hidden;
    background: no-repeat center/100% url(/img/common/starclub-badge.png);
    bottom: var(--club-x, 0);
    right: var(--club-x, 0);
    width: var(--club-size, 16px);
    height: var(--club-size, 16px);
  }

  &::after {
    content: "";
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: calc(100% - var(--line) * 2);
    height: calc(100% - var(--line) * 2);
    border-radius: 9999px;
    overflow: hidden;
    background-color: var(--white);
  }

  & .profile__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 9999px;
    overflow: hidden;
    object-fit: cover;
    vertical-align: initial;
    background-color: var(--white);
    width: 100%;
    height: 100%;
  }

  &[data-membership="S"],
  &[data-membership="V"],
  &[data-membership="P"] {
    background-image: var(--profile_line_regular);
  }
  &[data-membership="S"]::before,
  &[data-membership="V"]::before {
    display: block;
  }
  &[data-membership="S"]::after,
  &[data-membership="V"]::after,
  &[data-membership="P"]::after {
    display: block;
  }

  &[data-membership="S"] .profile__image,
  &[data-membership="V"] .profile__image,
  &[data-membership="P"] .profile__image {
    width: calc(100% - var(--line) * 4);
    height: calc(100% - var(--line) * 4);
  }

  &.xxxsmall {
    --size: 18px;
    --line: 1px;
    --club-size: 10px;
    --club-x: 0;
  }

  &.xxsmall {
    --size: 24px;
    --line: 1px;
    --club-size: 10px;
    --club-x: 0;
  }
  &.extrasmall {
    --size: 36px;
    --line: 1px;
    --club-size: 14px;
    --club-x: 0;
  }
  &.small {
    --size: 40px;
    --line: 1px;
    --club-size: 16px;
    --club-x: 0;
  }
  &.regular {
    --size: 48px;
    --line: 1px;
    --club-size: 16px;
    --club-x: 0;
  }
  &.medium {
    --size: 56px;
    --line: 2px;
    --club-size: 24px;
    --club-x: 0;
  }
  &.large {
    --size: 96px;
    --line: 3px;
    --club-size: 32px;
    --club-x: 1px;
  }
  &.xlarge {
    --size: 112px;
    --line: 3px;
    --club-size: 40px;
    --club-x: 2px;
  }
  &.xxlarge {
    --size: 136px;
    --line: 4px;
    --club-size: 48px;
    --club-x: 2px;
  }
  .profile .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }
`;

export default ProfileImage;
