const getIsYoutube = ({ watch_flag }: { watch_flag: string }) => {
  // 유튜브 시청 관련 플래그 [N: 재생불가, P: 재생가능, S: 특정국가 시청 제한]
  return !["A", "X", "N"].includes(watch_flag);
};

const videoThumbnailUrl = ({ watch_flag, thumbnail_url, baseurl }: any) => {
  if (getIsYoutube({ watch_flag })) {
    return thumbnail_url;
  }
  return `${baseurl}.jpg`;
};

export default videoThumbnailUrl;
