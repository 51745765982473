import React, { useCallback, useEffect, useRef, useState } from "react";
import { PlyrInstance, usePlyr } from "plyr-react";
import { PlayState } from "react-gsap";
import { ClientLogger } from "@/scripts/log/client";
import {
  PlayerError,
  PlayerProps,
  PlayScriptProps,
  VideoModel,
} from "./player.types";
import { getPlyrParams, usePlayerUtil } from "./player.utils";

const usePlayer = (
  video: Partial<VideoModel>,
  ref: React.MutableRefObject<null>,
  audioRef: React.MutableRefObject<HTMLAudioElement | null>,
) => {
  useEffect(() => {
    return () => {
      eventIdRef.current = Date.now();
    };
  }, []);

  const isFirstPlay = useRef(true);
  const {
    duration,
    isMobile,
    isTimeBetweenStartAndEnd,
    convertAdaptedTime,
    convertOriginTime,
    isScriptFinished,
    syncAudioByVideoTime,
  } = usePlayerUtil(video);
  const [playbackRate, setPlaybackRate] = useState<number>(1); // 재생속도
  const [error, setError] = useState<PlayerError | null | undefined>(null);
  const [playerState, setPlayerState] = useState<
    "idel" | "video" | "voice" | "record"
  >("idel");
  const [scriptsState, setScriptsState] = useState(
    Array.from({ length: video.line_count }, (v, i) => ({
      idx: i + 1,
      playState: PlayState.pause,
    })),
  );

  const eventIdRef = useRef<number>(0);
  const prevIdxRef = useRef(0);

  const [plyrParams, setPlyrParams] = useState(getPlyrParams(video));

  // useEffect(() => {
  //   console.log("SetPlyrParams");
  //   setPlyrParams(getPlyrParams(video));
  // }, [video.idx]);

  const reload = (videoModel: VideoModel) => {
    setPlyrParams({ ...getPlyrParams(videoModel) });
    isFirstPlay.current = true;
  };

  const setPoster = (url: string) => {
    const { plyr } = ref.current;
    const player = plyr as PlyrInstance;
    if (player) {
      player.poster = url;
    }
  };

  useEffect(() => {
    const { plyr } = ref.current;
    const player = plyr as PlyrInstance;
    if (player !== undefined && player.source !== null) {
      plyr.once("ready", () => {
        const playerError = ref.current?.plyr?.media?.error;
        if (playerError) {
          setPoster("");
          setError(playerError);
        }
      });
    }
  }, [ref.current?.plyr]);

  const plyrRef = usePlyr(ref, plyrParams, [plyrParams]);

  const setScript = (idx: number, state: PlayState) => {
    if (idx - 1 >= 0) {
      setScriptsState((curr) => [
        ...curr.slice(0, idx - 1),
        { ...curr[idx - 1], playState: state },
        ...curr.slice(idx),
      ]);
    } else {
      setScriptsState((curr) => [...curr]);
    }
  };

  const seekCompleter = (playerParam: PlyrInstance, seekPos: number) => {
    return new Promise((resolve, reject) => {
      const player = playerParam;

      const targetPos = convertAdaptedTime(seekPos);
      let count = 0;
      const limit = 50; // 대략 5초
      const seekHandler = async () => {
        if (count > limit) {
          reject(Error("timeout seek"));
          return;
        }
        if (
          targetPos - 0.08 <= player.currentTime &&
          player.currentTime <= targetPos + 0.08
        ) {
          resolve(true);
          return;
        }
        count += 1;
        setTimeout(seekHandler, 100);
      };

      player.currentTime = targetPos;
      seekHandler();
    });
  };

  const getPlayer = useCallback(() => {
    if (plyrRef.current) {
      const { plyr } = ref.current;
      const player = plyr as PlyrInstance;

      if (player.duration === 0) {
        return null;
      }
      if (player.source === null) {
        return null;
      }

      return player;
    }
    return null;
  }, [ref.current]);

  const playCompleter = ({
    player,
    startPos,
    endPos,
    newEventId,
    callback,
    handler,
  }: {
    player: PlyrInstance;
    startPos: number;
    endPos: number;
    newEventId: number;
    callback: () => void;
    handler?: (time: number) => void;
  }) => {
    return new Promise((resolve) => {
      const seekHandler = async () => {
        if (newEventId !== eventIdRef.current) {
          resolve(false);
          return;
        }
        if (isScriptFinished(player.currentTime, endPos)) {
          callback();
          resolve(true);
          return;
        }

        if (handler) {
          handler(convertOriginTime(player.currentTime));
        }

        if (audioRef && audioRef.current) {
          const audio = audioRef.current;
          const gap = isMobile ? 0.5 : 0.1;
          syncAudioByVideoTime(player, audio, gap, startPos);
        }

        setTimeout(seekHandler, 100);
      };

      seekHandler();
    });
  };

  const isFirstPlaying = () => {
    const player = getPlayer();
    if (isFirstPlay.current && player) {
      player.play();
      player.pause();
      isFirstPlay.current = false;
    }
  };

  const setAudio = (url: string) => {
    if (audioRef.current) {
      const audio = audioRef.current;
      audio.src = url;
      return audio;
    }
    return null;
  };

  const pausePrevScript = ({ idx }: { idx: number }) => {
    const prevIdx = prevIdxRef.current;
    const isChangedIdx = prevIdx !== idx;
    if (isChangedIdx) {
      setScript(prevIdxRef.current, PlayState.pause);
    }
    prevIdxRef.current = idx;

    return isChangedIdx;
  };

  // startPos | endPos : 시작 값을 더하지 않은 순수한 포지션
  const initPosition = async ({
    idx,
    startPos,
    endPos,
    forceSeek,
  }: {
    idx: number;
    startPos: number;
    endPos: number;
    forceSeek?: boolean;
  }) => {
    const player = getPlayer();
    if (player) {
      if (forceSeek) {
        setScript(idx, PlayState.stop);
        await seekTo(startPos);
        return;
      }

      if (isTimeBetweenStartAndEnd(player.currentTime, startPos, endPos)) {
        setScript(idx, PlayState.stop);
        await seekTo(startPos);
      }
    }
  };

  const playScript = async ({
    idx,
    startPos,
    endPos,
    speed,
    newEventId,
    handler,
    callback,
  }: {
    idx: number;
    startPos: number;
    endPos: number;
    speed: number;
    newEventId: number;
    handler?: (time: number) => void;
    callback?: () => void;
  }) => {
    const player = getPlayer();
    if (player) {
      player.once("timeupdate", () => {
        setScript(idx, PlayState.play);
      });
      player.speed = speed;

      await player.play();
      playCompleter({
        player,
        startPos,
        endPos,
        newEventId,
        callback: () => {
          player.pause();
          setScript(idx, PlayState.stopEnd);
          setPlayerState("idel");
          if (callback) callback();
        },
        handler,
      });
    }
  };
  const playSub = async (
    args: PlayScriptProps & { handler: (time: number) => void },
  ) => {
    isFirstPlaying();
    setPlayerState("video");
    unmute();
    setAudio("");
    pausePrevScript({ idx: args.idx });

    const newEventId = Date.now();
    eventIdRef.current = newEventId;

    await initPosition({
      ...args,
    });
    await playScript({
      ...args,
      speed: 1,
      newEventId,
      handler: args.handler,
    });
  };

  const playOrigin = async () => {
    const player = getPlayer();
    if (player === null || player === undefined) return false;

    isFirstPlaying();
    setPlayerState("video");
    unmute();
    setAudio("");
    pausePrevScript({ idx: 0 });

    const startPos = 0;
    const endPos = duration;
    const newEventId = Date.now();
    eventIdRef.current = newEventId;

    await initPosition({
      idx: 0,
      startPos,
      endPos,
    });
    await playScript({
      idx: 0,
      startPos,
      endPos,
      speed: 1,
      newEventId,
    });
  };

  const playDubbed = async (args: { url: string }) => {
    const player = getPlayer();
    if (player === null || player === undefined) return false;

    isFirstPlaying();
    setPlayerState("voice");
    mute();
    const audio = setAudio(args.url);
    pausePrevScript({ idx: 0 });

    const startPos = 0;
    const endPos = duration;

    const newEventId = Date.now();
    eventIdRef.current = newEventId;

    await initPosition({
      idx: 0,
      startPos,
      endPos,
    });
    await playScript({
      ...args,
      idx: 0,
      startPos,
      endPos,
      speed: 1,
      newEventId,
    });
    audio?.play();
  };

  const playVideo = async (args: PlayScriptProps) => {
    const player = getPlayer();
    if (player === null || player === undefined) return false;

    isFirstPlaying();
    setPlayerState("video");
    unmute();
    setAudio("");
    const isChangedIdx = pausePrevScript(args);
    const newEventId = Date.now();
    eventIdRef.current = newEventId;

    await initPosition({ ...args, forceSeek: isChangedIdx });
    await playScript({ ...args, speed: playbackRate, newEventId });
    return true;
  };

  const playVoice = async (args: PlayScriptProps & { url: string }) => {
    const player = getPlayer();
    if (player === null || player === undefined) return false;

    isFirstPlaying();
    setPlayerState("voice");
    mute();
    const audio = setAudio(args.url);
    if (audio) {
      audio.volume = 1;
      audio.currentTime = 0;
      pausePrevScript(args);
      const newEventId = Date.now();
      eventIdRef.current = newEventId;
      await initPosition({ ...args, forceSeek: true });
      await playScript({ ...args, speed: 1, newEventId });
      audio.play();
      return true;
    }
    return false;
  };

  const playRecord = async (
    args: PlayScriptProps,
    beforeCallback: () => void,
    callback: () => void,
  ) => {
    const player = getPlayer();
    if (player === null || player === undefined) return false;

    isFirstPlaying();
    setPlayerState("record");
    mute();
    pausePrevScript(args);

    const newEventId = Date.now();
    eventIdRef.current = newEventId;

    await initPosition({ ...args, forceSeek: true });
    if (beforeCallback) {
      await beforeCallback();
    }
    await playScript({ ...args, speed: 1, newEventId, callback });
    return true;
  };

  const playPreview = async (
    args: PlayScriptProps,
    beforeCallback: () => void,
    callback: () => void,
  ) => {
    const player = getPlayer();
    if (player === null || player === undefined) return false;

    isFirstPlaying();
    setPlayerState("record");
    mute();
    pausePrevScript(args);

    const newEventId = Date.now();
    eventIdRef.current = newEventId;

    await initPosition({ ...args, forceSeek: true });
    if (beforeCallback) beforeCallback();
    await playScript({ ...args, speed: 1, newEventId, callback });
  };

  const pause = async () => {
    const player = getPlayer();
    if (player) {
      player.pause();
    }
    if (audioRef.current) {
      const audio = audioRef.current;
      audio.pause();
    }
    setScript(prevIdxRef.current, PlayState.pause);
    eventIdRef.current = Date.now();
    setPlayerState("idel");
  };

  const stop = async () => {
    const player = getPlayer();
    if (player) {
      player.pause();
      seekTo(0);
    }
    if (audioRef.current) {
      const audio = audioRef.current;
      audio.pause();
    }
    setScript(0, PlayState.stopEnd);
    eventIdRef.current = Date.now();
    prevIdxRef.current = 0;
    setPlayerState("idel");
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      pause();
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [audioRef, getPlayer]);

  const mute = useCallback(async () => {
    const player = getPlayer();
    if (player) {
      player.volume = 0;
      player.muted = true;
    }
  }, []);

  const unmute = useCallback(async () => {
    const player = getPlayer();
    if (player) {
      player.volume = 1;
      player.muted = false;
    }
  }, []);

  const setSpeed = useCallback(
    async (speed: number) => {
      const player = getPlayer();
      if (player) {
        setPlaybackRate(speed);
      }
    },
    [audioRef, getPlayer],
  );

  const resetScript = useCallback(async () => {
    setScriptsState(
      Array.from({ length: video.line_count }, (v, i) => ({
        idx: i + 1,
        playState: PlayState.stop,
      })),
    );
  }, [video.line_count]);

  // seekPos : 시작 값을 더하지 않은 순수한 포지션
  const seekTo = async (seekPos: number) => {
    const player = getPlayer();
    try {
      if (player) {
        await seekCompleter(player, seekPos);
      }
    } catch (e) {
      ClientLogger.error("seekTo - usePlayer", error);
      eventIdRef.current = Date.now();
    }
  };

  const progressChangeHandler = (seekPos: number) => {
    seekTo(seekPos);
  };

  const playing = playerState !== "idel";
  return {
    ref: plyrRef,
    video,
    scriptsState,
    playerState,
    speed: playbackRate,
    error,
    playing,
    playOrigin,
    playDubbed,
    playVideo,
    playVoice,
    playRecord,
    playSub,
    playPreview,
    reload,
    setPoster,
    pause,
    stop,
    mute,
    unmute,
    seekTo,
    setSpeed,
    progressChangeHandler,
    resetScript,
  } as PlayerProps;
};

export default usePlayer;
